import React from 'react';
import { Layout } from 'antd';
import LoginForm from '../../components/General/LoginForm';
import InstallPWA from '../../components/General/InstallPWA';

import './Login.scss';

export default function Login() {
    const { Content } = Layout;

    return (
        <Layout className="login">
            <Content className="login__content">
                <div className="login__content-banner">
                    <h1>¡Bienvenido!</h1>
                </div>

                <div className="login__content-subtitle">
                    <h1>Inicia sesión en tu cuenta</h1>
                </div>

                <LoginForm />

                <InstallPWA login />
            </Content>
        </Layout>
    );
}
