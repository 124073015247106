import React from 'react';
import { Layout, Result, Typography } from 'antd';
import { DollarCircleTwoTone } from '@ant-design/icons';

import HeaderTop from '../../../components/General/HeaderTop';

import './CashPayment.scss';

export default function CashPayment() {
    const { Content } = Layout;
    const { Paragraph } = Typography;

    return (
        <Layout className="change-password">
            <Content className="change-password__content">
                <HeaderTop
                    // backIcon={true}
                    route={'/payment'}
                    title={'Pago en Efectivo'}
                />
                <div>
                    <Result
                        icon={<DollarCircleTwoTone twoToneColor="#52c41a" />}
                        subTitle={
                            <Paragraph type="secondary">
                                Puedes pagar con efectivo.
                            </Paragraph>
                        }
                    />
                </div>
            </Content>
        </Layout>
    );
}
