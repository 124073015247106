import React from 'react';
import { Typography, Row, Col } from 'antd';
import moment from 'moment';
import ListRestaurants from '../../components/Admin/Restaurants/ListRestaurants';

import './SuperAdmin.scss';

export default function SuperAdmin() {
    const { Title } = Typography;
    return (
        <div className="super-admin">
            <Row>
                <Col xs={24} md={20}>
                    <Title>Administrador de Control B</Title>
                </Col>
                <Col xs={24} md={4}>
                    Fecha: {moment().format('LL')}
                </Col>
            </Row>
            <ListRestaurants />
        </div>
    );
}
