import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Button } from 'antd';
import {
    ReconciliationOutlined,
    HomeOutlined,
    ProfileOutlined,
    LoginOutlined,
    KeyOutlined,
    SyncOutlined,
    LineChartOutlined,
    FormOutlined,
    TagsOutlined,
    UserSwitchOutlined,
    TeamOutlined,
    CreditCardOutlined,
    BellOutlined,
    ExceptionOutlined,
} from '@ant-design/icons';
import { Image } from 'antd';
import { logout, getAccessTokenApi } from '../../../api/auth';
import {
    getCompanyIdApi,
    getCompanySuscriptionApi,
} from '../../../api/company';
import userIcon from '../../../assets/img/png/userIcon.png';
import useAuth from '../../../hooks/useAuth';
import useUser from '../../../hooks/useUser';
import { suscriptionType } from '../../../utils/general';

import './MenuSider.scss';

function MenuSider(props) {
    const { location, name, setCollapse, companyImage } = props;
    const { user } = useAuth();
    const { person } = useUser();

    const [roleUser, setRoleUser] = useState('Operator');
    const [suscriptionCompany, setSuscriptionCompany] = useState('Basic');
    const companyId = getCompanyIdApi();

    useEffect(() => {
        if (user !== undefined) {
            const roleId =
                user[
                    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                ];
            setRoleUser(roleId);
        }
    }, [user]);

    useEffect(() => {
        const token = getAccessTokenApi();
        if (companyId) {
            getCompanySuscriptionApi(token, companyId)
                .then((response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            const result = response.result;
                            setSuscriptionCompany(
                                suscriptionType(result.suscriptionType)
                            );
                        }
                    }
                })
                .catch((err) => {});
        }
    }, [companyId]);

    const logoutUser = () => {
        logout();
        window.location.reload();
    };

    return (
        <>
            <div className="menu-sider">
                <Image
                    className="menu-sider__image"
                    src={companyImage ? companyImage : userIcon}
                />

                <h1>¡Bienvenido!</h1>

                <h1>{name}</h1>
            </div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                onClick={() => setCollapse(true)}
            >
                <Menu.Item key="/admin" icon={<HomeOutlined />} disabled={!person.active}>
                    <Link to={'/admin'}>Perfil</Link>
                </Menu.Item>
                <Menu.Item key="/admin/menu" icon={<ProfileOutlined />} disabled={!person.active}>
                    <Link to={'/admin/menu'}>Menú</Link>
                </Menu.Item>
                <Menu.Item key="/admin/orden" icon={<ReconciliationOutlined />} disabled={!person.active}>
                    <Link to={'/admin/orden'}>Órdenes</Link>
                </Menu.Item>
                {roleUser === 'Admin' && suscriptionCompany === 'Premium' && (
                    <Menu.Item
                        key="/admin/collaborators"
                        icon={<TeamOutlined />}
                        disabled={!person.active}
                    >
                        <Link to={'/admin/collaborators'}>Colaboradores</Link>
                    </Menu.Item>
                )}
                {roleUser === 'Admin' && suscriptionCompany !== 'Basic' && (
                    <Menu.Item key="/admin/promotions" icon={<TagsOutlined />} disabled={!person.active}>
                        <Link to={'/admin/promotions'}>Promociones</Link>
                    </Menu.Item>
                )}
                {roleUser === 'Admin' && suscriptionCompany !== 'Basic' && (
                    <Menu.Item
                        key="/admin/runners"
                        icon={<UserSwitchOutlined />}
                        disabled={!person.active}
                    >
                        <Link to={'/admin/runners'}>Runners</Link>
                    </Menu.Item>
                )}

                {roleUser === 'Admin' && (
                    <Menu.Item key="/admin/report" icon={<LineChartOutlined />} disabled={!person.active}>
                        <Link to={'/admin/report'}>Reportes</Link>
                    </Menu.Item>
                )}
                {roleUser === 'Admin' && (
                    <Menu.Item
                        key="/admin/subscription"
                        icon={<CreditCardOutlined />}
                    >
                        <Link to={'/admin/subscription'}>Suscripción</Link>
                    </Menu.Item>
                )}
                {roleUser === 'Admin' && (
                    <Menu.Item key="/admin/advices" icon={<BellOutlined />} disabled={!person.active}>
                        <Link to={'/admin/advices'}>Avisos</Link>
                    </Menu.Item>
                )}
                {roleUser === 'Admin' && (
                    <Menu.Item key="/admin/news" icon={<ExceptionOutlined />} disabled={!person.active}>
                        <Link to={'/admin/news'}>Noticias</Link>
                    </Menu.Item>
                )}
                <Menu.Item key="/admin/changePassword" icon={<KeyOutlined />} disabled={!person.active}>
                    <Link to={'/admin/changePassword'}>Cambiar Contraseña</Link>
                </Menu.Item>
                <Menu.Item key="/admin/updates" icon={<SyncOutlined />}>
                    <Link to={'/admin/updates'}>Actualizaciones</Link>
                </Menu.Item>
                <Menu.Item key="/admin/policy" icon={<FormOutlined />}>
                    <Link to={'/admin/policy'}>Términos y Condiciones</Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<LoginOutlined />}>
                    <Button
                        type="text"
                        className="menu-side-btn"
                        onClick={logoutUser}
                    >
                        Cerrar Sesión
                    </Button>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default withRouter(MenuSider);
