import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Checkbox,
  Divider,
  Layout,
  Form,
  Input,
  InputNumber,
  Switch,
} from "antd";
import "./AddIngredientForm.scss";

import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

const AddIngredientForm = (props) => {
  const { showCost, showOptions } = props;
  const [showExtraCost, setShowExtraCos] = useState(showCost);
  const [showMOptions, setshowMOptions] = useState(showOptions);

  useEffect(() => {
    setShowExtraCos(showCost);
  }, [showCost]);
  const changeCheck = (e) => {
    setShowExtraCos(e.target.checked);
  };

  useEffect(() => {
    setshowMOptions(showOptions);
  }, [showOptions]);
  const addMOptions = (e) => {
    setshowMOptions(e.target.checked);
  };

  return (
    <div>
      <Form.Item
        label="Sección"
        name="titleIngredient"
        rules={[
          {
            required: true,
            message: "Por favor ingrese un título",
          },
        ]}
      >
        <Input placeholder="Elige tu salsa" />
      </Form.Item>
      <Row>
        <p>Disponible:</p>
        <Switch />
      </Row>
      <Row className="margin-check">
        <Checkbox>Sección Obligatoria</Checkbox>
      </Row>
      <Row>
        <Checkbox onChange={(e) => addMOptions(e)}>Múltiples Opciones</Checkbox>
      </Row>
      {showMOptions && (
        <Form.Item
          label="Número de opciones"
          name="titleIngredient"
          rules={[
            {
              required: true,
              message: "Por favor ingresa un número",
            },
          ]}
        >
          <Input placeholder="2" />
        </Form.Item>
      )}
      <br />
      <Divider>Agregar Opciones</Divider>
      <Row>
        <Col span={20}>
          <Form.Item
            label="Opción"
            name="descriptionIngredient"
            rules={[
              {
                required: true,
                message: "Por favor ingrese una descripción",
              },
            ]}
          >
            <Input placeholder="Salsa verde" />
          </Form.Item>
        </Col>
        <Col span={4} className="add-ingredient">
          <Button
            className={["btn-icons", "btn-add", "add-ingredient-button"]}
            type="primary"
            size={"small"}
            shape="circle"
            icon={<PlusOutlined />}
            onClick={(e) => changeCheck(e)}
          />
        </Col>
      </Row>
      <Row>
        <p>Disponible:</p>
        <Switch />
      </Row>
      <Row className="margin-check">
        <Checkbox onChange={(e) => changeCheck(e)}>Costo Extra</Checkbox>
      </Row>

      {showExtraCost && (
        <Form.Item
          label="Precio"
          name="priceIngredient"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el precio",
            },
          ]}
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            placeholder="Precio"
          />
        </Form.Item>
      )}
      <IngredientTable />
      <IngredientTable />
      <Row className="display-button-right">
        <Button className="margin-check" type="primary">
          Agregar
        </Button>
      </Row>
    </div>
  );
};

export default AddIngredientForm;

function IngredientTable(props) {
  const { showDeleteConfirm, showModal } = props;
  const titleDelete = `el ingrediente:`;
  const titleModal = "Editar Ingrediente";
  let type = "EditIngredient";
  let actionEdit = "EditIngredient";

  return (
    <Layout className="border-layout-ing ">
      <Row className="space-content align-items-center">
        <Col className="height-items" xs={4} md={8} lg={6}>
          <h3>Salsa</h3>
        </Col>
        <Row xs={12} className="space-content">
          <Button
            className="btn-icons margin-button-ing"
            type="primary"
            shape="circle"
            danger
            icon={<DeleteOutlined />}
            onClick={() => showDeleteConfirm(titleDelete)}
          />
        </Row>
      </Row>
    </Layout>
  );
}
