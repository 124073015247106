import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Checkbox,
  Divider,
  Layout,
  Form,
  Input,
  InputNumber,
  Switch,
} from "antd";

const EditOptionForm = (props) => {
  const { showCost } = props;
  const [showExtraCost, setShowExtraCos] = useState(showCost);

  useEffect(() => {
    setShowExtraCos(showCost);
  }, [showCost]);
  const changeCheck = (e) => {
    setShowExtraCos(e.target.checked);
  };

  return (
    <Form>
      <Form.Item
        label="Opción"
        name="descriptionIngredient"
        rules={[
          {
            required: true,
            message: "Por favor ingrese una descripción",
          },
        ]}
      >
        <Input placeholder="Salsa verde" />
      </Form.Item>
      <Row>
        <p>Disponible:</p>
        <Switch />
      </Row>

      <Checkbox onChange={(e) => changeCheck(e)}>Costo Extra</Checkbox>
      {showExtraCost && (
        <Form.Item
          label="Precio"
          name="priceIngredient"
          rules={[
            {
              required: true,
              message: "Por favor ingrese el precio",
            },
          ]}
        >
          <InputNumber
            style={{
              width: "100%",
            }}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            placeholder="Precio"
          />
        </Form.Item>
      )}
      <Row className="display-button-right">
        <Button className="margin-check" type="primary">
          Guardar
        </Button>
      </Row>
    </Form>
  );
};

export default EditOptionForm;
