import React, { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Layout, notification } from 'antd';
import AddCardStripe from '../Client/PaymentCard/AddCardStripe';
import HeaderTop from '../../components/General/HeaderTop';
import { apiKeyStripePublic } from '../../api/config';

import './RegisterCardStripe.scss';
import { getCompanyApi, getCompanyIdApi } from '../../api/company';
import { getAccessTokenApi, logout } from '../../api/auth';
import CheckoutConekta from '../../components/Client/CheckoutConekta';

export default function RegisterCardStripe() {
    const { Content } = Layout;
    const [company, setCompany] = useState(null);
    const promise = loadStripe(apiKeyStripePublic, {
        locale: 'es-419',
    });

    useEffect(() => {
        if (company === null) {
            const idComany = getCompanyIdApi();
            const token = getAccessTokenApi();

            getCompanyApi(token, idComany)
                .then((response) => {
                    if (response) {
                        if (response?.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }
                        if (response.error) {
                            return false;
                        }
                        setCompany({
                            UseStripe: response.useStripe,
                            UseConekta: response.useConekta,
                            ConektaPublicKey: response.conektaPublicKey,
                        });
                    }
                })
                .catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    return (
        <Layout className='register-card-stripe'>
            <Content>
                <HeaderTop
                    title={'Añadir Tarjeta'}
                    backIcon={true}
                    route={'/payment/cards'}
                />
                <div className='register-card-stripe__content'>
                    {company !== null && company.UseStripe && (
                        <Elements stripe={promise}>
                            <AddCardStripe />
                        </Elements>

                        // <CheckoutConekta action='add-card' />
                    )}
                    {company !== null && company.UseConekta && (
                        <CheckoutConekta
                            action='add-card'
                            conektaPublicKey={company.ConektaPublicKey}
                        />
                    )}
                </div>
            </Content>
        </Layout>
    );
}
