import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
    Row,
    Col,
    Radio,
    Typography,
    InputNumber,
    Spin,
    Button,
    notification,
} from 'antd';
import { DollarOutlined, CreditCardOutlined } from '@ant-design/icons';
import { apiKeyStripePublic } from '../../../api/config';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyApi, getCompanyIdApi } from '../../../api/company';
import CheckoutForm from '../CheckoutForm';
import { changePaymentMethodOrder } from '../../../api/order';
import { messageError } from '../../../utils/general';
import CheckoutConekta from '../CheckoutConekta';

export default function ChangePayment({ setIsModalVisible, orderId }) {
    const promise = loadStripe(apiKeyStripePublic, {
        locale: 'es-419',
    });

    const [infoCompany, setInfoCompany] = useState(null);
    const [payType, setPayType] = useState(1);
    const [acceptCardCredit, setAcceptCardCredit] = useState(true);
    const [change, setChange] = useState(0);
    const [stripePaymentMethod, setStripePaymentMethod] = useState('');
    const [conektaPaymentMethod, setConektaPaymentMethod] = useState('');
    const [activeSpin, setActiveSpin] = useState(true);
    const [confirmOrder, setConfirmOrder] = useState(true);
    const [loading, setLoading] = useState(false);

    const { Paragraph } = Typography;

    useEffect(() => {
        const idComany = getCompanyIdApi();
        const token = getAccessTokenApi();
        getCompanyApi(token, idComany)
            .then((response) => {
                if (response) {
                    if (response?.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado',
                        });

                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1500);
                        return;
                    }
                    if (response.error) {
                        return false;
                    }
                    setInfoCompany({
                        CreditCard: response.creditCard,
                        CashOnly: response.cashOnly,
                        UseStripe: response.useStripe,
                        UseConekta: response.useConekta,
                        ConektaPublicKey: response.conektaPublicKey,
                    });
                }
                setActiveSpin(false);
            })
            .catch((err) => {});

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangePayment = (e) => {
        setPayType(e.target.value);
        if (e.target.value === 2) {
            setConfirmOrder(false);
        } else {
            setConfirmOrder(true);
        }
    };

    const changePaymentHandler = async () => {
        if (orderId) {
            const token = getAccessTokenApi();
            let data;
            if (payType === 1) {
                data = {
                    PayType: payType,
                    ChangeOf: change !== 0 ? change : null,
                };
            } else if (payType === 2) {
                if (infoCompany.UseConekta) {
                    data = {
                        PayType: payType,
                        ConektaPaymentMethodId: conektaPaymentMethod,
                    };
                }
                if (infoCompany.UseStripe) {
                    data = {
                        PayType: payType,
                        StripePaymentMethodId: stripePaymentMethod,
                    };
                }
            } else {
                notification['warning']({
                    message: 'Debe seleccionar un método de pago',
                });
                return;
            }
            setLoading(true);
            const result = await changePaymentMethodOrder(token, orderId, data);
            setLoading(false);

            if (result) {
                if (result.statusCode === 200) {
                    notification['success']({
                        message:
                            'Su método de pago se ha modificado exitosamente.',
                    });

                    setIsModalVisible(false);
                    window.location.reload();
                } else {
                    let msgError = 'Ocurrió un error, intentelo mas tarde.';
                    if (
                        result.statusCode === 404 ||
                        result.statusCode === 400 ||
                        result.statusCode === 409
                    ) {
                        msgError = result.description;
                    }
                    notification['error']({
                        message: msgError,
                    });
                }
            } else {
                notification['error']({
                    message: messageError(),
                });
            }
        } else {
            notification['error']({
                message: 'Ocurrió un error intentelo mas tarde.',
            });
        }
    };

    return (
        <div className='change-payment'>
            <Spin spinning={activeSpin} tip='Cargando...'>
                {infoCompany !== null && (
                    <div className='basket-form__container-paypal'>
                        <Radio.Group
                            defaultValue={1}
                            size='large'
                            onChange={onChangePayment}
                            value={payType}
                        >
                            <Radio.Button
                                value={1}
                                disabled={infoCompany.CashOnly ? false : true}
                            >
                                {' '}
                                <DollarOutlined /> Efectivo
                            </Radio.Button>
                            <Radio.Button
                                value={2}
                                disabled={
                                    infoCompany.CreditCard && acceptCardCredit
                                        ? false
                                        : true
                                }
                            >
                                {' '}
                                <CreditCardOutlined /> Tarjeta T/C
                            </Radio.Button>
                        </Radio.Group>
                        {payType === 2 && infoCompany.CreditCard && (
                            <Row className='change'>
                                {infoCompany.UseStripe && (
                                    <Elements stripe={promise}>
                                        <CheckoutForm
                                            setConfirmOrder={setConfirmOrder}
                                            setStripePaymentMethod={
                                                setStripePaymentMethod
                                            }
                                            setActiveSpin={setActiveSpin}
                                            setAcceptCardCredit={
                                                setAcceptCardCredit
                                            }
                                        />
                                    </Elements>
                                )}
                                {infoCompany.UseConekta && (
                                    <CheckoutConekta
                                        setConfirmOrder={setConfirmOrder}
                                        setConektaPaymentMethod={
                                            setConektaPaymentMethod
                                        }
                                        setActiveSpin={setActiveSpin}
                                        setAcceptCardCredit={
                                            setAcceptCardCredit
                                        }
                                        conektaPublicKey={
                                            infoCompany.ConektaPublicKey
                                        }
                                    />
                                )}
                            </Row>
                        )}

                        {payType === 1 && infoCompany.CashOnly && (
                            <Row className='change'>
                                <Col span={6} className='change-left'>
                                    <Paragraph>Cambio de</Paragraph>
                                </Col>
                                <Col span={18} className='change-right'>
                                    <InputNumber
                                        min={0}
                                        value={change}
                                        defaultValue={0}
                                        formatter={(value) =>
                                            `$ ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )
                                        }
                                        onChange={(value) => setChange(value)}
                                        style={{
                                            width: '50%',
                                        }}
                                    />
                                </Col>
                            </Row>
                        )}
                        <Button
                            onClick={changePaymentHandler}
                            disabled={confirmOrder === false}
                            type='primary'
                            block
                            style={{ marginTop: 20 }}
                            loading={loading}
                        >
                            Guardar
                        </Button>
                    </div>
                )}
            </Spin>
        </div>
    );
}
