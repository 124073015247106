import React from 'react';
import { Layout } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import TablePromos from '../../../components/Admin/Promotions/TablePromos';

import './Promotions.scss';

const Promotions = () => {
    const { Content } = Layout;

    return (
        <Layout className="collaborators">
            <HeaderTop title={'Promociones'} backUrl="/admin" />
            <Content className="collaborators__content">
                <TablePromos />
            </Content>
        </Layout>
    );
};

export default Promotions;
