import { useState } from 'react';

const initialCompany = {
    Location: '',
    Telephone: '',
};

export const useInfoCompany = (initialState = initialCompany) => {
    const [infoCompany, setInfoCompany] = useState(initialState);

    return {
        infoCompany,
        setInfoCompany,
    };
};
