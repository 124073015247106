import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Result, Button } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi, processStripeAccountApi } from '../../../api/company';
import { userLogOut } from '../../../utils/general';

import './SuccessStripeAccount.scss';

const SuccessStripeAccount = () => {
    const { Content } = Layout;
    let history = useHistory();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    function redirectHome() {
        history.push(`/admin/`);
    }

    useEffect(() => {
        processStripeAccountApi(token, companyId).then((res) => {
            console.log(res);
        });
    }, [companyId, token]);

    return (
        <Layout className="success-account">
            <Content>
                <Result
                    status="success"
                    title="¡Registro Exitoso!"
                    subTitle="Su cuenta de Stripe se ha creado exitosamene. Empiece a disfrutar."
                    extra={[
                        <Button
                            type="primary"
                            key="redirectHome"
                            onClick={redirectHome}
                        >
                            Ir a Inicio
                        </Button>,
                    ]}
                />
            </Content>
        </Layout>
    );
};

export default SuccessStripeAccount;
