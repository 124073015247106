import React from 'react';
import { Layout, Typography, List, Button, Divider } from 'antd';
import HeaderTop from '../../components/General/HeaderTop';
import InstallPWA from '../../components/General/InstallPWA';
import useUser from '../../hooks/useUser';
import useAuth from '../../hooks/useAuth';
import {
    askForNotificationPermission,
    getMobileOperatingSystem,
    notificationActive,
} from '../../utils/subscriptions';
import { getAccessTokenApi } from '../../api/auth';
import { getCompanyIdApi } from '../../api/company';
import './Update.scss';

const Update = ({ history }) => {
    const { Content } = Layout;
    const { Title } = Typography;
    const { person } = useUser();
    const { name, userType } = person;
    const { user } = useAuth();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const dataClient = [
        'Generales:',
        '-Mejoramos el performance en toda la PWA.',
        // '-Se agregó funcionalidad para confirmar una dirección válida.',
        // '-Agregamos la funcionalidad de recuperación de contraseña.',
        // '-Se agregó pantalla para control de cambios de actualizaciones.',
        '-Se actualizó el documento de Términos y Condiciones.',

        'Cliente:',
        '-Se realizaron cambios en el diseño de ventanas, ahora es más amigable y visual.',
        '-Ahora puedes navegar sobre el menú aún teniendo una orden activa.',
        '-Ya puedes visualizar el detalle de tu pedido y el total en la pantalla de seguimiento de orden.',
        '-Se calcula un costo de envío dependiendo de los km de tu dirección con el establecimiento.',
        '-Se agregó función para agregar propina.',
    ];
    const dataAdmin = [
        'Generales:',
        '-Mejoramos el performance en toda la PWA.',
        // '-Se agregó funcionalidad para confirmar una dirección válida.',
        // '-Agregamos la funcionalidad de recuperación de contraseña.',
        // '-Se agregó pantalla para control de cambios de actualizaciones.',
        '-Se actualizó el documento de Términos y Condiciones.',

        'Administrador:',
        // '-Se calcula un costo de envío dependiendo de los km al editar una orden a tipo domicilio.',
        // '-Se manejo un mismo diseño para la carga de menú en celular y pc.',
        // '-Se agregó propina a los registros.',
        // '-Ahora puedes establecer km para cobro mínimo y cantidad de cobro extra por km.',
        '-Se agregó Código de seguridad para Reporte de Ventas.',
        '-Se agregó validación de seguridad, por medio de confirmación de correo electrónico.',
        '-Se agregó sección de Categorías para el Pool de Restaurantes.',
        '-Se agregó sección de Instrucciones de recogida para el Runner.',
        '-Se agregaron Secciones Especiales en el Menú para darle la opción al cliente de elegir sus ingredientes.',
        '-Se agrego la funcionalidad de Promociones y descuentos.',
        '-Se agrego sección de Runners para dar de alta los usuarios.',

        'Cliente:',
        // '-Se realizaron cambios en el diseño de ventanas, ahora es más amigable y visual.',
        // '-Ahora puedes navegar sobre el menú aún teniendo una orden activa.',
        // '-Ya puedes visualizar el detalle de tu pedido y el total en la pantalla de seguimiento de orden.',
        // '-Se calcula un costo de envío dependiendo de los km de tu dirección con el establecimiento.',
        // '-Se agregó función para agregar propina.',
        '-Se agregó funcionalidad de escoger los ingredientes de los platillos.',
        '-Se agregaron descuentos, promociones generales al menú o con código al momento de pagar la órden.',
        '-Se agregó confirmación de correo electrónico.',
        '-Al momento de utilizar el método de pago con tarjeta, pide más datos del usuario por seguridad. (Nombre, Apellido, Teléfono, Email, Código Postal y Dirección).',
        '-Se agregaron validaciones en el registro al momento de poner la contraseña.',
    ];
    const dataRunner = [
        'Generales:',
        '-Mejoramos el performance en toda la PWA.',
        '-Puedes visualizar el historial de las ordenes finalizadas',
        '-Agregamos la funcionalidad de recuperación de contraseña.',
        '-Se agregó pantalla para control de cambios de actualizaciones.',
        'Runner:',
        '-Permite subir tus documentos para los establecimientos laborando.',
        '-Permite conectarte o desconectarte de los restaurantes afiliados.',
        '-Permite notificar al cliente dependiendo del estatus del pedido. ',
    ];

    // const updateApp = () =>{
    //     window.location.reload();
    // }
    function activeNotification() {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        if (companyId) {
            askForNotificationPermission(clientId, roleId, companyId, token);
        }
        notificationActive();
    }

    return (
        <Layout className='update'>
            <Content className='register__content'>
                <HeaderTop
                    title={'Actualizaciones'}
                    // backUrl={userType === 1 ? '/admin' : '/'}
                    backIconAuto={true}
                    history={history}
                />
                <div className='update__container-title'>
                    <Title level={3}>{`¡Hola, ${name}!`}</Title>
                </div>
                <div className='update__container'>
                    <List
                        size='small'
                        header={<div>Actualizaciones nuevas:</div>}
                        footer={<div> Version 1.0.1 - 23/Junio/2021</div>}
                        dataSource={
                            userType === 1
                                ? dataAdmin
                                : userType === 2
                                ? dataRunner
                                : dataClient
                        }
                        locale={{ emptyText: 'No hay nuevas actualizaciones.' }}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                    />
                    {/* <Button type="primary" block onClick={updateApp}>Actualizar</Button> */}

                    {getMobileOperatingSystem() !== 'iOS' && (
                        <>
                            {userType !== 2 && (
                                <>
                                    <Divider>Notificaciones</Divider>
                                    <Button
                                        type='primary'
                                        block
                                        onClick={() => activeNotification()}
                                    >
                                        Activar
                                    </Button>
                                </>
                            )}
                        </>
                    )}

                    <InstallPWA />
                </div>
            </Content>
        </Layout>
    );
};

export default Update;
