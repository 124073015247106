import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import {
    Typography,
    Layout,
    Steps,
    Descriptions,
    Divider,
    Button,
    Skeleton,
    Alert,
    Collapse,
    Tag,
    Col,
    Row,
    notification,
} from 'antd';
import {
    CaretRightOutlined,
    FileProtectOutlined,
    UserOutlined,
    PhoneOutlined,
    CarOutlined,
    CreditCardOutlined,
} from '@ant-design/icons';
import Footer from '../../../components/Client/Footer';
import CancelOrderForm from '../../../components/Client/CancelOrderForm';
import HeaderTop from '../../../components/General/HeaderTop';
import Modal from '../../../components/General/Modal';
import useUser from '../../../hooks/useUser';
import useAuth from '../../../hooks/useAuth';
import { getAccessTokenApi } from '../../../api/auth';
import {
    getCompanyApi,
    getCompanyIdApi,
    getCompanySuscriptionApi,
} from '../../../api/company';
import { getRunnerOrderDetail } from '../../../api/runner';
import { getOrderApi } from '../../../api/order';
import { askForNotificationPermission } from '../../../utils/subscriptions';
import {
    formatMoney,
    formatPhoneNumber,
    userLogOut,
    suscriptionType,
} from '../../../utils/general';

import './StatusOrder.scss';
import ChangePayment from '../../../components/Client/ChangePayment';

const StatusOrder = () => {
    let history = useHistory();
    const { idOrder } = useParams();
    const { Step } = Steps;
    const { person } = useUser();
    const { shortName } = person;
    const { user } = useAuth();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [orderClient, setOrderClient] = useState(null);
    const [infoCompany, setInfoCompany] = useState(null);
    const [status, setStatus] = useState(-1);
    const [reloadPage, setreloadPage] = useState(false);
    const [activeOrder, setActiveOrder] = useState(false);
    const [timeOutCancel, setTimeOutCancel] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const { Panel } = Collapse;
    const { Text, Paragraph, Title } = Typography;
    const [suscriptionCompany, setSuscriptionCompany] = useState('Basic');
    const [styleButton, setStyleButton] = useState('primary');
    const [ghostStyle, setGhostStyle] = useState(true);
    const [titleModal, setTitleModal] = useState('true');

    useEffect(() => {
        const token = getAccessTokenApi();
        if (companyId) {
            getCompanySuscriptionApi(token, companyId)
                .then((response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            const result = response.result;
                            setSuscriptionCompany(
                                suscriptionType(result.suscriptionType)
                            );
                        }
                    }
                })
                .catch((err) => {});
        } else {
           window.location.href='/';
        }
    }, [companyId]);

    useEffect(() => {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        askForNotificationPermission(clientId, roleId, companyId, token);
        getCompanyApi(token, companyId).then((response) => {
            setInfoCompany(response);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        if (!isNaN(parseInt(idOrder))) {
            getOrderApi(token, companyId, parseInt(idOrder)).then((res) => {
                if (res === undefined) {
                    notification['error']({
                        message:
                            'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                    });
                    return false;
                }

                if (res.status === 400 || res.statusCode === 404) {
                    setActiveOrder(false);
                } else {
                    setActiveOrder(true);

                    let dayMoment = moment.unix(res.orderDateTimeUnix);

                    let now = moment();
                    dayMoment.add(10, 'minutes');
                    let dif = dayMoment.diff(now, 'minutes');

                    if (dif <= 0) {
                        setTimeOutCancel(false);
                    } else {
                        setTimeOutCancel(true);
                    }
                }

                if (res.status === 7) {
                    if (reloadPage) {
                        notification['warning']({
                            message: 'Orden Cancelada',
                            description: `${res.cancelationReason} , para más información contactese con nosotros.`,
                        });
                    }
                    setActiveOrder(false);

                    history.push('/');

                    return;
                }
                //save order
                setOrderClient(res);

                switch (res.status) {
                    case 1:
                        setStatus(-1);
                        break;
                    case 2:
                        setStatus(0);
                        break;
                    case 3:
                        setStatus(1);
                        break;
                    case 4:
                        setStatus(1);
                        break;
                    case 5:
                        setStatus(2);
                        break;
                    case 6:
                        setStatus(3);
                        break;

                    default:
                        setStatus(-1);
                        break;
                }

                if (res.status === 6) {
                    if (reloadPage) {
                        notification['success']({
                            message: 'Orden Pagada',
                            description: 'Disfrute su orden..',
                        });
                    }

                    history.push('/');
                }
            });
        } else {
            history.push('/');
        }

        setreloadPage(false);

        window.scrollTo(0, 0);
        return () => {
            setOrderClient(null);
            setStatus(null);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPage]);

    const cancelOrder = () => {
        let dayMoment = moment.unix(orderClient.orderDateTimeUnix);

        let now = moment();
        dayMoment.add(10, 'minutes');
        let dif = dayMoment.diff(now, 'minutes');

        if (dif <= 0) {
            setTimeOutCancel(false);
            notification['error']({
                message:
                    'Ya excedió el tiempo limite para poder cancelar la orden.',
            });
        } else {
            setTimeOutCancel(true);
            setTitleModal('Cancelar Orden');
            setContextModal(
                <CancelOrderForm
                    order={orderClient}
                    setIsModalVisible={setIsModalVisible}
                    setreloadPage={setreloadPage}
                    history={history}
                />
            );
            setIsModalVisible(true);
        }
    };
    const viewRunnerDetails = async (idOrder) => {
        const result = await getRunnerOrderDetail(token, idOrder);
        if (result?.statusCode !== 200) {
            setGhostStyle(false);
            setStyleButton('danger');
            notification['error']({
                message: result.description,
            });
        } else {
            setGhostStyle(false);
            setStyleButton('primary');
            setIsModalVisible(true);
            setTitleModal('Información Runner');
            setContextModal(
                <div className='client-info__container'>
                    <div className='client-info__header'>
                        <div className='client-info__header-circle'>
                            <UserOutlined className='icon' />
                        </div>
                    </div>
                    <Row gutter={[0, 0]}>
                        <Col span={24}>
                            <Title level={3} style={{ marginTop: 20 }}>
                                {' '}
                                <UserOutlined /> Nombre
                            </Title>
                        </Col>
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Text className='name'>{result.result.name}</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title level={4}>
                                <CarOutlined /> Vehículo
                            </Title>
                        </Col>
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Text>
                                {result.result.vehicle.type === 0
                                    ? 'Automóvil'
                                    : 'Motocicleta'}
                            </Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Title level={4}>
                                <CreditCardOutlined /> Placas
                            </Title>
                        </Col>
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Text>{result.result.vehicle.plate}</Text>
                        </Col>
                    </Row>
                    <a
                        href={`tel:+52${result.result.phoneNumber}`}
                        className='ant-btn ant-btn-lg ant-btn-block ant-btn-primary'
                        style={{ marginBottom: 20 }}
                    >
                        <PhoneOutlined style={{ marginRight: 5 }} /> Llamar a
                        runner
                    </a>
                </div>
            );
        }
    };

    const changePaymentHandle = () => {
        setTitleModal('Cambiar método de pago');
        setContextModal(
            <ChangePayment
                setIsModalVisible={setIsModalVisible}
                orderId={orderClient.orderId}
            />
        );
        setIsModalVisible(true);
    };

    return (
        <>
            <Layout className='status-order'>
                <HeaderTop
                    backIconHandle={true}
                    goToUrl={'/orders/active'}
                    title={`¡Hola, ${shortName}!`}
                />
                {orderClient !== null ? (
                    orderClient.requiresOtherPaymentMethod && (
                        <Alert
                            type='error'
                            message={
                                <Text strong>Ocurrió un error en el pago.</Text>
                            }
                            description='Favor de actualizar o cambiar el método de pago.'
                            banner
                        />
                    )
                ) : (
                    <></>
                )}
                <Divider orientation='left'>Información de tu orden</Divider>
                <div className='status-order__container'>
                    {orderClient == null && infoCompany === null && (
                        <Skeleton active paragraph={{ rows: 5 }} />
                    )}

                    {orderClient !== null &&
                        infoCompany !== null &&
                        activeOrder && (
                            <>
                                <Title level={5} type='secondary'>
                                    {infoCompany.name}
                                </Title>
                                {suscriptionCompany !== 'Basic' &&
                                    orderClient.orderType === 2 && (
                                        <>
                                            <Tag
                                                icon={<FileProtectOutlined />}
                                                color='blue'
                                                // color="red"
                                                style={{
                                                    marginBottom: 15,
                                                    fontSize: 18,
                                                    padding: 10,
                                                }}
                                            >
                                                Código de entrega:{' '}
                                                {orderClient.deliveryCode}
                                            </Tag>
                                            <Col span={12}>
                                                {orderClient.status >= 2 && (
                                                    <Button
                                                        style={{
                                                            marginBottom: 10,
                                                        }}
                                                        type={styleButton}
                                                        size='small'
                                                        icon={<UserOutlined />}
                                                        onClick={() =>
                                                            viewRunnerDetails(
                                                                orderClient.orderId
                                                            )
                                                        }
                                                        ghost={ghostStyle}
                                                    >
                                                        Información del runner
                                                    </Button>
                                                )}
                                            </Col>
                                        </>
                                    )}
                                <Descriptions>
                                    <Descriptions.Item label='Tiempo de preparación'>
                                        {orderClient.preparationTime === 0
                                            ? 'Pendiente'
                                            : `${orderClient.preparationTime} minutos`}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Tipo de entrega'>
                                        {orderClient.orderType === 1
                                            ? 'Para llevar'
                                            : orderClient.orderType === 2
                                            ? 'A Domicilio'
                                            : 'Para Comer Aqui'}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Sucursal'>
                                        {`${infoCompany.address} Col. ${infoCompany.neighborhood} CP. ${infoCompany.zipCode}, ${infoCompany.city} ${infoCompany.state}`}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Teléfono de sucursal'>
                                        {formatPhoneNumber(infoCompany.phone)}{' '}
                                        {infoCompany.phoneAlternate === ''
                                            ? ''
                                            : infoCompany.phoneAlternate ===
                                              null
                                            ? ''
                                            : ' / '}
                                        {formatPhoneNumber(
                                            infoCompany.phoneAlternate
                                        )}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Ubicación de entrega'>
                                        {orderClient.orderType === 1
                                            ? 'Sucursal'
                                            : orderClient.orderType === 2
                                            ? orderClient.clientAddress
                                            : `Mesa ${orderClient.tableNumber}`}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='No. de Pedido'>
                                        {orderClient.orderNum}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Fecha y Hora'>
                                        {moment
                                            .unix(orderClient.orderDateTimeUnix)
                                            .format('DD/MM/YYYY')}{' '}
                                        {moment
                                            .unix(orderClient.orderDateTimeUnix)
                                            .format('h:mm A')}
                                    </Descriptions.Item>
                                    <Descriptions.Item label='Método de pago'>
                                        {' '}
                                        {orderClient.payType === 1
                                            ? `Efectivo ${
                                                  orderClient.changeOf -
                                                      orderClient.total >
                                                  0
                                                      ? ', recibirá $' +
                                                        formatMoney(
                                                            orderClient.changeOf -
                                                                orderClient.total
                                                        ) +
                                                        ' al pagar con $' +
                                                        formatMoney(
                                                            orderClient.changeOf
                                                        )
                                                      : ''
                                              }`
                                            : 'Tarjeta bancaria'}
                                    </Descriptions.Item>

                                    <Descriptions.Item label='Total'>
                                        {' '}
                                        ${formatMoney(orderClient.total)}
                                    </Descriptions.Item>
                                </Descriptions>

                                {orderClient.ignoreDeliveryCost &&
                                    orderClient.orderType === 2 && (
                                        <Alert
                                            message='Costo de envío pendiente...'
                                            type='warning'
                                            showIcon
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 10,
                                            }}
                                        />
                                    )}
                                <Collapse
                                    ghost
                                    defaultActiveKey={['1']}
                                    expandIcon={({ isActive }) => (
                                        <CaretRightOutlined
                                            rotate={isActive ? 90 : 0}
                                        />
                                    )}
                                >
                                    {/* <Panel header="Detalle del pedido " key="1">
                                    {orderClient.details.map((item) => {
                                        return (
                                            <p key={item.itemId} className="status-order-items">
                                                [{item.quantity}] {item.itemName}{' '}
                                                {item.instructions !== ''
                                                    ? `(${item.instructions})`
                                                    : ''}
                                            </p>
                                        );
                                    })}
                                </Panel> */}
                                    <Panel header='Detalle del pedido ' key='1'>
                                        {orderClient.details.map(
                                            (item, index) => {
                                                return (
                                                    <div
                                                        key={`${item.itemId}-${index}`}
                                                        className='status-order-items'
                                                    >
                                                        <span>
                                                            [{item.quantity}]{' '}
                                                            {item.itemName}{' '}
                                                            {item.orderDetailUom !==
                                                            null
                                                                ? `(${item.orderDetailUom.units.toFixed(
                                                                      2
                                                                  )}${' '}${
                                                                      item
                                                                          .orderDetailUom
                                                                          .unitTypeName
                                                                  }), $${formatMoney(
                                                                      item.linePrice
                                                                  )} `
                                                                : ''}
                                                            {item.instructions !==
                                                            ''
                                                                ? `(${item.instructions})`
                                                                : ''}
                                                        </span>

                                                        {item.selectedOptions
                                                            .length > 0 && (
                                                            <Collapse
                                                                ghost
                                                                defaultActiveKey={[
                                                                    '2',
                                                                ]}
                                                                expandIcon={({
                                                                    isActive,
                                                                }) => (
                                                                    <CaretRightOutlined
                                                                        rotate={
                                                                            isActive
                                                                                ? 90
                                                                                : 0
                                                                        }
                                                                    />
                                                                )}
                                                            >
                                                                <Panel
                                                                    header='Opciones'
                                                                    key='2'
                                                                >
                                                                    {item.selectedOptions.map(
                                                                        (
                                                                            option
                                                                        ) => {
                                                                            return (
                                                                                <Paragraph
                                                                                    className='section-items'
                                                                                    key={
                                                                                        option.name +
                                                                                        option.id
                                                                                    }
                                                                                >
                                                                                    <Text
                                                                                        strong
                                                                                    >{`${option.sectionName}: `}</Text>
                                                                                    <Text>{`${option.name}`}</Text>

                                                                                    {option.price && (
                                                                                        <Text
                                                                                            type='secondary'
                                                                                            className='gray margin-left'
                                                                                        >
                                                                                            $
                                                                                            {formatMoney(
                                                                                                option.price *
                                                                                                    item.quantity
                                                                                            )}
                                                                                        </Text>
                                                                                    )}
                                                                                </Paragraph>
                                                                            );
                                                                        }
                                                                    )}
                                                                </Panel>
                                                            </Collapse>
                                                        )}
                                                    </div>
                                                );
                                            }
                                        )}
                                    </Panel>
                                </Collapse>
                            </>
                        )}
                </div>

                {activeOrder && (
                    <div className='status-order__container-steps'>
                        <Divider orientation='left'>Estatus</Divider>

                        {orderClient !== null && orderClient.orderType === 1 ? (
                            <Steps direction='vertical' current={status}>
                                <Step
                                    title='Orden aceptada por el restaurante'
                                    description='Empezaremos a preparar tu orden'
                                />
                                {/* <Step title="Preparando tu orden" description="Estamos realizando tu orden" /> */}
                                <Step
                                    title='Tu orden ya esta lista'
                                    description='Ya puedes pasar al restaurante'
                                />
                                <Step
                                    title='Tu orden fue entregada'
                                    description='Orden completada'
                                />
                            </Steps>
                        ) : (
                            <Steps direction='vertical' current={status}>
                                <Step
                                    title='Orden aceptada por el restaurante'
                                    description='Empezaremos a preparar tu orden'
                                />
                                {/* <Step title="Preparando tu orden" description="Estamos realizando tu orden" /> */}
                                <Step
                                    title='Tu orden va en camino'
                                    description='Llegará pronto'
                                />
                                <Step
                                    title='Tu orden llegó'
                                    description='Orden lista'
                                />
                            </Steps>
                        )}
                    </div>
                )}

                {!activeOrder && orderClient !== null && (
                    <div className='status-order__container-steps'>
                        <Alert
                            message='No hay Orden'
                            description={`En estos momentos no cuenta con una orden activa con el número ${idOrder}`}
                            type='info'
                            showIcon
                        />
                    </div>
                )}

                {activeOrder && (
                    <div className='status-order__container-buttons'>
                        <Button
                            className='btn-resfresh'
                            danger
                            block
                            onClick={() => setreloadPage(true)}
                        >
                            Actualizar Status
                        </Button>

                        {status === -1 && timeOutCancel && (
                            <Button
                                type='primary'
                                danger
                                block
                                onClick={cancelOrder}
                            >
                                Cancelar pedido
                            </Button>
                        )}

                        {orderClient?.requiresOtherPaymentMethod && (
                            <Button
                                className='btn-resfresh'
                                type='primary'
                                block
                                onClick={() => changePaymentHandle()}
                            >
                                Cambiar Método de Pago
                            </Button>
                        )}
                    </div>
                )}

                <Modal
                    isVisible={isModalVisible}
                    setIsVisible={setIsModalVisible}
                    title={titleModal}
                    footer={false}
                    destroyOnClose
                >
                    {contextModal}
                </Modal>
            </Layout>
            <Footer basket={false} showOrder showHome isOrder />
        </>
    );
};

export default StatusOrder;
