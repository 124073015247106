import React, { useState } from 'react';
import {
    Form,
    Input,
    Button,
    Spin,
    notification,
    Divider,
    Row,
    Col,
    Typography,
} from 'antd';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { changePasswordApi } from '../../../api/user';
import { logout } from '../../../api/auth';
import {
    validatePassword,
    validateTextHasCapitalize,
    validateTextHasLowercase,
    validateTextHasNumber,
    validateTextHasCharacter,
} from '../../../utils/formValidation';

import useAuth from '../../../hooks/useAuth';

import './ChangePasswordForm.scss';

const ChangePasswordForm = () => {
    const [loading, setLoading] = useState(false);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasLowerCase, setHasLowerCase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasCharacter, setHasCharacter] = useState(false);
    const [lenghtCorrect, setLenghtCorrect] = useState(false);

    const { user } = useAuth();
    const { Text } = Typography;

    const layout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
            lg: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
            lg: {
                span: 14,
            },
        },
    };
    const tailLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
            lg: {
                span: 8,
                offset: 8,
            },
        },
    };

    const onFinish = async (values) => {
        setLoading(true);
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        let data = values;
        data['UserId'] = clientId;

        const result = await changePasswordApi(data);

        if (result === undefined) {
            setLoading(false);

            notification['error']({
                message: 'Ocurrió un error, Intentelo mas tarde',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }

        if (result.statusCode === 200) {
            setLoading(false);

            notification['success']({
                message: 'Operación exitosa',
                // description: "Revise su bandeja de entrada, por favor."
            });
        } else {
            let messageError = 'Ocurrió un error, Intentelo otra vez';

            if (result.statusCode === 409) {
                messageError = result.description;
            }

            if (result.statusCode === 400) {
                messageError = result.description;
            }

            setLoading(false);

            notification['error']({
                message: messageError,
            });
        }
    };

    const checkPassword = (text) => {
        if (validateTextHasCapitalize(text)) {
            setHasUpperCase(true);
        } else {
            setHasUpperCase(false);
        }

        if (validateTextHasLowercase(text)) {
            setHasLowerCase(true);
        } else {
            setHasLowerCase(false);
        }

        if (validateTextHasNumber(text)) {
            setHasNumber(true);
        } else {
            setHasNumber(false);
        }

        if (validateTextHasCharacter(text)) {
            setHasCharacter(true);
        } else {
            setHasCharacter(false);
        }

        if (text.length >= 6) {
            setLenghtCorrect(true);
        } else {
            setLenghtCorrect(false);
        }
    };

    return (
        <Spin tip='Cargando...' spinning={loading}>
            <Form
                {...layout}
                onFinish={onFinish}
                className='change-password-form'
            >
                <Form.Item
                    name='OldPassword'
                    label='Contraseña Anterior'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una contraseña',
                        },
                        () => ({
                            validator(rule, value) {
                                if (validatePassword(value)) {
                                    return Promise.resolve();
                                } else if (!validatePassword(value)) {
                                    return Promise.reject(
                                        'La contrase\u00F1a anterior debe coincidir con los puntos establecidos abajo'
                                    );
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name='NewPassword'
                    label='Contraseña Nueva'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una contraseña',
                        },
                        () => ({
                            validator(rule, value) {
                                if (validatePassword(value)) {
                                    return Promise.resolve();
                                } else if (!validatePassword(value)) {
                                    return Promise.reject(
                                        'La contrase\u00F1a nueva debe coincidir con los puntos establecidos abajo'
                                    );
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        onChange={(e) => checkPassword(e.target.value)}
                    />
                </Form.Item>

                <div className='validate-password'>
                    <Row justify='center'>
                        <Col xs={24} sm={8} lg={8}>
                            <Divider>La contraseña debe incluir:</Divider>
                            <ul>
                                <li>
                                    {hasUpperCase && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una mayúscula{' '}
                                        </Text>
                                    )}
                                    {!hasUpperCase && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una mayúscula{' '}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {hasLowerCase && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una minúscula{' '}
                                        </Text>
                                    )}
                                    {!hasLowerCase && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una minúscula{' '}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {hasNumber && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un número{' '}
                                        </Text>
                                    )}
                                    {!hasNumber && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un número{' '}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {hasCharacter && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un carácter:{' '}
                                            {'?()!@#$%^&*[]"\';:_-<>. =+/ '}{' '}
                                        </Text>
                                    )}
                                    {!hasCharacter && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un carácter:{' '}
                                            {'?()!@#$%^&*[]"\';:_-<>. =+/'}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {lenghtCorrect && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Tiene 6 o más caracteres{' '}
                                        </Text>
                                    )}
                                    {!lenghtCorrect && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Tiene 6 o más caracteres{' '}
                                        </Text>
                                    )}
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>

                <Form.Item
                    name='ConfirmPassword'
                    label='Confirmar Contraseña'
                    dependencies={['NewPassword']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese la contraseña anterior',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (
                                    !value ||
                                    getFieldValue('NewPassword') === value
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Las dos contraseñas ingresadas no son iguales'
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    {...tailLayout}
                    className='reset-password-form__button'
                >
                    <Button type='primary' htmlType='submit' block>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default ChangePasswordForm;
