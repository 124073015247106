import { basePath } from './config';

// DELETE CARD
export function deleteStripeCardApi(token, idCard) {
    const url = `${basePath}/client/stripe/payment-method/${idCard}`;

    const params = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}
