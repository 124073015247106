import React, { useEffect, useState } from 'react';
import {
    Typography,
    Row,
    Col,
    Divider,
    Button,
    Modal,
    notification,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
    formatMoney,
    getPriceSubscription,
    messageError,
} from '../../../../../utils/general';
import PaymentMethods from '../../../../General/PaymentMethods/PaymentMethods';

import './PaymentDetails.scss';
import { getAccessTokenApi } from '../../../../../api/auth';
import {
    deleteStripeSubscriptionApi,
    getPriceProductSubscriptionApi,
    getProductsSubscriptionApi,
} from '../../../../../api/subscription';

export default function PaymentDetails({ companySubscriptionInfo, loading }) {
    const [priceId, setPriceId] = useState('');
    const { Title, Paragraph } = Typography;
    const { confirm } = Modal;

    useEffect(() => {
        const token = getAccessTokenApi();

        getProductsSubscriptionApi(token)
            .then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        const found = response.result.find(
                            (element) =>
                                element.suscriptionType ===
                                companySubscriptionInfo.suscriptionType
                        );
                        if (found !== undefined && found !== null) {
                            getPriceProductSubscriptionApi(
                                token,
                                found.stripeProductId
                            ).then((res) => {
                                if (res) {
                                    if (res.statusCode === 200) {
                                        setPriceId(res.result[0]['id']);
                                    } else {
                                        notification['error']({
                                            message:
                                                'Ocurrió un error, intentelo mas tarde.',
                                        });
                                    }
                                } else {
                                    notification['error']({
                                        message: messageError(),
                                    });
                                }
                            });
                        }
                    } else {
                        notification['error']({
                            message: 'Ocurrió un error, intentelo mas tarde.',
                        });
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                }
            })
            .catch((err) => console.log(err));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function showDeleteConfirm() {
        confirm({
            title: '¿Estás seguro de querer cancelar la suscripción?',
            icon: <ExclamationCircleOutlined />,
            content: 'Podra volverla activar mas adelante.',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                handleCancelSusbcription();
            },
            onCancel() {},
        });
    }

    const handleCancelSusbcription = async () => {
        const token = getAccessTokenApi();

        const result = await deleteStripeSubscriptionApi(token);
        if (result) {
            if (result.statusCode === 200) {
                notification['success']({
                    message: 'Suscripción cancelada exitosamente.',
                });
                window.location.reload();
            }
        } else {
            notification['error']({
                message: messageError(),
            });
        }
    };

    return (
        <div className='payment-details'>
            <Row>
                <Col span={24}>
                    <Title level={3}>Detalles del Pago</Title>
                    <PaymentMethods
                        subscription={false}
                        changePaymentMethod
                        priceId={priceId}
                        payTypeCurrent={companySubscriptionInfo.payType}
                        currentCard={companySubscriptionInfo.paymentMethodId}
                        card
                        transfer
                        deposite
                    />

                    {companySubscriptionInfo.payType === 2 &&
                        companySubscriptionInfo.status === 1 && (
                            <Paragraph style={{ marginTop: 10 }}>
                                Nosotros realizaremos el cargo de{' '}
                                {formatMoney(
                                    getPriceSubscription(
                                        companySubscriptionInfo.suscriptionType
                                    )
                                )}{' '}
                                cada{' '}
                                {moment
                                    .unix(companySubscriptionInfo.startDate)
                                    .format('DD')}{' '}
                                de cada mes de la tarjeta seleccionada.
                            </Paragraph>
                        )}
                    <Divider />
                    {/* MOSTRAR SOLO CON EL PAGO CON TARJETA */}
                    {companySubscriptionInfo.status !== 5 && (
                        <Button
                            type='primary'
                            danger
                            onClick={showDeleteConfirm}
                        >
                            Cancelar Suscripción
                        </Button>
                    )}
                </Col>
            </Row>
        </div>
    );
}
