import React, { useEffect, useState } from 'react';
import {
    Form,
    Spin,
    Input,
    Checkbox,
    Tooltip,
    Button,
    Col,
    Row,
    Select,
    notification,
} from 'antd';
import {
    UserOutlined,
    MailOutlined,
    PhoneOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../api/auth';
import { updateUserAdminApi } from '../../../../api/user';
import { getCompanyIdApi } from '../../../../api/company';
import { userLogOut } from '../../../../utils/general';

import './EditUserForm.scss';

const EditUserForm = ({ user, setIsVisibleModal, setreloadUsers }) => {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setUserData([
            {
                name: ['Name'],
                value: user.name,
            },
            {
                name: ['LastName'],
                value: user.lastName,
            },
            {
                name: ['email'],
                value: user.email,
            },
            {
                name: ['userType'],
                value: user.userType,
            },
            {
                name: ['phoneNumber'],
                value: user.phoneNumber,
            },
            {
                name: ['Active'],
                value: user.active,
            },
        ]);
    }, [user]);

    const updateUser = async (values) => {
        const token = getAccessTokenApi();
        if (token == null) {
            userLogOut();
            return;
        }
        const companyId = getCompanyIdApi();

        let userUpdate = values;
        delete userUpdate.email;
        userUpdate['phoneNumber'] = userUpdate['phoneNumber'].toString();
        userUpdate['companyId'] = companyId;
        userUpdate['id'] = user.id;

        const result = await updateUserAdminApi(token, userUpdate);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                //recreo correctamenta
                setIsVisibleModal(false);
                setreloadUsers(true);
                notification['success']({
                    message: 'Usuario creado exitosamente',
                });
            }
        }

        setLoading(false);
    };

    return (
        <div className='edit-user-form'>
            <EditForm
                userData={userData}
                setUserData={setUserData}
                updateUser={updateUser}
                loading={loading}
            />
        </div>
    );
};

export default EditUserForm;

function EditForm(props) {
    const { userData, setUserData, updateUser, loading } = props;
    const { Option } = Select;
    const [disableButton, setDisableButton] = useState(true);

    const tailLayout = {
        wrapperCol: { offset: 0, span: 12 },
    };

    return (
        <Spin tip='Guardando...' spinning={loading}>
            <Form
                className='form-edit'
                onFinish={updateUser}
                fields={userData}
                scrollToFirstError={true}
                onFieldsChange={(changedFields, allFields) => {
                    setUserData(allFields);
                    if (disableButton !== false) {
                        setDisableButton(false);
                    }
                }}
            >
                <Row gutter={24}>
                    <Col md={24} xs={24}>
                        <Form.Item
                            name='Name'
                            label='Nombre del usuario'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un nombre',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder='Nombre'
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item
                            name='LastName'
                            label='Apellido del usuario'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un apellido',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder='Apellido'
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label='Correo Electrónico'
                            name='email'
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'El correo electrónico no es válido',
                                },
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un correo electrónico',
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder='Email'
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name='phoneNumber'
                            label={
                                <span>
                                    Teléfono&nbsp;
                                    <Tooltip title='Tú número telefónico debe contener 10 dígitos'>
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingresa tú número telefónico',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            value.toString().length === 10
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Por favor ingresa tú número telefónico a 10 dígitos'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder='Teléfono'
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label='Rol'
                            name='userType'
                            // hasFeedback
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Por favor seleccione un rol',
                            //     },
                            // ]}
                        >
                            <Select placeholder='Selecciona un rol' disabled>
                                <Option value={1}>Administrador</Option>
                                <Option value={4}>Colaborador</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    {...tailLayout}
                    name='Active'
                    valuePropName='checked'
                >
                    <Checkbox>Activo</Checkbox>
                </Form.Item>

                <Form.Item>
                    <Button block type='primary' htmlType='submit'>
                        Actualizar Usuario
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
