import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Spin,
    notification,
    Row,
    Col,
    Divider,
    InputNumber,
} from 'antd';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { editGroupRunner } from '../../../../api/runner';

const layout = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const EditRunnerGroup = (props) => {
    const { element, setIsModalVisible, groupAdmin, setGroupAdmin } = props;
    const [groupForm, setGroupForm] = useState([]);
    const [form] = Form.useForm();
    useEffect(() => {
        setGroupForm([
            {
                name: ['Name'],
                value: element.name,
            },
            {
                name: ['DistanceKm'],
                value: element.distanceKm,
            },
            {
                name: ['PricePerKm'],
                value: element.pricePerKm,
            },
            {
                name: ['ExceededPricePerKm'],
                value: element.exceededPricePerKm,
            },
        ]);

        return () => {
            form.resetFields();
            setGroupForm([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [element]);

    const token = getAccessTokenApi();
    const [loadingForm, setLoadingForm] = useState(false);

    function Cancel() {
        // form.resetFields();
        setIsModalVisible(false);
    }

    const onFinish = async (values) => {
        setLoadingForm(true);

        let formData = values;
        const result = await editGroupRunner(token, formData, element.id);

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);

            return false;
        }
        if (result.statusCode === 400) {
            notification['error']({
                message: 'Solo números positivos son permitidos',
            });

            return false;
        }

        if (result.statusCode === 200) {
            setLoadingForm(false);

            notification['success']({
                message: 'Grupo modificado exitosamente',
            });
            const found = groupAdmin.findIndex((obj) => obj.id === element.id);
            groupAdmin[found].name = result.result.name;
            groupAdmin[found].distanceKm = result.result.distanceKm;
            groupAdmin[found].exceededPricePerKm =
                result.result.exceededPricePerKm;
            groupAdmin[found].pricePerKm = result.result.pricePerKm;

            setGroupAdmin(groupAdmin);

            setIsModalVisible(false);
        } else {
            setLoadingForm(false);
            let message =
                'Ocurrio un problema al editar grupo, Intentelo otra vez';
            if (result.statusCode === 404) {
                message = result.description;
            }
            notification['error']({
                message: message,
            });
        }
    };

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">
            <Form
                {...layout}
                className="groupName"
                onFinish={onFinish}
                form={form}
                fields={groupForm}
                onFieldsChange={(changedFields, allFields) => {
                    setGroupForm(allFields);
                }}
            >
                <Form.Item
                    label="Nombre del grupo"
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder="Nombre del grupo" />
                </Form.Item>
                <Form.Item
                    label="Distancia base (Km)"
                    name="DistanceKm"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese la distancia',
                        },
                    ]}
                >
                    <InputNumber placeholder="1" min="0" />
                </Form.Item>
                <Form.Item
                    label="Precio base"
                    name="PricePerKm"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese el precio',
                        },
                    ]}
                >
                    <InputNumber placeholder="50" min="0" />
                </Form.Item>
                <Form.Item
                    label="Precio por kilómetro excedido"
                    name="ExceededPricePerKm"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese el precio',
                        },
                    ]}
                >
                    <InputNumber placeholder="50" min="0" />
                </Form.Item>

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default EditRunnerGroup;
