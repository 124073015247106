import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Typography,
    Divider,
    Row,
    Col,
    Select,
    Spin,
    notification,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import { editOrderStatus } from '../../../api/order';
import { userLogOut } from '../../../utils/general';

import './CancelOrderForm.scss';

const CancelOrderForm = (props) => {
    const {
        setIsModalVisible,
        order,
        setReloadPendingOrders,
        setReloadOrders,
    } = props;
    const { TextArea } = Input;
    const { Title } = Typography;
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 20 },
    };
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [showOtherReason, setShowOtherReason] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const onFinish = async (values) => {
        setLoading(true);
        let reasoncancelation = values.reason;

        if (reasoncancelation === 'Otra') {
            reasoncancelation = values.otherReason;

            if (reasoncancelation === '' || reasoncancelation === ' ') {
                notification['error']({
                    message: 'Debe escribir una razón',
                });
                setLoading(false);

                return false;
            }
        }

        let data = {
            orderId: order.orderId,
            status: 7,
            cancelationReason: reasoncancelation,
            companyId: companyId,
            fromClient: false,
        };

        const result = await editOrderStatus(token, data);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
            setIsModalVisible(false);
            setLoading(false);

            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Orden cancelada exitosamente',
            });

            if (order.statusText === 'Aprobada') {
                setReloadPendingOrders(true);
            } else {
                if (setReloadPendingOrders !== undefined) {
                    setReloadPendingOrders(true);
                } else {
                    if (setReloadOrders !== undefined) {
                        setReloadOrders(true);
                    }
                }
            }

            setIsModalVisible(false);
        } else {
            if (result.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1200);
            } else {
                let message = 'Ocurrió un problema, Intentelo mas tarde';

                notification['error']({
                    message: message,
                });
            }
        }

        setLoading(false);
    };

    const handleReason = (value) => {
        if (value === 'Otra') {
            setShowOtherReason(true);
        } else {
            if (showOtherReason) {
                setShowOtherReason(false);
            }
        }
    };

    const Cancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Spin spinning={loading} tip="Cancelando...">
            <Form {...layout} name="cancelOrder" onFinish={onFinish}>
                <Row>
                    <Col span={4} className="text-center">
                        <ExclamationCircleOutlined
                            style={{ fontSize: '28px', color: '#faad14' }}
                        />
                    </Col>
                    <Col span={20}>
                        <Title level={4}>
                            ¿Estás seguro de cancelar esta orden?
                        </Title>
                    </Col>
                </Row>
                <Form.Item
                    label="Razón"
                    name="reason"
                    dependencies={['otherReason']}
                    rules={[
                        {
                            required: true,
                            message: 'Por favor indique la razón o motivo.',
                        },
                    ]}
                >
                    <Select onChange={handleReason}>
                        <Select.Option value="Dirección equivocada">
                            Dirección equivocada
                        </Select.Option>
                        <Select.Option value="Precio">Precio</Select.Option>
                        <Select.Option value="Método de Pago">
                            Método de Pago
                        </Select.Option>
                        <Select.Option value="Otra">Otra</Select.Option>
                    </Select>
                </Form.Item>

                {showOtherReason && (
                    <Form.Item
                        label="Otra razón"
                        name="otherReason"
                        dependencies={['reason']}
                        rules={[
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (
                                        getFieldValue('reason') === 'Otra' &&
                                        value !== undefined
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        'Debe escribir una razón'
                                    );
                                },
                            }),
                        ]}
                    >
                        <TextArea
                            placeholder="Razón"
                            autoSize={{ minRows: 3, maxRows: 5 }}
                            showCount
                            maxLength={100}
                        />
                    </Form.Item>
                )}

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            No
                        </Button>
                    </Col>
                    <Col span={12} className="text-right">
                        <Form.Item>
                            <Button type="primary" danger htmlType="submit">
                                Si
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default CancelOrderForm;
