import React, { useEffect, useState } from 'react';
import {
    Button,
    Typography,
    Row,
    Col,
    Divider,
    Skeleton,
    notification,
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Summary from './Summary';
import PaymentDetails from './PaymentDetails';

import './MySubscription.scss';
import { getAccessTokenApi } from '../../../../api/auth';
import {
    getCompanyIdApi,
    getCompanySuscriptionApi,
} from '../../../../api/company';
import {
    companySubscriptionStatus,
    messageError,
} from '../../../../utils/general';

export default function MySubscription(props) {
    const { setTab } = props;
    const [companySubscriptionInfo, setCompanySubscriptionInfo] =
        useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = getAccessTokenApi();
        const companyId = getCompanyIdApi();

        getCompanySuscriptionApi(token, companyId).then((response) => {
            if (response) {
                if (response.statusCode === 200) {
                    setCompanySubscriptionInfo(response.result);
                } else {
                    let msg =
                        'Ocurrio un error al cargar la información, intentelo de nuevo.';
                    if (response.statusCode === 404) {
                        msg = response.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            } else {
                notification['error']({
                    message: messageError(),
                });
            }
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className='my-subscription'>
            {!loading && (
                <SubscriptionTop
                    setTab={setTab}
                    companySubscriptionInfo={companySubscriptionInfo}
                />
            )}
            {loading && <Skeleton active />}
            <Divider />
            <Row>
                <Col xs={24} md={12}>
                    <Summary
                        companySubscriptionInfo={companySubscriptionInfo}
                        loading={loading}
                    />
                </Col>
                <Col xs={24} md={12}>
                    {!loading && (
                        <PaymentDetails
                            companySubscriptionInfo={companySubscriptionInfo}
                        />
                    )}
                    {loading && <Skeleton active paragraph />}
                </Col>
            </Row>
        </div>
    );
}

function SubscriptionTop(props) {
    const { setTab, companySubscriptionInfo } = props;
    const { Title } = Typography;
    const [status, setStatus] = useState(true);

    useEffect(() => {
        if (companySubscriptionInfo) {
            setStatus(
                companySubscriptionStatus(companySubscriptionInfo.status)
            );
        }
    }, [companySubscriptionInfo]);

    return (
        <Row className='my-subscription__top'>
            <Col xs={24} md={16}>
                <Title level={3}>Plan de Suscripción</Title>
            </Col>
            <Col xs={24} md={8}>
                <Row>
                    <Col xs={12} md={10}>
                        <Button
                            className={
                                status === 'Activa'
                                    ? 'btn-state-active'
                                    : 'btn-state-inactive'
                            }
                            icon={
                                status === 'Activa' ? (
                                    <CheckOutlined />
                                ) : (
                                    <CloseOutlined />
                                )
                            }
                            size='large'
                        >
                            {status === 'Activa'
                                ? 'Activo'
                                : status === 'Cancelada por Compañía' ||
                                  status === 'Cancelada por Super Admin'
                                ? 'Cancelado'
                                : 'Inactivo'}
                        </Button>
                    </Col>
                    <Col xs={12} md={14}>
                        <Button
                            size='large'
                            className='btn-change-package'
                            onClick={() => setTab('2')}
                        >
                            Cambiar Plan
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
