import React, { useState, useRef, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Image, Skeleton, Typography } from 'antd';
import {
    getCompanyBannerCouponsApi,
    getCompanyIdApi,
} from '../../../api/company';
import { getAccessTokenApi } from '../../../api/auth';
import Modal from '../../../../src/components/General/Modal';
import moment from 'moment';

import './PromoBanner.scss';
import { formatMoney } from '../../../utils/general';

export default function PromoBanner() {
    const isMounted = useRef(true);
    const [promos, setPromos] = useState([]);
    const [loadingPromo, setLoadingPromo] = useState(true);
    const [contextModal, setContextModal] = useState(null);
    const [isModalVisibleForm, setIsModalVisibleForm] = useState(false);
    const [titleModal, setTitleModal] = useState('Titulo');

    useEffect(() => {
        const token = getAccessTokenApi();
        const companyId = getCompanyIdApi();
        getCompanyBannerCouponsApi(token, companyId).then((response) => {
            if (isMounted) {
                if (response) {
                    setPromos(response);
                }
                setLoadingPromo(false);
            }
        });
        return () => {
            isMounted.current = false;
        };
    }, []);

    const showModal = (coupon) => {
        setIsModalVisibleForm(true);
        setTitleModal(coupon.name);
        setContextModal(
            <CouponForm
                coupon={coupon}
                setIsModalVisibleForm={setIsModalVisibleForm}
            ></CouponForm>
        );
    };

    return (
        <div className='promo-banner'>
            {!loadingPromo && (
                <>
                    {promos.length > 0 && (
                        <Carousel showThumbs={false} showStatus={false}>
                            {promos.map((promo) => (
                                <ImagePromo
                                    key={promo.cuponId}
                                    coupon={promo}
                                    showModal={showModal}
                                />
                            ))}
                        </Carousel>
                    )}
                </>
            )}
            {loadingPromo && <Skeleton active />}
            <Modal
                className={['margin-form']}
                isVisible={isModalVisibleForm}
                setIsVisible={setIsModalVisibleForm}
                title={titleModal}
                footer={false}
                // destroyOnClose
            >
                {contextModal}
            </Modal>
        </div>
    );
}

function ImagePromo(props) {
    const { coupon, showModal } = props;
    const { couponImage } = coupon;

    return (
        <div>
            <Image
                width={320}
                src={`data:image/${couponImage.extension};base64, ${couponImage.imageData}`}
                preview={false}
                onClick={() => showModal(coupon)}
            />
        </div>
    );
}

function CouponForm(props) {
    const { coupon } = props;
    const { Title } = Typography;

    let cuponType = '';
    switch (coupon.type) {
        case 1:
            cuponType = 'Promocion 2x1';
            break;
        case 2:
            cuponType = 'Promocion 3x2';
            break;
        case 3:
            cuponType = `Promocion descuento de $${formatMoney(
                coupon.discountPrice
            )}`;
            break;
        case 4:
            cuponType = `Promocion descuento de ${coupon.discountPercent}% `;
            break;
        case 5:
            cuponType = `Promocion descuento de $${formatMoney(
                coupon.discountPrice
            )} en la orden`;
            break;
        case 6:
            cuponType = `Promocion descuento de ${coupon.discountPercent}% en la orden`;
            break;
        case 7:
            cuponType = 'Promocion envío gratis';
            break;

        default:
            break;
    }

    let periodType = '';
    if (coupon.durationType === 'range-dates') {
        periodType = `${moment
            .unix(coupon.startDate)
            .format('DD/MM/YYYY, h:mm a')} - ${moment
            .unix(coupon.endDate)
            .format('DD/MM/YYYY, h:mm a')}`;
    } else {
        const daysPromoArray = coupon.applicableDays.split(',');

        let daysString = '';
        daysPromoArray.forEach((ele) => {
            switch (ele) {
                case '1':
                    daysString = `${daysString} Lunes,`;
                    break;
                case '2':
                    daysString = `${daysString} Martes, `;
                    break;
                case '3':
                    daysString = `${daysString} Miércoles, `;
                    break;
                case '4':
                    daysString = `${daysString} Jueves, `;
                    break;
                case '5':
                    daysString = `${daysString} Viernes, `;
                    break;
                case '6':
                    daysString = `${daysString} Sábado, `;
                    break;
                case '7':
                    daysString = `${daysString} Domingo, `;
                    break;
                default:
                    return '';
            }
        });

        periodType = `${daysString} de ${moment
            .unix(coupon.timeStart)
            .format('h:mm a')} - ${moment
            .unix(coupon.timeEnd)
            .format('h:mm a')}`;
    }

    return (
        <div className='container-coupon'>
            <Title level={3}>{cuponType}</Title>

            <Image
                className='container-img'
                width={320}
                src={`data:image/${coupon.couponImage.extension};base64, ${coupon.couponImage.imageData}`}
                preview={false}
            />
            <Title level={5}>Válido: {periodType}</Title>
            {coupon.minimum !== 0 && (
                <Title level={5}>
                    Consumo mínimo: ${formatMoney(coupon.minimum)}
                </Title>
            )}
        </div>
    );
}
