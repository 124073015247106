import React, { useState, useEffect } from 'react';
import {
    Switch,
    List,
    Tooltip,
    Button,
    Typography,
    Modal as ModalAntd,
    notification,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    StopOutlined,
    CheckOutlined,
    UserAddOutlined,
} from '@ant-design/icons';
import Modal from '../../../General/Modal';
import EditUserForm from '../EditUserForm';
import AddUserForm from '../AddUserForm';
import { deleteUserApi, updateUserAdminApi } from '../../../../api/user';
import { getAccessTokenApi } from '../../../../api/auth';
import useAuth from '../../../../hooks/useAuth';
import { userLogOut } from '../../../../utils/general';

import './ListUsers.scss';

const { confirm } = ModalAntd;

const ListUsers = (props) => {
    const { usersActive, usersInactive, setreloadUsers } = props;
    const [viewUsersActive, setViewUsersActive] = useState(true);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setmodalContent] = useState(null);
    const { user } = useAuth();
    const idCurrentUser =
        user[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
        ];
    const { Text } = Typography;

    const addUserModal = () => {
        setIsVisibleModal(true);
        setModalTitle('Creando nuevo usuario');
        setmodalContent(
            <AddUserForm
                setIsVisibleModal={setIsVisibleModal}
                setreloadUsers={setreloadUsers}
            />
        );
    };

    return (
        <div className='list-users'>
            <div className='list-users__header'>
                <div className='list-users__header-switch'>
                    <Switch
                        defaultChecked
                        onChange={() => setViewUsersActive(!viewUsersActive)}
                    />
                    <Text>
                        {viewUsersActive
                            ? 'Usuarios Activos'
                            : 'Usuarios Inactivos'}
                    </Text>
                </div>

                <Button
                    type='primary'
                    onClick={addUserModal}
                    icon={<UserAddOutlined />}
                >
                    Nuevo Usuario
                </Button>
            </div>

            {viewUsersActive ? (
                <UsersActive
                    usersActive={usersActive}
                    setIsVisibleModal={setIsVisibleModal}
                    setModalTitle={setModalTitle}
                    setmodalContent={setmodalContent}
                    setreloadUsers={setreloadUsers}
                    idCurrentUser={idCurrentUser}
                />
            ) : (
                <UsersInactive
                    usersInactive={usersInactive}
                    setreloadUsers={setreloadUsers}
                    idCurrentUser={idCurrentUser}
                />
            )}

            <Modal
                destroyOnClose
                title={modalTitle}
                isVisible={isVisibleModal}
                setIsVisible={setIsVisibleModal}
                footer={false}
            >
                {modalContent}
            </Modal>
        </div>
    );
};

function UsersActive(props) {
    const { Title } = Typography;
    const {
        usersActive,
        setIsVisibleModal,
        setModalTitle,
        setmodalContent,
        setreloadUsers,
        idCurrentUser,
    } = props;

    const editUser = (user) => {
        setIsVisibleModal(true);
        setModalTitle(
            `Editar ${user.name ? user.name : '...'}  ${
                user.lastName ? user.lastName : '...'
            }`
        );
        setmodalContent(
            <EditUserForm
                user={user}
                setIsVisibleModal={setIsVisibleModal}
                setreloadUsers={setreloadUsers}
            />
        );
    };

    return (
        <>
            <Title level={3}>Lista de Usuarios Activos</Title>
            <List
                className='users-active'
                itemLayout='horizontal'
                dataSource={usersActive}
                pagination={{
                    onChange: (page) => {},
                    pageSize: 6,
                }}
                renderItem={(user) => (
                    <UserActive
                        user={user}
                        editUser={editUser}
                        setreloadUsers={setreloadUsers}
                        idCurrentUser={idCurrentUser}
                    />
                )}
            />
        </>
    );
}

function UserActive(props) {
    const { user, editUser, setreloadUsers, idCurrentUser } = props;
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const confirmDesactiveUser = () => {
        confirm({
            title: 'Desactivar usuario',
            content: `¿Estás seguro que quieres desactivar a ${user.name} ${user.lastName}?`,
            okText: 'Desactivar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                desactivateUser();
            },
        });
    };

    const desactivateUser = async () => {
        // const token = getAccessTokenApi();

        const userUpdate = {
            id: user.id,
            Active: false,
        };

        const result = await updateUserAdminApi(token, userUpdate);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setreloadUsers(true);
                notification['success']({
                    message: 'Usuario desactivado exitosamente',
                });
            }
        }
    };

    const showDeleteConfirm = () => {
        // const token = getAccessTokenApi();

        confirm({
            title: 'Eliminado usuario',
            content: `¿Estás seguro que quieres eliminar a ${user.name} ${user.lastName}?`,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                deleteUserApi(token, user.id)
                    .then((response) => {
                        if (response.statusCode === 200) {
                            notification['success']({
                                message: 'Usuario eliminado con exito',
                            });
                            setreloadUsers(true);
                        } else {
                            let msg = 'Ocurrió un error, intentelo mas tarde';

                            if (response.statusCode === 409) {
                                msg = response.description;
                            }
                            notification['error']({
                                message: msg,
                            });
                        }
                    })
                    .catch((err) => {
                        notification['error']({
                            message: err,
                        });
                    });
            },
        });
    };

    return (
        <List.Item
            actions={[
                <Tooltip placement='top' title='Editar Usuario'>
                    <Button
                        type='primary'
                        onClick={() => editUser(user)}
                        icon={<EditOutlined />}
                    />
                </Tooltip>,
                <Tooltip placement='top' title='Desactivar Usuario'>
                    <Button
                        danger
                        type='primary'
                        onClick={confirmDesactiveUser}
                        icon={<StopOutlined />}
                        disabled={user.id === idCurrentUser ? true : false}
                    />
                </Tooltip>,
                <Tooltip placement='top' title='Eliminar Usuario'>
                    <Button
                        danger
                        type='primary'
                        onClick={showDeleteConfirm}
                        icon={<DeleteOutlined />}
                        disabled={user.id === idCurrentUser ? true : false}
                    />
                </Tooltip>,
            ]}
        >
            <List.Item.Meta
                // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
                title={`
                    ${user.name ? user.name : '...'}
                    ${user.lastName ? user.lastName : '...'}
                `}
                description={user.email}
            />
        </List.Item>
    );
}

function UsersInactive({ usersInactive, setreloadUsers }) {
    const { Title } = Typography;

    return (
        <>
            <Title level={3}>Lista de Usuarios Inactivos</Title>
            <List
                className='users-active'
                itemLayout='horizontal'
                dataSource={usersInactive}
                pagination={{
                    onChange: (page) => {},
                    pageSize: 6,
                }}
                renderItem={(user) => (
                    <UserInactive user={user} setreloadUsers={setreloadUsers} />
                )}
            />
        </>
    );
}

function UserInactive(props) {
    const { user, setreloadUsers } = props;
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const confirmActiveUser = () => {
        confirm({
            title: 'Activar usuario',
            content: `¿Estás seguro que quieres activar a ${user.name} ${user.lastName}?`,
            okText: 'Activar',
            okType: 'primary',
            cancelText: 'Cancelar',
            onOk() {
                activateUser();
            },
        });
    };

    const activateUser = async () => {
        // const token = getAccessTokenApi();

        const userUpdate = {
            id: user.id,
            Active: true,
        };

        const result = await updateUserAdminApi(token, userUpdate);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                setreloadUsers(true);
                notification['success']({
                    message: 'Usuario activado exitosamente',
                });
            }
        }
    };

    const showDeleteConfirm = () => {
        // const token = getAccessTokenApi();

        confirm({
            title: 'Eliminado usuario',
            content: `¿Estás seguro que quieres eliminar a ${user.name} ${user.lastName}?`,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                deleteUserApi(token, user.id)
                    .then((response) => {
                        if (response.statusCode === 200) {
                            notification['success']({
                                message: 'Usuario eliminado con exito',
                            });
                            setreloadUsers(true);
                        } else {
                            let msg = 'Ocurrió un error, intentelo mas tarde';

                            if (response.statusCode === 409) {
                                msg = response.description;
                            }
                            notification['error']({
                                message: msg,
                            });
                        }
                    })
                    .catch((err) => {
                        notification['error']({
                            message: err,
                        });
                    });
            },
        });
    };

    return (
        <List.Item
            actions={[
                <Tooltip placement='top' title='Activar Usuario'>
                    <Button
                        type='primary'
                        onClick={confirmActiveUser}
                        icon={<CheckOutlined />}
                    />
                </Tooltip>,
                <Tooltip placement='top' title='Eliminar Usuario'>
                    <Button
                        danger
                        type='primary'
                        onClick={showDeleteConfirm}
                        icon={<DeleteOutlined />}
                    />
                </Tooltip>,
            ]}
        >
            <List.Item.Meta
                // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}
                title={`
                    ${user.name ? user.name : '...'}
                    ${user.lastName ? user.lastName : '...'}
                `}
                description={user.email}
            />
        </List.Item>
    );
}

export default ListUsers;
