import React from 'react';
import { Link } from 'react-router-dom';
import { Image, Affix, Button, Popconfirm, message } from 'antd';
import { LeftOutlined, SearchOutlined, CloseOutlined } from '@ant-design/icons';
import { getUserPath } from '../../../utils/general';
import { getCompanyIdApi } from '../../../api/company';

import './HeaderTop.scss';

export default function HeaderTop(props) {
    const {
        title,
        backIcon,
        backIconFunction,
        history,
        confirmBackIcon,
        logoCompany,
        imageCompany,
        route = '/login',
        search,
        handleSearch,
        closed,
        handleClosed,
        backIconHandle,
        goToUrl,
        backIconAuto,
        textCapitalize,
    } = props;
    const companyId = getCompanyIdApi();
    function cancelBackIcon() {
        history.push(`/type/${companyId}`);
        message.success('Se guardara su canasta para después.');
    }

    function handleGoTo() {
        window.location.href = goToUrl;
    }

    const handleReturn = () => {
        // console.log(history);
        if (history.length <= 2) {
            history.push('/');
        } else {
            if (history.location.pathname === '/perfil') {
                const userPath = getUserPath();
                history.push(
                    `${userPath === 'restaurant' ? `/type/${companyId}` : '/'}`
                );
            } else {
                history.goBack();
            }
        }
    };

    return (
        <Affix offsetTop>
            <div className="header-top">
                <div className="header-top__left">
                    {backIcon && (
                        <Link className="back-btn" to={route}>
                            <LeftOutlined />
                        </Link>
                    )}

                    {backIconAuto && (
                        <Button
                            onClick={handleReturn}
                            className="back-handle-btn"
                            type="text"
                            icon={
                                <LeftOutlined
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: 25,
                                    }}
                                />
                            }
                        />
                    )}

                    {backIconHandle && (
                        <Button
                            onClick={handleGoTo}
                            className="back-handle-btn"
                            type="text"
                            icon={
                                <LeftOutlined
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: 25,
                                    }}
                                />
                            }
                        />
                    )}

                    {backIconFunction && (
                        <Popconfirm
                            title="¿Desea vaciar su canasta?"
                            onConfirm={confirmBackIcon}
                            onCancel={cancelBackIcon}
                            okText="Si"
                            cancelText="No"
                        >
                            <Button
                                className="back-handle-btn"
                                type="text"
                                icon={
                                    <LeftOutlined
                                        style={{
                                            color: 'white',
                                            fontWeight: 'bold',
                                            fontSize: 25,
                                        }}
                                    />
                                }
                            />
                        </Popconfirm>
                    )}

                    {logoCompany && imageCompany !== null && (
                        <Image width={60} src={imageCompany} />
                    )}
                </div>

                <div className="header-top__center">
                    <h1
                        className={`${textCapitalize ? 'text-capitalize' : ''}`}
                    >
                        {title}
                    </h1>
                </div>

                <div className="header-top__right">
                    {search && (
                        <Button
                            className="search-btn"
                            type="text"
                            icon={
                                <SearchOutlined
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: 25,
                                    }}
                                />
                            }
                            onClick={handleSearch}
                        />
                    )}

                    {closed && (
                        <Button
                            className="search-btn"
                            type="text"
                            icon={
                                <CloseOutlined
                                    style={{
                                        color: 'white',
                                        fontWeight: 'bold',
                                        fontSize: 25,
                                    }}
                                />
                            }
                            onClick={handleClosed}
                        />
                    )}
                </div>
            </div>
        </Affix>
    );
}
