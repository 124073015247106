import React from 'react';
import { Divider, Layout, Typography, List, Button } from 'antd';
import {
    DollarCircleOutlined,
    CreditCardOutlined,
    RightOutlined,
} from '@ant-design/icons';

import HeaderTop from '../../../components/General/HeaderTop';

import './PaymentConfig.scss';

const PaymentConfig = ({ history }) => {
    const { Content } = Layout;
    const { Title } = Typography;

    const data = [
        {
            title: 'Efectivo',
            icon: (
                <DollarCircleOutlined
                    style={{ fontSize: '20px', color: '#08c', marginRight: 10 }}
                />
            ),
        },
        {
            title: 'Tarjeta',
            icon: (
                <CreditCardOutlined
                    style={{ fontSize: '20px', color: '#08c', marginRight: 10 }}
                />
            ),
        },
    ];

    const handleGoTo = (item) => {
        switch (item.title) {
            case 'Efectivo':
                history.push('/payment/cash');
                break;

            case 'Tarjeta':
                history.push('/payment/cards');
                break;

            default:
                break;
        }
    };

    return (
        <Layout className="payment-config">
            <Content className="payment-config_content">
                <HeaderTop
                    title={'Método de Pago'}
                    backIcon={true}
                    backUrl={'/'}
                />

                <div className="payment-config__container">
                    <Title style={{ marginTop: 20 }} level={4}>
                        Métodos de pago
                    </Title>

                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item) => (
                            <List.Item onClick={() => handleGoTo(item)}>
                                <List.Item.Meta
                                    title={
                                        <>
                                            {' '}
                                            {item.icon} {item.title}{' '}
                                        </>
                                    }
                                />
                                <div>
                                    <Button type="text">
                                        <RightOutlined />
                                    </Button>
                                </div>
                            </List.Item>
                        )}
                    />
                </div>

                <Divider style={{ marginTop: 0, marginBottom: 0 }}></Divider>
            </Content>
        </Layout>
    );
};

export default PaymentConfig;
