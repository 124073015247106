import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
    Layout,
    Row,
    Col,
    Button,
    Divider,
    Modal,
    notification,
    Alert,
} from 'antd';
import {
    CreditCardFilled,
    // EditOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import HeaderTop from '../../../components/General/HeaderTop';
import { getAccessTokenApi, logout } from '../../../api/auth';
import {
    getCompanyApi,
    getCompanyIdApi,
    getStripePaymentMethodsApi,
} from '../../../api/company';
import { deleteStripeCardApi } from '../../../api/stripe';
import { messageError, userLogOut } from '../../../utils/general';

import './PaymentCard.scss';
import { getCardsConektaApi } from '../../../api/conekta';

export default function PaymentCard({ history }) {
    const isMounted = useRef(true);
    const { cardId } = useParams();
    const [card, setCard] = useState(null);
    const [loading, setLoading] = useState(true);
    const [company, setCompany] = useState(null);
    const { Content } = Layout;
    const { confirm } = Modal;
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        if (company === null) {
            const idComany = getCompanyIdApi();
            getCompanyApi(token, idComany)
                .then((response) => {
                    if (response) {
                        if (response?.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }
                        if (response.error) {
                            return false;
                        }
                        setCompany({
                            UseStripe: response.useStripe,
                            UseConekta: response.useConekta,
                        });

                        if (response.useStripe) {
                            stripeMethods();
                        }

                        if (response.useConekta) {
                            conektaMethods();
                        }
                    }
                })
                .catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        // stripeMethods();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const stripeMethods = () => {
        getStripePaymentMethodsApi(token).then((data) => {
            if (isMounted.current) {
                if (data?.statusCode === 200) {
                    const allCards = data.result;

                    const cardSelect = allCards.filter(
                        (ele) => ele.id === cardId
                    );

                    setCard(cardSelect[0]);
                    setLoading(false);
                } else {
                    notification['error']({
                        message:
                            'Ocurrio un error al cargar la información, intentelo mas tarde.',
                    });
                }
            }
        });
    };

    const conektaMethods = () => {
        const companyId = getCompanyIdApi();
        if (companyId && companyId !== null) {
            getCardsConektaApi(token, companyId).then((data) => {
                if (isMounted.current) {
                    if (data?.statusCode === 200) {
                        const allCards = data.result;

                        const cardSelect = allCards.filter(
                            (ele) => ele.id === cardId
                        );

                        setCard(cardSelect[0]);
                        setLoading(false);
                    } else {
                        notification['error']({
                            message:
                                'Ocurrio un error al cargar la información, intentelo mas tarde.',
                        });
                    }
                }

                setLoading(false);
            });
        } else {
            notification['error']({
                message: 'Operación inválida.',
            });
        }
    };

    function showDeleteConfirm() {
        confirm({
            title: '¿Estás seguro de eliminar esta tarjeta?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteCard();
            },
            onCancel() {},
        });
    }

    const deleteCard = async () => {
        const result = await deleteStripeCardApi(token, card.id);

        if (result === undefined) {
            notification['error']({
                message: messageError(),
            });
        } else {
            if (result.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1200);

                return false;
            }

            if (result.statusCode === 200) {
                //go back!
                notification['success']({
                    message: 'Tarjeta eliminada exitosamente',
                });
                setTimeout(() => {
                    history.replace('/payment/cards');
                }, 1500);
            } else {
                let msg = 'Lo sentimos, ocurrió un error vuelva a intertarlo.';

                if (
                    result.statusCode === 400 ||
                    result.statusCode === 409 ||
                    result.statusCode === 404
                ) {
                    msg = result.description;
                }
                notification['error']({
                    message: msg,
                });
            }
        }
    };

    return (
        <Layout className='payment-card'>
            <Content className='payment-card__content'>
                <HeaderTop
                    backIcon={true}
                    route={'/payment/cards'}
                    title={'Tarjeta'}
                />
                <div className='payment-card__container'>
                    {!loading && card && (
                        <div className='card'>
                            <div className='card__front card__part'>
                                <CreditCardFilled className='card__icon ' />
                                <h1 className='card__front-logo card__logo'>
                                    Tarjeta Crédito / Débito
                                </h1>
                                <p className='card_numer'>
                                    **** **** ****{' '}
                                    {company.UseConekta
                                        ? card.last4
                                        : card.card.last4}
                                </p>

                                <div className='card__space-75'>
                                    <span className='card__label'>Títular</span>
                                    <p className='card__info'>
                                        {company.UseStripe &&
                                            card.billingDetails['name']}
                                        {company.UseConekta && card.name}
                                    </p>
                                </div>
                                <div className='card__space-25'>
                                    <span className='card__label'>
                                        Expiración
                                    </span>
                                    {company.UseStripe && (
                                        <p className='card__info'>
                                            {card.card.expMonth < 10
                                                ? `0${card.card.expMonth}`
                                                : card.card.expMonth}
                                            /
                                            {card.card.expYear
                                                .toString()
                                                .substr(2, 3)}
                                        </p>
                                    )}
                                    {company.UseConekta && (
                                        <p className='card__info'>
                                            {card.expMonth < 10
                                                ? `${card.expMonth}`
                                                : card.expMonth}
                                            /{card.expYear}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}

                    {!loading && card && (
                        <>
                            <Divider>Acciónes</Divider>

                            <Row gutter={[16, 16]}>
                                <Col span={24}>
                                    {/* <Button
                                        shape='round'
                                        block
                                        danger
                                        size='large'
                                        icon={
                                            <EditOutlined
                                                style={{
                                                    fontSize: 18,
                                                    color: '#ff7875',
                                                }}
                                            />
                                        }
                                        className='add-card__button'
                                    >
                                        Editar Tarjeta
                                    </Button> */}
                                </Col>

                                {company.UseStripe && (
                                    <Col span={24}>
                                        <Button
                                            type='primary'
                                            shape='round'
                                            block
                                            danger
                                            size='large'
                                            icon={
                                                <DeleteOutlined
                                                    style={{
                                                        fontSize: 18,
                                                        color: '#fff',
                                                    }}
                                                />
                                            }
                                            className='add-card__button'
                                            onClick={showDeleteConfirm}
                                            disabled={loading}
                                        >
                                            Eliminar Tarjeta
                                        </Button>
                                    </Col>
                                )}
                            </Row>
                        </>
                    )}

                    {!loading && !card && (
                        <Alert
                            message='Tarjeta no válida'
                            description='La tarjeta no esta guardada en el sistema.'
                            type='error'
                            showIcon
                        />
                    )}
                </div>
            </Content>
        </Layout>
    );
}
