import React, { useEffect, useState, useRef } from 'react';

import { Divider, Layout, Typography, Card, notification } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import { getCompanyFriendlyTips } from '../../../api/friendlyTips';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';

const News = () => {
    const { Content } = Layout;
    const { Title, Paragraph } = Typography;
    const isMounted = useRef(true);
    const [allNews, setAllNews] = useState([]);
    const companyId = getCompanyIdApi();

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
    useEffect(() => {
        const accessToken = getAccessTokenApi();
        getCompanyFriendlyTips(accessToken, companyId).then((response) => {
            if (isMounted.current) {
                if (response) {
                    if (response.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado',
                        });

                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1500);
                        return;
                    }
                    setAllNews(response.result);
                }
            }
        });
    }, [companyId]);

    return (
        <Layout className="collaborators">
            <HeaderTop title={'Noticias'} backUrl="/admin" />
            <Content className="collaborators__content">
                {allNews?.map((item, index) => {
                    return (
                        <div className="list-active-orders" key={item.id}>
                            <Card className="card">
                                <Title level={3}>{item.title}</Title>
                                <Divider />
                                <Paragraph className="summary__title">
                                    {' '}
                                    {item.message}
                                </Paragraph>
                            </Card>
                        </div>
                    );
                })}
                {allNews.length === 0 && (
                    <div className="list-active-orders">
                        <Card className="card">
                            <Title level={3}>No hay noticias por mostrar</Title>
                        </Card>
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default News;
