import React from 'react';
import { Row, Col, Typography } from 'antd';
import {
    UserOutlined,
    PhoneOutlined,
    EnvironmentOutlined,
} from '@ant-design/icons';
import { formatPhoneNumber } from '../../../utils/general';
import './ClientInfo.scss';

export default function ClientInfo({
    clientName,
    clientPhoneNumber,
    clientAddress,
    orderNum,
}) {
    const { Text, Title } = Typography;
    return (
        <div className="client-info">
            <div className="client-info__top">
                <Title level={5} type="secondary">
                    Orden No. {orderNum}
                </Title>
            </div>
            <div className="client-info__header">
                <div className="client-info__header-circle">
                    <UserOutlined className="icon" />
                </div>
            </div>

            <div className="client-info__container">
                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Title level={3}>
                            {' '}
                            <UserOutlined /> Nombre
                        </Title>
                    </Col>
                    <Col span={24} style={{ marginBottom: '15px' }}>
                        <Text className="name">{clientName}</Text>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        <Title level={4}>
                            <PhoneOutlined /> Teléfono
                        </Title>
                    </Col>
                    <Col span={24} style={{ marginBottom: '15px' }}>
                        <Text>{formatPhoneNumber(clientPhoneNumber)}</Text>
                    </Col>
                </Row>

                {clientAddress !== 'Sucursal' && (
                    <Row>
                        <Col span={24}>
                            <Title level={4}>
                                <EnvironmentOutlined />
                                Dirección
                            </Title>
                        </Col>
                        <Col span={24} style={{ marginBottom: '15px' }}>
                            <Text>{clientAddress}</Text>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
}
