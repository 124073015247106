import React, { useEffect, useState, useRef } from 'react';
import {
    Table,
    Button,
    Tag,
    Row,
    Col,
    Tooltip,
    Modal as ModalAntd,
    notification,
} from 'antd';
import {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    FilterOutlined,
    CheckOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Modal from '../../../General/Modal';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import {
    getCompanyIdApi,
    getCompanySuscriptionApi,
} from '../../../../api/company';
import { getAllAdvices, deleteAdvice } from '../../../../api/advice';
import AddAdviceForm from '../AddAdviceForm/AddAdviceForm';
import EditAdviceForm from '../EditAdviceForm/EditAdviceForm';
import { suscriptionType } from '../../../../utils/general';
import './TableAdvices.scss';

const TableAdvices = () => {
    const isMounted = useRef(true);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [reloadAdvices, setReloadAdvices] = useState(true);
    const [dataMenu, setDataMenu] = useState([]);
    const { confirm } = ModalAntd;

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const token = getAccessTokenApi();
        const companyId = getCompanyIdApi();
        getCompanySuscriptionApi(token, companyId)
            .then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        const result = response.result;
                        const type = suscriptionType(result.suscriptionType);
                        if (type === 'Basic') {
                            window.location.href = '/admin';
                        }
                    }
                }
            })
            .catch((err) => {});
    }, []);

    useEffect(() => {
        if (reloadAdvices) {
            const accessToken = getAccessTokenApi();
            const now = moment();
            getAllAdvices(accessToken).then((response) => {
                if (isMounted.current) {
                    if (response) {
                        if (response.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }

                        let dataCompleteMenu = [];
                        if(response.result !== null){
                            response.result.forEach((element) => {
                                element['key'] = element.id;
                                let status = '';
    
                                if (element.durationType === 'range-dates') {
                                    if (
                                        moment(now).isBefore(
                                            moment.unix(element.endDate)
                                        ) &&
                                        moment(now).isSameOrAfter(
                                            moment.unix(element.startDate)
                                        )
                                    ) {
                                        status = 'Vigente';
                                    } else {
                                        if (
                                            moment(now).isBefore(
                                                moment.unix(element.endDate)
                                            ) &&
                                            moment(now).isSameOrBefore(
                                                moment.unix(element.startDate)
                                            )
                                        ) {
                                            status = 'Pausa';
                                        } else {
                                            status = 'Vencida';
                                        }
                                    }
                                } else {
                                    const daysAdviceArray =
                                        element.applicableDays.split(',');
                                    let nowDay = moment().days();
                                    if (nowDay === 0) {
                                        //asign sunday value 7
                                        nowDay = 7;
                                    }
                                    //search rest of the days
                                    const found = daysAdviceArray.find(
                                        (element) => parseInt(element) === nowDay
                                    );
    
                                    if (found) {
                                        // status = 'Vigente';
                                        if (
                                            now.hour() >=
                                                moment
                                                    .unix(
                                                        element.timeStart,
                                                        'h:mma'
                                                    )
                                                    .hour() &&
                                            now.hour() <=
                                                moment
                                                    .unix(element.timeEnd, 'h:mma')
                                                    .hour()
                                        ) {
                                            status = 'Vigente';
                                        } else {
                                            status = 'Pausa';
                                        }
                                    } else {
                                        status = 'Pausa';
                                    }
                                }
                                element['status'] = status;
    
                                element['typeAdvice'] = getTypeAdvice(
                                    element.noticeType
                                );
                                element['periodType'] = getPeriodAdvice(element);
                                dataCompleteMenu.push(element);
                            });
                        }
                        setDataMenu(dataCompleteMenu);
                        setReloadAdvices(false);
                    }
                }
            });
        }
    }, [reloadAdvices]);

    const getTypeAdvice = (type) => {
        let typeText = '';

        switch (type) {
            case 1:
                typeText = 'Administrativo';
                break;
            case 2:
                typeText = 'Fecha Especial';
                break;
            case 3:
                typeText = 'Clima';
                break;
            default:
                return '';
        }

        return typeText;
    };

    const getPeriodAdvice = (advice) => {
        let periodType = '';
        if (advice.durationType === 'range-dates') {
            periodType = `${moment
                .unix(advice.startDate)
                .format('DD/MM/YYYY, h:mm a')} - ${moment
                .unix(advice.endDate)
                .format('DD/MM/YYYY, h:mm a')}`;
        } else {
            const daysAdviceArray = advice.applicableDays.split(',');

            let daysString = '';
            daysAdviceArray.forEach((ele) => {
                switch (ele) {
                    case '1':
                        daysString = `${daysString} Lunes,`;
                        break;
                    case '2':
                        daysString = `${daysString} Martes, `;
                        break;
                    case '3':
                        daysString = `${daysString} Miércoles, `;
                        break;
                    case '4':
                        daysString = `${daysString} Jueves, `;
                        break;
                    case '5':
                        daysString = `${daysString} Viernes, `;
                        break;
                    case '6':
                        daysString = `${daysString} Sábado, `;
                        break;
                    case '7':
                        daysString = `${daysString} Domingo, `;
                        break;
                    default:
                        return '';
                }
            });

            periodType = `${daysString} de ${moment
                .unix(advice.timeStart)
                .format('h:mm a')} - ${moment
                .unix(advice.timeEnd)
                .format('h:mm a')}`;
        }
        return periodType;
    };

    const columns = [
        {
            title: 'Nombre de aviso',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.length - b.name.length,
            sortOrder: sortedInfo.columnKey === 'name' && sortedInfo.order,
            ellipsis: false,
        },
        {
            title: 'Tipo',
            dataIndex: 'typeAdvice',
            key: 'typeAdvice',
            filters: [
                { text: 'Administrativo', value: 'Administrativo' },
                { text: 'Fecha Especial', value: 'Fecha Especial' },
                {
                    text: 'Clima',
                    value: 'Clima',
                },
            ],
            filteredValue: filteredInfo.typeAdvice || null,
            onFilter: (value, record) => record.typeAdvice === value,
            sorter: (a, b) => a.typeAdvice.length - b.typeAdvice.length,
            sortOrder:
                sortedInfo.columnKey === 'typeAdvice' && sortedInfo.order,
            ellipsis: false,
        },
        {
            title: 'Estatus',
            dataIndex: 'status',
            key: 'status',
            filters: [
                { text: 'Vigente', value: 'Vigente' },
                { text: 'Vencida', value: 'Vencida' },
                { text: 'Pausa', value: 'Pausa' },
            ],
            filteredValue: filteredInfo.status || null,
            onFilter: (value, record) => record.status.includes(value),
            sorter: (a, b) => a.status.length - b.status.length,
            sortOrder: sortedInfo.columnKey === 'status' && sortedInfo.order,
            ellipsis: false,
            align: 'center',
            render: (statusText) => (
                <Tag
                    color={
                        statusText === 'Vencida'
                            ? 'red'
                            : statusText === 'Pausa'
                            ? 'orange'
                            : 'green'
                    }
                >
                    {statusText.toUpperCase()}
                </Tag>
            ),
        },
        {
            title: 'Activo',
            key: 'isActive',
            dataIndex: 'isActive',
            filters: [
                { text: 'Activa', value: true },
                { text: 'Desactivada', value: false },
            ],
            filteredValue: filteredInfo.isActive || null,
            onFilter: (value, record) => record.isActive === value,
            sorter: (a, b) => a.isActive - b.isActive,
            sortOrder: sortedInfo.columnKey === 'isActive' && sortedInfo.order,
            ellipsis: false,
            align: 'center',
            render: (statusText) => (
                <Tag color={statusText === false ? 'red' : 'green'}>
                    {statusText ? <CheckOutlined /> : <CloseOutlined />}
                </Tag>
            ),
        },
        {
            title: 'Periodo',
            key: 'periodType',
            dataIndex: 'periodType',
        },
        {
            title: 'Acciones',
            key: 'key',
            fixed: 'right',
            width: 100,
            render: (key) => (
                <Tooltip title='Editar Aviso'>
                    <Button
                        type='primary'
                        onClick={() => handleEditAdvice(key)}
                        icon={<EditOutlined />}
                    >
                        Editar
                    </Button>
                </Tooltip>
            ),
        },
    ];

    const handleAddAdvice = () => {
        setModalTitle('Añadir nuevo aviso');
        setModalContent(
            <AddAdviceForm
                setIsModalVisible={setIsModalVisible}
                setReloadAdvices={setReloadAdvices}
            />
        );
        setIsModalVisible(true);
    };

    const handleEditAdvice = (ele) => {
        setModalTitle('Editar aviso');
        setModalContent(
            <EditAdviceForm
                setIsModalVisible={setIsModalVisible}
                setReloadAdvices={setReloadAdvices}
                idAdvice={ele.id}
            />
        );
        setIsModalVisible(true);
    };

    const handleDeleteAdvice = () => {
        confirm({
            title: 'Eliminar avisos',
            icon: <ExclamationCircleOutlined />,
            content:
                '¿Estás seguro que deseas borrar los avisos seleccionados?, no podrá recuperarla después',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteAdvicesApi();
            },
            onCancel() {},
        });
    };

    const deleteAdvicesApi = async () => {
        const accessToken = getAccessTokenApi();
        const dataDelete = {
            NoticesIdList: selectedRowKeys,
        };
        const result = await deleteAdvice(accessToken, dataDelete);
        if (result?.statusCode === 409) {
            notification['error']({
                message: result.description,
            });
        }
        notification['success']({
            message: 'Se elimino exitosamente.',
        });
        setSelectedRowKeys([]);
        setReloadAdvices(true);
    };

    const handleChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='table-advice'>
            <Row>
                <Col xs={24}>
                    <p
                        style={{
                            textAlign: 'right',
                            textTransform: 'capitalize',
                        }}
                    >
                        {moment().format('dddd LL')}
                    </p>
                </Col>
            </Row>
            <Row className='table-advice__top'>
                <Col md={24} style={{ textAlign: 'right' }}>
                    <Button
                        danger
                        icon={<FilterOutlined />}
                        onClick={clearAll}
                        style={{ marginRight: 10 }}
                    >
                        Limpiar Filtros
                    </Button>

                    <Button
                        onClick={handleAddAdvice}
                        type='primary'
                        icon={<PlusOutlined />}
                    >
                        Agregar aviso
                    </Button>
                </Col>
            </Row>
            <div style={{ marginBottom: 16 }}>
                <span style={{ marginLeft: 8 }}>
                    {hasSelected
                        ? `Seleccionados ${selectedRowKeys.length} elemento(s)`
                        : ''}
                </span>
            </div>

            <Table
                bordered
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataMenu}
                onChange={handleChange}
                loading={reloadAdvices}
                scroll={{ x: 950 }}
            />

            <div style={{ marginBottom: 16 }}>
                <Button
                    type='primary'
                    danger
                    icon={<DeleteOutlined />}
                    size='large'
                    disabled={!hasSelected}
                    onClick={handleDeleteAdvice}
                >
                    {`Eliminar ${selectedRowKeys.length} ${
                        selectedRowKeys.length > 1 ? 'elementos' : 'elemento'
                    }`}
                </Button>
            </div>

            <Modal
                destroyOnClose
                title={modalTitle}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                footer={true}
            >
                {modalContent}
            </Modal>
        </div>
    );
};

export default TableAdvices;
