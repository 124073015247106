import React, { useEffect, useState } from 'react';
import { List, Typography, Row, Col, Divider, Skeleton } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import {
    formatMoney,
    getPriceSubscription,
} from '../../../../../utils/general';

import './Summary.scss';

export default function Summary({ companySubscriptionInfo, loading }) {
    const { Title, Paragraph, Text } = Typography;
    const [suscriptionType, setSuscriptionType] = useState(0);

    useEffect(() => {
        if (companySubscriptionInfo) {
            setSuscriptionType(companySubscriptionInfo.suscriptionType);
        }
    }, [companySubscriptionInfo]);

    const basic = [
        { title: 'Perfil de Usuario y Administrador' },
        { title: 'Arma tu Platillo' },
        { title: 'Notificaciones' },
        { title: 'Indicadores' },
        { title: 'Diferentes Pedidos' },
        { title: 'Canasta de Compra' },
        { title: 'Método de Pago' },
        { title: 'Estatus de Seguimiento de Orden' },
    ];

    const plus = [
        { title: 'Descuentos Automatizados' },
        { title: 'Perfil para Runner' },
        { title: 'Banners Promocionales' },
        { title: 'Código de Confirmación de Pedido' },
        { title: 'Seguimiento GPS de Pedido' },
        { title: '+ Paquete Básico' },
    ];

    const premium = [
        { title: 'App Propia' },
        { title: 'Landing Page' },
        { title: 'Integración a su PAC de Facturación' },
        { title: 'Personalización de Funcionalidades y Módulos' },
        { title: 'Programa de Lealtad' },
        { title: 'Perfil de Colaboradores' },
        { title: 'Pedido a la Mesa' },
        { title: '+ Paquete Plus' },
    ];

    return (
        <div className='summary'>
            <Row>
                {loading && (
                    <Col span={24}>
                        <div className=''>
                            <Skeleton.Input
                                style={{ width: 200, marginBottom: 20 }}
                                active={true}
                                size={'large'}
                            />
                        </div>

                        <div className=''>
                            <Skeleton.Input
                                style={{ width: 350 }}
                                active={true}
                                size={'large'}
                            />

                            <Skeleton active paragraph={{ rows: 5 }} />
                        </div>
                        <Divider>
                            <Paragraph className='summary__title'>
                                ¿Qué incluye el paquete ?
                            </Paragraph>
                        </Divider>

                        <Skeleton active paragraph={{ rows: 4 }} />
                    </Col>
                )}
                {!loading && companySubscriptionInfo !== null && (
                    <Col span={24}>
                        <Title level={3}>Detalle de la Suscripción</Title>

                        <Paragraph className='summary__title'>
                            Plan:{' '}
                            {suscriptionType === 1
                                ? 'Básico'
                                : suscriptionType === 2
                                ? 'Plus'
                                : suscriptionType === 3
                                ? 'Premium'
                                : '-'}{' '}
                            <Text className='text-important'>(Mensual)</Text>
                            {'      '}
                            <Text strong>
                                {formatMoney(
                                    getPriceSubscription(suscriptionType)
                                )}
                            </Text>
                        </Paragraph>

                        {/* <Paragraph type='secondary'>
                            Lorem Ipsum es simplemente el texto de relleno de
                            las imprentas y archivos de texto. Lorem Ipsum ha
                            sido el texto de relleno estándar de las industrias
                            desde el año 1500, cuando un impresor (N. del T.
                            persona que se dedica a la imprenta) desconocido usó
                            una galería de textos y los mezcló de tal manera que
                            logró hacer un libro de textos especimen. No sólo
                            sobrevivió 500 años, sino que tambien ingresó como
                            texto de relleno en documentos electrónicos,
                            quedando esencialmente igual al original. Fue
                            popularizado en los 60s con la creación de las hojas
                            "Letraset", las cuales contenian pasajes de Lorem
                            Ipsum, y más recientemente con software de
                            autoedición, como por ejemplo Aldus PageMaker, el
                            cual incluye versiones de Lorem Ipsum.
                        </Paragraph> */}

                        <Divider>
                            <Paragraph className='summary__title'>
                                ¿Qué incluye el paquete{' '}
                                <Text className='text-important'>
                                    {suscriptionType === 1
                                        ? 'Básico'
                                        : suscriptionType === 2
                                        ? 'Plus'
                                        : suscriptionType === 3
                                        ? 'Premium'
                                        : '-'}{' '}
                                </Text>
                                ?
                            </Paragraph>
                        </Divider>

                        <List
                            itemLayout='horizontal'
                            dataSource={
                                suscriptionType === 1
                                    ? basic
                                    : suscriptionType === 2
                                    ? plus
                                    : suscriptionType === 3
                                    ? premium
                                    : []
                            }
                            renderItem={(item) => (
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={
                                            <CheckCircleOutlined className='summary__icon' />
                                        }
                                        title={<p>{item.title}</p>}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                )}
            </Row>
        </div>
    );
}
