import React from 'react';
import { Layout } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import ListActiveOrders from '../../../components/Runner/ListActiveOrders/ListActiveOrders';

import './ActiveOrdersList.scss';

export default function ActiveOrdersList() {
    const { Content } = Layout;

    return (
        <Layout className="active-orders-list">
            <Content className="active-orders-list">
                <HeaderTop title={'Ordenes Activas'} />
                <div className="active-orders-list__container">
                    <ListActiveOrders />
                </div>
            </Content>
        </Layout>
    );
}
