import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Divider, Layout, Skeleton, notification } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import ListUsers from '../../../components/Admin/Collaborators/ListUsers/ListUsers';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getUsersList } from '../../../api/user';
import { getCompanyIdApi } from '../../../api/company';
import useAuth from '../../../hooks/useAuth';
import AdminHome from '../../../pages/Admin/Perfil';
import { userLogOut } from '../../../utils/general';

import './Collaborators.scss';

const Collaborators = () => {
    const { user } = useAuth();
    const { Content } = Layout;
    const [usersActive, setUsersActive] = useState([]);
    const [usersInactive, setUsersInactive] = useState([]);
    const [reloadUsers, setreloadUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const token = getAccessTokenApi();

    const [roleUser, setRoleUser] = useState('');

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        setRoleUser(roleId);
    }, [user]);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        if (roleUser === 'Admin') {
            const companyId = getCompanyIdApi();
            setLoadingUsers(true);

            //active
            getUsersList(token, companyId, true).then((response) => {
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                } else {
                    if (response) setUsersActive(response);
                    setLoadingUsers(false);
                }
            });

            //inactive
            getUsersList(token, companyId, false).then((response) => {
                if (response.statusCode !== 401) {
                    if (response) setUsersInactive(response);
                    setLoadingUsers(false);
                }
            });
            setreloadUsers(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadUsers, roleUser]);

    if (roleUser === 'Operator') {
        return (
            <>
                <Route path="/admin" component={AdminHome} />
                <Redirect to="/admin" />
            </>
        );
    }

    return (
        <Layout className="collaborators">
            <HeaderTop
                title={'Colaboradores'}
                // backIcon={true}
                backUrl="/admin"
            />
            {roleUser === 'Admin' && (
                <Content className="collaborators__content">
                    {!loadingUsers && (
                        <ListUsers
                            usersActive={usersActive}
                            usersInactive={usersInactive}
                            setreloadUsers={setreloadUsers}
                        />
                    )}
                    {loadingUsers && (
                        <div>
                            <div style={{ textAlign: 'right' }}>
                                <Skeleton.Button active size="large" />
                            </div>
                            <Divider />
                            <Skeleton active paragraph={{ rows: 2 }} />
                            <Divider />
                            <Skeleton active paragraph={{ rows: 2 }} />
                            <Divider />
                            <Skeleton active paragraph={{ rows: 2 }} />
                        </div>
                    )}
                </Content>
            )}
        </Layout>
    );
};

export default Collaborators;
