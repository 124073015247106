import React from 'react';
import { Divider, Layout } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import ChangePasswordForm from '../../../components/General/ChangePasswordForm';

import './ChangePassword.scss';

const ChangePassword = () => {
    const { Content } = Layout;

    return (
        <Layout className="change-password">
            <Content className="change-password__content">
                <HeaderTop backIcon={true} route={'/perfil'} title={'Contraseña'} />
                <Divider>Cambiar tu contraseña</Divider>
                <ChangePasswordForm />
            </Content>
        </Layout>
    );
};

export default ChangePassword;
