import React, { useEffect, useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { DatePicker, Typography, Select, Row, Col, notification } from 'antd';
import moment from 'moment';
import { formatMoney, userLogOut } from '../../../utils/general';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi, getCompaniesApi } from '../../../api/company';
import { getGraphicOrderReportApi } from '../../../api/order';

const SellChart = ({ allRest }) => {
    const now = moment();
    const [ordersData, setOrdersData] = useState([]);
    const [dateSearch, setDateSearch] = useState(now);
    const [periodSearch, setPeriodsearch] = useState(1);
    const [restaurants, setRestaurants] = useState([]);
    const [companyId, setCompanyId] = useState(getCompanyIdApi());
    const { Title, Paragraph } = Typography;
    const { Option } = Select;
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        // const companyId = getCompanyIdApi();
        const data = {
            CompanyId: companyId,
            Semester: periodSearch,
            Year: parseInt(moment(dateSearch).format('YYYY')),
        };
        getGraphicOrderReportApi(token, data)
            .then((response) => {
                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                } else {
                    if (response) {
                        let newData = response.map((ele) => {
                            ele['Ventas'] = ele.sales;
                            ele['Canceladas'] = ele.cancelled;
                            return ele;
                        });
                        setOrdersData(newData);
                    }
                }
            })
            .catch((err) => {});
    }, [companyId, dateSearch, periodSearch, token]);

    useEffect(() => {
        if (allRest) {
            const filters = `isTestingCompany=${false}&active=${true}`;
            getCompaniesApi(token, filters)
                .then((response) => {
                    setRestaurants(response);
                })
                .catch((err) => {});
        }
    }, [token, allRest]);

    function onChangeMonth(date, dateString) {
        setDateSearch(date);
    }

    function handleChangePeriod(value) {
        setPeriodsearch(value);
    }

    function handleChangeRest(value) {
        setCompanyId(value);
    }

    return (
        <div>
            <Row gutter={16} className='first-section-report'>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title level={3}>
                        Búsqueda de{' '}
                        {periodSearch === 1
                            ? 'Enero - Junio'
                            : 'Julio - Diciembre'}
                    </Title>
                </Col>
                <Col
                    xs={24}
                    md={12}
                    lg={12}
                    className='first-section-report__left'
                >
                    <Select
                        defaultValue={1}
                        style={{ width: 180 }}
                        onChange={handleChangePeriod}
                        size='large'
                    >
                        <Option value={1}>Enero - Junio</Option>
                        <Option value={2}>Julio - Diciembre</Option>
                    </Select>
                    <Paragraph style={{ marginTop: 10 }}>
                        Año de búsqueda: {moment(dateSearch).format('YYYY')}{' '}
                    </Paragraph>
                </Col>
                <Col
                    xs={24}
                    md={12}
                    lg={12}
                    className='first-section-report__right'
                >
                    <DatePicker
                        style={{ width: '160px' }}
                        placeholder='Seleccionar año'
                        picker='year'
                        onChange={onChangeMonth}
                        size='large'
                    />
                    {allRest && (
                        <Select
                            defaultValue={companyId}
                            style={{ width: 'auto', marginLeft: 20 }}
                            onChange={handleChangeRest}
                            size='large'
                            placeholder='Selec. Restaurante'
                        >
                            {restaurants.map((rest) => {
                                return (
                                    <Option
                                        key={rest.companyId}
                                        value={rest.companyId}
                                    >
                                        {rest.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    )}
                </Col>
            </Row>

            <ResponsiveContainer width='100%' height={450}>
                <LineChart
                    data={ordersData}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis
                        dataKey='month'
                        padding={{ left: 30, right: 30 }}
                        tick={<CustomizedAxisTick />}
                    />
                    <YAxis
                        padding={{ top: 30 }}
                        tickFormatter={(value) => {
                            return formatMoney(value);
                        }}
                    />

                    {/* <YAxis yAxisId="left" padding={{ top: 30 }} tickFormatter={(value)=>{return formatMoney(value)}} label={{ value: "Ventas", angle: -90, position: 'insideLeft', offset: -5}} /> */}
                    {/* <YAxis yAxisId="right" orientation="right" padding={{ top: 30 }} tickFormatter={(value)=>{return formatMoney(value)}} label={{ value: "Canceladas", angle: 90, position: 'insideRight', offset: -20 }} />   */}

                    <Tooltip
                        formatter={(value, name, props) => {
                            return '$ ' + formatMoney(value);
                        }}
                    />
                    <Legend />
                    <Line
                        type='monotone'
                        dataKey='Ventas'
                        stroke='#82ca9d'
                        activeDot={{ r: 8 }}
                        strokeWidth={1.5}
                        label={<CustomizedLabel />}
                    />
                    <Line
                        type='monotone'
                        dataKey='Canceladas'
                        stroke='#d9363e'
                        strokeWidth={1}
                    />

                    {/* <Line yAxisId="left" type="monotone" dataKey="sales" stroke="#82ca9d" activeDot={{ r: 8 }} strokeWidth={1.5} label={<CustomizedLabel />}/>
				  <Line yAxisId="right" type="monotone" dataKey="cancelled" stroke="#d9363e" strokeWidth={1} /> */}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default SellChart;

function CustomizedAxisTick(props) {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text
                x={0}
                y={0}
                dy={16}
                textAnchor='end'
                fill='#666'
                transform='rotate(-35)'
            >
                {payload.value}
            </text>
        </g>
    );
}

function CustomizedLabel(props) {
    const { x, y, stroke, value } = props;

    return (
        <text
            x={x}
            y={y}
            dy={-4}
            fill={stroke}
            fontSize={12}
            textAnchor='middle'
        >
            {formatMoney(value)}
        </text>
    );
}
