import React, { useEffect, useState } from 'react';
import {
    Button,
    Row,
    Col,
    Space,
    Popconfirm,
    Empty,
    notification,
    Divider,
    Modal,
    Spin,
    Alert,
} from 'antd';
import {
    DownloadOutlined,
    CloseOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../../api/auth';
import {
    downloadDocumentsRunnerApi,
    getDocumentsRunnerAdminApi,
    editAdminRunner,
    deleteDocumentsRunner,
} from '../../../../../api/runner';
import moment from 'moment';

import './DocumentModal.scss';

export default function DocumentModal(props) {
    const {
        runner,
        runners,
        setRunners,
        setIsVisibleModal,
        denegarAutorizacion,
    } = props;
    const token = getAccessTokenApi();
    const [documents, setDocuments] = useState(null);
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState({
        visibleTCF: false,
        visibleTCP: false,
        visibleLMF: false,
        visibleLMP: false,
    });
    const [confirmLoading, setConfirmLoading] = useState(false);
    const { confirm } = Modal;

    useEffect(() => {
        setLoading(true);
        getDocumentsRunnerAdminApi(token, runner.id).then((response) => {
            if (response?.statusCode === 200) {
                const documentsArray = response?.result;
                setDocuments(documentsArray);
            } else {
                if (response?.statusCode === 404) {
                    notification['warning']({
                        message: `Documentación pendiente de: ${runner.name} ${runner.lastName}.`,
                    });
                } else {
                    let msg =
                        'Ocurrio un problema, Intente mas tarde por favor.';

                    if (
                        response?.statusCode === 409 ||
                        response?.statusCode === 400
                    ) {
                        msg = response?.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            }
            setLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const downloadHandler = (id, name) => {
        setLoading(true);
        downloadDocumentsRunnerApi(token, id).then((response) => {
            setLoading(false);

            const outside = URL.createObjectURL(response);
            const link = document.createElement('a');
            link.target = '_blank';
            link.download = name;
            link.href = outside;
            link.click();

            //SOLO ABRE
            // window.open(outside);
        });
    };

    function showConfirm() {
        confirm({
            title: `¿Estás seguro de autorizar al runner: ${runner.name} ${runner.lastName} ?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Si',
            onOk() {
                editRunner(true);
            },
            onCancel() {},
        });
    }

    function completeDocHandler() {
        if (
            !documents.circulationCardBackId ||
            !documents.circulationCardBackName ||
            !documents.circulationCardFrontId ||
            !documents.circulationCardFrontName ||
            !documents.driversLicenseBackId ||
            !documents.driversLicenseBackName ||
            !documents.driversLicenseFrontId ||
            !documents.driversLicenseFrontName
        ) {
            return false;
        }
        return true;
    }

    const editRunner = async (status) => {
        const documentComplete = completeDocHandler();
        if (!documentComplete) {
            notification['error']({
                message: `Documentación incompleta.`,
                description: `No puede autorizar a ${runner.name} ${runner.lastName} porque no tiene completo todos los documentos.`,
            });
        } else {
            setLoading(true);
            const data = {
                id: runner.id,
                authorized: status,
                active: runner.active,
            };
            editAdminRunner(token, data).then((response) => {
                if (response?.statusCode === 200) {
                    const messageSuccess = `Se ha ${
                        response.result.authorized
                            ? 'autorizado'
                            : 'desautorizado'
                    } al runner con éxito.`;
                    notification['success']({
                        message: messageSuccess,
                    });
                    setLoading(false);
                    setIsVisibleModal(false);
                    window.location.reload();
                } else {
                    let msg =
                        'Ocurrio un problema, Intente mas tarde por favor.';

                    if (
                        response?.statusCode === 409 ||
                        response?.statusCode === 400 ||
                        response?.statusCode === 404
                    ) {
                        msg = response?.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                    setLoading(false);
                }
            });
        }
    };

    const showPopconfirm = (typeDoc) => {
        setVisible((prevValue) => ({
            ...prevValue,
            [typeDoc]: true,
        }));
        // setVisible({...visible, [typeDoc]: true});
    };

    const handleCancel = () => {
        setVisible({
            visibleTCF: false,
            visibleTCP: false,
            visibleLMF: false,
            visibleLMP: false,
        });
    };

    function confirmDelete(idDoc, nameDoc) {
        setConfirmLoading(true);
        setLoading(true);
        deleteDocumentsRunner(token, runner.id, idDoc).then((response) => {
            if (response?.statusCode === 200) {
                changeInfoRunner(false);

                let nameFound = '';

                switch (nameDoc) {
                    case 'Tarjeta de circulación (Frontal)':
                        nameFound = 'circulationCardFrontId';
                        break;

                    case 'Tarjeta de circulación (Posterior)':
                        nameFound = 'circulationCardBackId';
                        break;
                    case 'Licencia de manejo (Frontal)':
                        nameFound = 'driversLicenseFrontId';
                        break;
                    case 'Licencia de manejo (Posterior)':
                        nameFound = 'driversLicenseBackId';
                        break;

                    default:
                        break;
                }
                setDocuments({ ...documents, [nameFound]: null });
                setVisible({
                    visibleTCF: false,
                    visibleTCP: false,
                    visibleLMF: false,
                    visibleLMP: false,
                });
                setConfirmLoading(false);
                setLoading(false);

                notification['success']({
                    message: 'Operación exitosa',
                    description: `Se ha rechazado con exito el documento: ${nameDoc}`,
                });
            } else {
                if (response?.statusCode === 404) {
                    notification['warning']({
                        message: `Documentación pendiente de: ${runner.name} ${runner.lastName}.`,
                    });
                } else {
                    let msg =
                        'Ocurrio un problema, Intente mas tarde por favor.';

                    if (
                        response?.statusCode === 409 ||
                        response?.statusCode === 400
                    ) {
                        msg = response?.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            }
        });
    }

    const changeInfoRunner = (status) => {
        const runnerJSON = {
            name: runner.name,
            lastName: runner.lastName,
            email: runner.email,
            phoneNumber: runner.phoneNumber,
            birthDate: moment.unix(runner.birthDate),
            vehicle: {
                type: runner.vehicle.type,
                plate: runner.plate,
                id: runner.vehicle.id,
            },
            id: runner.id,
            isOnline: runner.isOnline,
            active: runner.active,
            authorized: status,
        };

        const onEdited = runners.indexOf(runner);

        if (onEdited !== -1) runners[onEdited] = runnerJSON;

        setRunners(runners);
    };

    return (
        <div className='document-modal'>
            <Spin spinning={loading} tip='Cargando'>
                {documents === null && (
                    <div className='document-modal__no-files'>
                        <Row>
                            <Col span={24}>
                                <Empty description={false} />
                                <p className='no-files-text'>
                                    Aún no hay documentos
                                </p>
                            </Col>
                        </Row>
                    </div>
                )}
                {documents !== null && (
                    <div>
                        <Divider style={{ marginTop: 0 }}>
                            Tarjeta de circulación{' '}
                        </Divider>

                        {documents.circulationCardExpirationDate !== 0 &&
                            documents.circulationCardExpirationDate !==
                                null && (
                                <p>
                                    Fecha de expiración de la tarjeta de
                                    circulación :{' '}
                                    {moment
                                        .unix(
                                            documents.circulationCardExpirationDate
                                        )
                                        .format('DD/MM/YYYY')}
                                </p>
                            )}

                        <Row
                            gutter={[15, 15]}
                            className='document-modal__container'
                        >
                            {documents.circulationCardFrontId !== null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Button
                                            icon={<DownloadOutlined />}
                                            type='primary'
                                            ghost
                                            onClick={() =>
                                                downloadHandler(
                                                    documents.circulationCardFrontId,
                                                    `Tarjeta_circulaciónF-${runner.name}_${runner.lastName}`
                                                )
                                            }
                                        >
                                            Tarjeta de circulación (Frontal)
                                        </Button>
                                    </Col>
                                    <Col xs={2}>
                                        <Space>
                                            {/* <Popconfirm
                                                placement="top"
                                                title={
                                                    '¿Está seguro que desea aceptar este documento?'
                                                }
                                                onConfirm={confirm}
                                                okText="Si, aceptar"
                                                cancelText="No, cancelar"
                                            >
                                                <Button
                                                    icon={<CheckOutlined />}
                                                    className="approve-button"
                                                />
                                            </Popconfirm> */}
                                            <Popconfirm
                                                placement='top'
                                                title={
                                                    '¿Está seguro que desea rechazar este documento?'
                                                }
                                                onConfirm={() =>
                                                    confirmDelete(
                                                        documents.circulationCardFrontId,
                                                        'Tarjeta de circulación (Frontal)'
                                                    )
                                                }
                                                okText='Si, rechazar'
                                                cancelText='No, cancelar'
                                                visible={visible.visibleTCF}
                                                onCancel={handleCancel}
                                            >
                                                <Button
                                                    icon={<CloseOutlined />}
                                                    type='primary'
                                                    danger
                                                    onClick={() =>
                                                        showPopconfirm(
                                                            'visibleTCF'
                                                        )
                                                    }
                                                />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            )}

                            {documents.circulationCardFrontId === null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Alert
                                            message='Pendiente: Tarjeta de circulación (Frontal)'
                                            banner
                                        />
                                    </Col>
                                </Row>
                            )}

                            {documents.circulationCardBackId !== null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Button
                                            icon={<DownloadOutlined />}
                                            type='primary'
                                            ghost
                                            onClick={() =>
                                                downloadHandler(
                                                    documents.circulationCardBackId,
                                                    `Tarjeta_circulaciónP-${runner.name}_${runner.lastName}`
                                                )
                                            }
                                        >
                                            Tarjeta de circulación (Posterior)
                                        </Button>
                                    </Col>
                                    <Col xs={2}>
                                        <Space>
                                            <Popconfirm
                                                placement='top'
                                                title={
                                                    '¿Está seguro que desea rechazar este documento?'
                                                }
                                                onConfirm={() =>
                                                    confirmDelete(
                                                        documents.circulationCardBackId,
                                                        'Tarjeta de circulación (Posterior)'
                                                    )
                                                }
                                                okText='Si, rechazar'
                                                cancelText='No, cancelar'
                                                visible={visible.visibleTCP}
                                                onCancel={handleCancel}
                                            >
                                                <Button
                                                    icon={<CloseOutlined />}
                                                    type='primary'
                                                    danger
                                                    onClick={() =>
                                                        showPopconfirm(
                                                            'visibleTCP'
                                                        )
                                                    }
                                                />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            )}

                            {documents.circulationCardBackId === null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Alert
                                            message='Pendiente: Tarjeta de circulación (Posterior)'
                                            banner
                                        />
                                    </Col>
                                </Row>
                            )}

                            <Divider style={{ marginTop: 0 }}>
                                Licencia de manejo
                            </Divider>

                            {documents.driversLicenseExpirationDate !== 0 &&
                                documents.driversLicenseExpirationDate !==
                                    null && (
                                    <p style={{ marginTop: 0 }}>
                                        Fecha de expiración licencia de manejo:{' '}
                                        {moment
                                            .unix(
                                                documents.driversLicenseExpirationDate
                                            )
                                            .format('DD/MM/YYYY')}
                                    </p>
                                )}

                            {documents.driversLicenseFrontId !== null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Button
                                            icon={<DownloadOutlined />}
                                            type='primary'
                                            ghost
                                            onClick={() =>
                                                downloadHandler(
                                                    documents.driversLicenseFrontId,
                                                    `Licencia-Frontal-${runner.name}_${runner.lastName}`
                                                )
                                            }
                                        >
                                            Licencia de manejo (Frontal)
                                        </Button>
                                    </Col>
                                    <Col xs={2}>
                                        <Space>
                                            <Popconfirm
                                                placement='top'
                                                title={
                                                    '¿Está seguro que desea rechazar este documento?'
                                                }
                                                onConfirm={() =>
                                                    confirmDelete(
                                                        documents.driversLicenseFrontId,
                                                        'Licencia de manejo (Frontal)'
                                                    )
                                                }
                                                okText='Si, rechazar'
                                                cancelText='No, cancelar'
                                                visible={visible.visibleLMF}
                                                onCancel={handleCancel}
                                            >
                                                <Button
                                                    icon={<CloseOutlined />}
                                                    type='primary'
                                                    danger
                                                    onClick={() =>
                                                        showPopconfirm(
                                                            'visibleLMF'
                                                        )
                                                    }
                                                />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            )}

                            {documents.driversLicenseFrontId === null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Alert
                                            message='Pendiente: Licencia de manejo (Frontal)'
                                            banner
                                        />
                                    </Col>
                                </Row>
                            )}

                            {documents.driversLicenseBackId !== null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Button
                                            icon={<DownloadOutlined />}
                                            type='primary'
                                            ghost
                                            onClick={() =>
                                                downloadHandler(
                                                    documents.driversLicenseBackId,
                                                    `Licencia-Posterior-${runner.name}_${runner.lastName}`
                                                )
                                            }
                                        >
                                            Licencia de manejo (Posterior)
                                        </Button>
                                    </Col>
                                    <Col xs={2}>
                                        <Space>
                                            <Popconfirm
                                                placement='top'
                                                title={
                                                    '¿Está seguro que desea rechazar este documento?'
                                                }
                                                onConfirm={() =>
                                                    confirmDelete(
                                                        documents.driversLicenseBackId,
                                                        'Licencia de manejo (Posterior)'
                                                    )
                                                }
                                                okText='Si, rechazar'
                                                cancelText='No, cancelar'
                                                visible={visible.visibleLMP}
                                                onCancel={handleCancel}
                                                okButtonProps={{
                                                    loading: confirmLoading,
                                                }}
                                            >
                                                <Button
                                                    icon={<CloseOutlined />}
                                                    type='primary'
                                                    danger
                                                    onClick={() =>
                                                        showPopconfirm(
                                                            'visibleLMP'
                                                        )
                                                    }
                                                />
                                            </Popconfirm>
                                        </Space>
                                    </Col>
                                </Row>
                            )}

                            {documents.driversLicenseBackId === null && (
                                <Row gutter={[0, 20]}>
                                    <Col xs={22}>
                                        <Alert
                                            message='Pendiente: Licencia de manejo (Posterior)'
                                            banner
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Row>
                    </div>
                )}

                {!runner.authorized && documents && (
                    <Button
                        style={{ marginBottom: 20 }}
                        type='primary'
                        onClick={showConfirm}
                        block
                    >
                        Autorizar
                    </Button>
                )}

                {documents && (
                    <Button
                        type='primary'
                        onClick={() => denegarAutorizacion(runner)}
                        // onClick={showDeleteConfirm}
                        danger
                        block
                        ghost
                    >
                        Razón de Rechazo
                    </Button>
                )}
            </Spin>
        </div>
    );
}
