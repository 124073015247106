import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Button } from 'antd';
import {
    LoginOutlined,
    KeyOutlined,
    SyncOutlined,
    FormOutlined,
    HomeOutlined,
    ReconciliationOutlined,
    FileDoneOutlined,
    BarChartOutlined,
    CustomerServiceOutlined,
} from '@ant-design/icons';
import { Image } from 'antd';
import { logout } from '../../../api/auth';
import userIcon from '../../../assets/img/png/userIcon.png';

import './MenuLeftSider.scss';

function MenuLeftSider(props) {
    const { location, name, setCollapse, companyImage } = props;

    const logoutUser = () => {
        logout();
        window.location.reload();
    };

    return (
        <>
            <div className="menu-sider">
                <Image
                    className="menu-sider__image"
                    src={companyImage ? companyImage : userIcon}
                />

                <h1>¡Bienvenido!</h1>

                <h1>{name}</h1>
            </div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                onClick={() => setCollapse(true)}
            >
                <Menu.Item key="/runner" icon={<HomeOutlined />}>
                    <Link to={'/runner'}>Perfil</Link>
                </Menu.Item>
                <Menu.Item
                    key="/runner/orders"
                    icon={<ReconciliationOutlined />}
                >
                    <Link to={'/runner/orders'}>Órdenes</Link>
                </Menu.Item>
                <Menu.Item
                    key="/runner/active/deliveries"
                    icon={<FileDoneOutlined />}
                >
                    <Link to={'/runner/active/deliveries'}>
                        Ordenes Activas
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/runner/orderHistory"
                    icon={<BarChartOutlined />}
                >
                    <Link to={'/runner/orderHistory'}>Historial</Link>
                </Menu.Item>
                <Menu.Item key="/runner/changePassword" icon={<KeyOutlined />}>
                    <Link to={'/runner/changePassword'}>
                        Cambiar Contraseña
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/runner/help"
                    icon={<CustomerServiceOutlined />}
                >
                    <Link to={'/runner/help'}>Ayuda</Link>
                </Menu.Item>
                <Menu.Item key="/runner/updates" icon={<SyncOutlined />}>
                    <Link to={'/runner/updates'}>Actualizaciones</Link>
                </Menu.Item>
                <Menu.Item key="/runner/policy" icon={<FormOutlined />}>
                    <Link to={'/runner/policy'}>Términos y Condiciones</Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<LoginOutlined />}>
                    <Button
                        type="text"
                        className="menu-side-btn"
                        onClick={logoutUser}
                    >
                        Cerrar Sesión
                    </Button>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default withRouter(MenuLeftSider);
