import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Layout, notification, Spin } from 'antd';
import Footer from '../../components/Client/Footer';
import HeaderTop from '../../components/General/HeaderTop';
import useUser from '../../hooks/useUser';
import TypeOrder from '../../components/Client/TypeOrder';
import PromoBanner from '../../components/Client/PromoBanner';
import { getCompanyApi } from '../../api/company';
import { getAccessTokenApi, logout } from '../../api/auth';
import { getAllOrdersFiltersApi } from '../../api/order';
import {
    getCompanyImageApi,
    getCompanySchedule,
    getCompanyNameApi,
    getCompanySuscriptionApi,
} from '../../api/company';
import useCompany from '../../hooks/useCompany';
import useAuth from '../../hooks/useAuth';
import { COMPANY_IMAGE, COMPANY_NAME, COMPANY_ID } from '../../utils/constants';
import { userLogOut, suscriptionType } from '../../utils/general';
import { askForNotificationPermission } from '../../utils/subscriptions';
import Logo from '../../assets/img/png/logoControlB.png';
import { getCompanyIdApi } from '../../api/company';
import { USER_PATH } from '../../utils/constants';
import useOrder from '../../../src/hooks/useOrder';

import './Client.scss';

export default function Client(props) {
    const isMounted = useRef(true);
    const { location, history } = props;
    const { companyId } = useParams();
    const { person, setPerson } = useUser();
    const companyIdSave = getCompanyIdApi();
    const { name, phoneNumber } = person;
    const { user } = useAuth();
    const { company, setCompany } = useCompany();
    const [loadingPage, setLoadingPage] = useState(true);
    const token = getAccessTokenApi();
    const companyImageApi = getCompanyImageApi();
    const companyName = getCompanyNameApi();
    const [scheduleCompany, setScheduleCompany] = useState(null);
    const [activeOrderID, setActiveOrderID] = useState(0);
    const [suscriptionCompany, setSuscriptionCompany] = useState('Basic');
    const { orderGeneral } = useOrder();
    const { Subtotal, orderLinesList, CompanyId } = orderGeneral;
    const clientId =
        user[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
        ];
    const roleId =
        user['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        askForNotificationPermission(clientId, roleId, companyId, token);
        localStorage.setItem(USER_PATH, 'restaurant');
        if (companyIdSave === '' || companyIdSave === null) {
            localStorage.setItem(COMPANY_ID, companyId);
        }
        setPerson({ ...person, companyId: companyId });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const token = getAccessTokenApi();
        if (companyId) {
            getCompanySuscriptionApi(token, companyId)
                .then((response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            const result = response.result;
                            setSuscriptionCompany(
                                suscriptionType(result.suscriptionType)
                            );
                        }
                    }
                })
                .catch((err) => {});
        }
    }, [companyId]);

    useEffect(() => {
        let activeOrderApi = false;

        if (companyId !== null) {
            const filters = `?CompanyId=${companyId}&ClientId=${clientId}&Status=1&Status=2&Status=3&Status=4&Status=5`;
            getAllOrdersFiltersApi(token, filters).then((res) => {
                if (isMounted.current) {
                    if (res === undefined) {
                        setLoadingPage(false);
                        return false;
                    }

                    if (res.statusCode === 200) {
                        const resultOrders = res.result;
                        if (resultOrders.length > 0) {
                            //tienes ordenes activas
                            setActiveOrderID(resultOrders.length);
                            activeOrderApi = true;
                        }
                        // if (res.orderId && res.companyId === companyId) {
                        //     setActiveOrderID(res.orderId);
                        //     activeOrderApi = true;
                        // }
                    }
                }
                getCompanyApi(token, companyId).then((response) => {
                    // console.log(response);
                    if (response === undefined) {
                        notification['error']({
                            message:
                                'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                        });
                        return;
                    }
                    if (response?.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado',
                        });

                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1500);
                        return;
                    }
                    if (response.error) {
                        return false;
                    }

                    if (companyName !== response.name) {
                        localStorage.setItem(COMPANY_NAME, response.name);
                    }

                    if (response.companyImage !== null) {
                        const extensionImage = response.companyImage.extension;
                        const imageProfile = `data:image/${extensionImage};base64, ${response.companyImage.imageData}`;
                        localStorage.setItem(COMPANY_IMAGE, imageProfile);
                    }

                    if (!response.active) {
                        notification['error']({
                            message: 'El comercio no esta disponible.',
                        });
                        setTimeout(() => {
                            history.push('/disable/restaurant');
                        }, 1000);
                    } else {
                        setCompany({
                            ...company,
                            Location: response.address,
                            State: response.state,
                            Neighborhood: response.neighborhood,
                            ZipCode: response.zipCode,
                            City: response.city,
                            Telephone: response.phone,
                            CompanyImage: response.companyImage,
                            DeliveryCostKM: response.deliveryCostKM,
                            DeliveryCost: response.deliveryCost,
                            DeliveryExtraKmCost: response.deliveryExtraKmCost,
                            DeliverryMaxDistance: response.deliverryMaxDistance,
                            ServiceMaxDistance: response.serviceMaxDistance,
                            AllowMultipleActiveOrders:
                                response.allowMultipleActiveOrders,
                            Name: response.name,
                            PickUp: response.pickUp,
                            ToTable: response.toTable,
                            Delivery: response.delivery,
                            Latitude: response.latitude,
                            Longitude: response.longitude,
                            CreditCard: response.creditCard,
                            CashOnly: response.cashOnly,
                            OrderMinimumPurchase: response.orderMinimumPurchase,
                            ActiveOrder: activeOrderApi,
                            UseStripe: response.useStripe,
                            UseConekta: response.useConekta,
                            ConektaPublicKey: response.conektaPublicKey,
                        });

                        setLoadingPage(false);
                    }
                });

                getCompanySchedule(token, companyId).then((resp) => {
                    if (resp) {
                        if (resp.statusCode === 401) {
                            logout();
                            return;
                        }
                        setScheduleCompany({
                            Schedule: resp,
                        });
                    }
                });
            });
        }
        // }
        return () => {
            // setInfoCompany(null);
            setLoadingPage(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Layout className="client">
                {loadingPage && <Spin size="large" tip="Cargando..." />}
                {!loadingPage && (
                    <>
                        <HeaderTop
                            textCapitalize
                            logoCompany={company === null ? false : true}
                            title={company === null ? '' : company.Name}
                            imageCompany={
                                companyImageApi === null
                                    ? Logo
                                    : companyImageApi
                            }
                        />

                        <TypeOrder
                            infoCompany={company}
                            scheduleCompany={scheduleCompany}
                            history={history}
                            location={location}
                            nameClient={name}
                            phoneNumberClient={phoneNumber}
                            user={user}
                        />

                        {suscriptionCompany !== 'Basic' && <PromoBanner />}
                    </>
                )}
            </Layout>
            {orderGeneral.orderLinesList.length > 0 ? (
                <Footer
                    showOrder
                    activeOrderID={activeOrderID}
                    backIconFunction={true}
                />
            ) : (
                <Footer showOrder activeOrderID={activeOrderID} showHome />
            )}

            {/* <Footer
                showOrder
                activeOrderID={activeOrderID}
                showHome
                backIconFunction={true}
            /> */}
        </>
    );
}
