import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Menu, Button } from 'antd';
import {
    LoginOutlined,
    KeyOutlined,
    SyncOutlined,
    FormOutlined,
    HomeOutlined,
    UsergroupAddOutlined,
    BellOutlined,
    BarChartOutlined,
    DollarCircleOutlined,
    SecurityScanOutlined,
} from '@ant-design/icons';
import { Image } from 'antd';
import { logout } from '../../../api/auth';
import userIcon from '../../../assets/img/png/userIcon.png';

import './MenuLeftSider.scss';

function MenuLeftSider(props) {
    const { location, name, setCollapse, companyImage } = props;

    const logoutUser = () => {
        logout();
        window.location.reload();
    };

    return (
        <>
            <div className="menu-sider">
                <Image
                    className="menu-sider__image"
                    src={companyImage ? companyImage : userIcon}
                />

                <h1 className="margin-bottom-label">¡Bienvenido!</h1>

                <h1 className="margin-bottom-label">{name}</h1>
            </div>
            <Menu
                theme="dark"
                mode="inline"
                defaultSelectedKeys={[location.pathname]}
                onClick={() => setCollapse(true)}
            >
                <Menu.Item key="/administrative" icon={<HomeOutlined />}>
                    <Link to={'/administrative'}>Inicio</Link>
                </Menu.Item>
                <Menu.Item
                    key="/administrative/users"
                    icon={<UsergroupAddOutlined />}
                >
                    <Link to={'/administrative/users'}>Usuarios</Link>
                </Menu.Item>
                <Menu.Item
                    key="/administrative/reports"
                    icon={<BarChartOutlined />}
                >
                    <Link to={'/administrative/reports'}>Reportes</Link>
                </Menu.Item>
                <Menu.Item
                    key="/administrative/payments"
                    icon={<DollarCircleOutlined />}
                >
                    <Link to={'/administrative/payments'}>Pagos</Link>
                </Menu.Item>
                <Menu.Item
                    key="/administrative/accessCode"
                    icon={<SecurityScanOutlined />}
                >
                    <Link to={'/administrative/accessCode'}>Códigos</Link>
                </Menu.Item>
                <Menu.Item
                    key="/administrative/friendlyTips"
                    icon={<BellOutlined />}
                >
                    <Link to={'/administrative/friendlyTips'}>
                        Friendly Tips
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/administrative/changePassword"
                    icon={<KeyOutlined />}
                >
                    <Link to={'/administrative/changePassword'}>
                        Cambiar Contraseña
                    </Link>
                </Menu.Item>
                <Menu.Item
                    key="/administrative/updates"
                    icon={<SyncOutlined />}
                >
                    <Link to={'/administrative/updates'}>Actualizaciones</Link>
                </Menu.Item>
                <Menu.Item key="/administrative/policy" icon={<FormOutlined />}>
                    <Link to={'/administrative/policy'}>
                        Términos y Condiciones
                    </Link>
                </Menu.Item>
                <Menu.Item key="4" icon={<LoginOutlined />}>
                    <Button
                        type="text"
                        className="menu-side-btn"
                        onClick={logoutUser}
                    >
                        Cerrar Sesión
                    </Button>
                </Menu.Item>
            </Menu>
        </>
    );
}

export default withRouter(MenuLeftSider);
