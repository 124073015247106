import React, { useState, useEffect } from 'react';
import { Layout, notification } from 'antd';
import MenuTop from '../../../components/Admin/MenuTop';
import Modal from '../../../components/General/Modal';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getRunnerGroups } from '../../../api/runner';
import { userLogOut } from '../../../utils/general';
import './Runners.scss';
import RunnersGroupForm from '../../../components/Admin/Runners/RunnersGroupForm/RunnersGroupForm';
import RunnerGroupList from '../../../components/Admin/Runners/RunnerGroupList/RunnerGroupList';

export default function Runners() {
    const [runnerGroupsList, setRunnerGroupsList] = useState();
    const [reloadGroups, setReloadGroups] = useState(false);
    let [loading, setloading] = useState(true);
    const [isVisible, setIsVisible] = useState(false);
    const [showPickUpGroup, setShowPickUpGroup] = useState(false);
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getRunnerGroups(token).then((response) => {
            if (response?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1500);
                return;
            } else {
                setRunnerGroupsList(response.result);
                setloading(false);
            }
        });
        return () => {
            setRunnerGroupsList([]);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, reloadGroups]);


    const openModal = () => {
        setIsVisible(true);
    };

    return (
        <Layout className="menu-admin">
            <MenuTop
                title={'Runners'}
                icon="add"
                action
                onAction={() => openModal()}
            />
            <RunnerGroupList
                runnerGroupsList={runnerGroupsList}
                loading={loading}
                setReloadGroups={setReloadGroups}
                showPickUpGroup={showPickUpGroup}
            />
            <Modal
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                title="Agregar un Grupo"
                footer={false}
            >
                <RunnersGroupForm
                    setIsVisible={setIsVisible}
                    runnerGroupsList={runnerGroupsList}
                    setRunnerGroupsList={setRunnerGroupsList}
                />
            </Modal>
        </Layout>
    );
}

