import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import {Layout} from 'antd';
import ResetPasswordForm from '../../components/General/ResetPasswordForm';
import HeaderTop from '../../components/General/HeaderTop';

import './ResetPassword.scss';

const ResetPassword = (props) => {
    const { Content } = Layout;
    const [user, setUser] = useState(null)

    useEffect(() => {
        let params = queryString.parse(props.location.search);
        setUser(params)
        return () => {
            
        }
    }, [props.location.search])

    return (
        <Layout className="reset-password">
            <Content className="reset-password__content">
                <HeaderTop 
                    title={"Cambiar Contraseña"} 
                    backIcon={true} 
                    backUrl={"/login"}
                />

                <ResetPasswordForm user={user}/>
            </Content>
        </Layout>
    )
}

export default withRouter(ResetPassword);
