import React, { useState } from 'react';
import {
    Form,
    Input,
    InputNumber,
    Button,
    Select,
    Checkbox,
    Tooltip,
    Spin,
    notification,
    Typography,
    Row,
    Col,
    Divider,
} from 'antd';
import {
    QuestionCircleOutlined,
    CheckCircleFilled,
    CloseCircleFilled,
} from '@ant-design/icons';
import {
    validatePassword,
    validateTextHasCapitalize,
    validateTextHasLowercase,
    validateTextHasNumber,
    validateTextHasCharacter,
} from '../../../utils/formValidation';
import { signUpApi } from '../../../api/user';
import fileTerminos from '../../../assets/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf';
import { getMobileOperatingSystem } from '../../../utils/subscriptions';

import './RegisterForm.scss';

export default function RegisterForm(props) {
    const { setShowRegisterForm, setEmail, companyId } = props;
    const { Option } = Select;
    const { Text } = Typography;

    const [loading, setLoading] = useState(false);
    const [hasUpperCase, setHasUpperCase] = useState(false);
    const [hasLowerCase, setHasLowerCase] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasCharacter, setHasCharacter] = useState(false);
    const [lenghtCorrect, setLenghtCorrect] = useState(false);

    const formItemLayout = {
        labelCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 8,
            },
            lg: {
                span: 6,
            },
        },
        wrapperCol: {
            xs: {
                span: 24,
            },
            sm: {
                span: 16,
            },
            lg: {
                span: 14,
            },
        },
    };

    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 8,
            },
            lg: {
                span: 8,
                offset: 8,
            },
        },
    };

    const [form] = Form.useForm();

    const onSend = (email) => {
        setEmail(email);
        setShowRegisterForm(false);
    };

    const onFinish = async (values) => {
        setLoading(true);

        let formData = values;

        formData['phoneNumber'] = formData['phoneNumber'].toString();
        formData['address'] = null;
        formData['address2'] = null;
        formData['city'] = null;
        formData['neighborhood'] = null;
        formData['phoneExtensionNumber'] = null;
        formData['vehicule'] = null;
        formData['registrationNumber'] = null;
        formData['companyId'] = companyId;

        const result = await signUpApi(formData);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });

            return false;
        }

        if (result.statusCode !== 200) {
            if (result.statusCode === 409) {
                notification['error']({
                    message: result.description,
                });

                setLoading(false);

                return true;
            }

            notification['error']({
                message: 'Ocurrio un problema, Intente mas tarde por favor.',
            });
            setLoading(false);

            return true;
        } else {
            //recreo correctamenta
            form.resetFields();
            notification['success']({
                message: 'Usuario creado exitosamente',
            });

            onSend(values.email);
            // setTimeout(() => {
            //     setLoading(false);
            //     window.location.href = '/login';
            // }, 2000);
        }
    };

    const checkPassword = (text) => {
        if (validateTextHasCapitalize(text)) {
            setHasUpperCase(true);
        } else {
            setHasUpperCase(false);
        }

        if (validateTextHasLowercase(text)) {
            setHasLowerCase(true);
        } else {
            setHasLowerCase(false);
        }

        if (validateTextHasNumber(text)) {
            setHasNumber(true);
        } else {
            setHasNumber(false);
        }

        if (validateTextHasCharacter(text)) {
            setHasCharacter(true);
        } else {
            setHasCharacter(false);
        }

        if (text.length >= 6) {
            setLenghtCorrect(true);
        } else {
            setLenghtCorrect(false);
        }
    };

    return (
        <Spin tip='Creando cuenta...' spinning={loading}>
            <Form
                {...formItemLayout}
                className='register-form'
                form={form}
                name='register'
                onFinish={onFinish}
                scrollToFirstError
                initialValues={{
                    UserType: 3,
                }}
            >
                <Form.Item
                    name='Name'
                    label='Nombre del usuario'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='LastName'
                    label='Apellido del usuario'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un apellido',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='email'
                    label='E-mail'
                    hasFeedback
                    rules={[
                        {
                            type: 'email',
                            message: 'El correo electrónico no es válido',
                        },
                        {
                            required: true,
                            message: 'Por favor ingrese un correo electrónico',
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name='password'
                    label='Contraseña'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una contraseña',
                        },
                        () => ({
                            validator(rule, value) {
                                if (validatePassword(value)) {
                                    return Promise.resolve();
                                } else if (!validatePassword(value)) {
                                    return Promise.reject(
                                        'La contrase\u00F1a nueva debe coincidir con los puntos establecidos abajo'
                                        // 'La contrase\u00F1a nueva debe de tener  mínimo 6 o más caracteres, por lo menos una may\u00FAscula, una minúscula, un número  y un car\u00E1cter especial'
                                    );
                                }
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        onChange={(e) => checkPassword(e.target.value)}
                    />
                </Form.Item>

                <div className='validate-password'>
                    <Row justify='center'>
                        <Col xs={24} sm={8} lg={8}>
                            <Divider>La contraseña debe incluir:</Divider>
                            <ul>
                                <li>
                                    {hasUpperCase && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una mayúscula{' '}
                                        </Text>
                                    )}
                                    {!hasUpperCase && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una mayúscula{' '}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {hasLowerCase && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una minúscula{' '}
                                        </Text>
                                    )}
                                    {!hasLowerCase && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Una minúscula{' '}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {hasNumber && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un número{' '}
                                        </Text>
                                    )}
                                    {!hasNumber && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un número{' '}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {hasCharacter && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un carácter:{' '}
                                            {'?()!@#$%^&*[]"\';:_-<>. =+/ '}{' '}
                                        </Text>
                                    )}
                                    {!hasCharacter && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Un carácter:{' '}
                                            {'?()!@#$%^&*[]"\';:_-<>. =+/'}
                                        </Text>
                                    )}
                                </li>
                                <li>
                                    {lenghtCorrect && (
                                        <Text type='success'>
                                            <CheckCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Tiene 6 o más caracteres{' '}
                                        </Text>
                                    )}
                                    {!lenghtCorrect && (
                                        <Text type='danger'>
                                            <CloseCircleFilled
                                                style={{ marginRight: 8 }}
                                            />
                                            Tiene 6 o más caracteres{' '}
                                        </Text>
                                    )}
                                </li>
                            </ul>
                        </Col>
                        {/* <Col span={8} offset={8}>
                            col-8
                        </Col> */}
                    </Row>
                </div>

                <Form.Item
                    name='confirmpassword'
                    label='Confirmar Contraseña'
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese la contraseña anterior',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (
                                    !value ||
                                    getFieldValue('password') === value
                                ) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Las dos contraseñas ingresadas no son iguales'
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item
                    name='UserType'
                    label='Tipo de Usuario'
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor selecciona una opción',
                        },
                    ]}
                >
                    <Select placeholder='Por favor selecciona una opción'>
                        <Option value={3}>Cliente</Option>
                        {/* <Option value="runner">Repartidor</Option> */}
                    </Select>
                </Form.Item>

                <Form.Item
                    name='phoneNumber'
                    label={
                        <span>
                            Teléfono&nbsp;
                            <Tooltip title='Tú número telefónico debe contener 10 dígitos'>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </span>
                    }
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingresa tú número telefónico',
                        },
                        ({ getFieldValue }) => ({
                            validator(rule, value) {
                                if (!value || value.toString().length === 10) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    'Por favor ingresa tú número telefónico a 10 dígitos'
                                );
                            },
                        }),
                    ]}
                >
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name='agreement'
                    valuePropName='checked'
                    className='register-form__container agreement'
                    rules={[
                        {
                            validator: (_, value) =>
                                value
                                    ? Promise.resolve()
                                    : Promise.reject(
                                          'Debe aceptar los Términos y Condiciones'
                                      ),
                        },
                    ]}
                    {...tailFormItemLayout}
                >
                    <Checkbox>
                        He leído y aceptado los
                        {getMobileOperatingSystem() !== 'iOS' && (
                            <a
                                href={fileTerminos}
                                target='_blank'
                                rel='noreferrer'
                            >
                                {' '}
                                Términos y Condiciones
                            </a>
                        )}
                        {getMobileOperatingSystem() === 'iOS' && (
                            <a
                                href='http://controlb.controlbsys.com/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf'
                                target='_blank'
                                rel='noreferrer'
                            >
                                {' '}
                                Términos y Condiciones
                            </a>
                        )}
                    </Checkbox>
                </Form.Item>
                <Form.Item
                    {...tailFormItemLayout}
                    className='register-form__container'
                >
                    <Button
                        type='primary'
                        htmlType='submit'
                        block={true}
                        size='large'
                        danger
                        shape='round'
                        className='register-form'
                    >
                        Registrar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
}
