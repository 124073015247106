import React from 'react';
import { Divider, Layout, Typography } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import fileTerminos from '../../../assets/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf';
import fileAvisoPrivacidad from '../../../assets/pdf/AVISO-DE-PRIVACIDAD-DE-CONTROL-B.pdf';
import { getMobileOperatingSystem } from '../../../utils/subscriptions';

import './Policy.scss';

const Policy = ({ history }) => {
    const { Content } = Layout;
    const { Title } = Typography;

    // const downloadFile = (fileUrl, nameFile) =>{
    //     let a = document.createElement('a');
    //     a.href = fileUrl;
    //     a.download = nameFile;
    //     document.body.appendChild(a);
    //     a.click();
    //     document.body.removeChild(a);
    // }

    return (
        <Layout className='policy'>
            <Content className='policy_content'>
                <HeaderTop
                    title={'Términos y Condiciones'}
                    backIconAuto={true}
                    history={history}
                />

                <div className='policy__container'>
                    <Title level={4}>
                        TÉRMINOS DE USO DEL PRODUCTO Y DEL SITIO WEB CONTROL B
                    </Title>

                    {getMobileOperatingSystem() !== 'iOS' && (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a href={fileTerminos} target='_blank'>
                            {' '}
                            TÉRMINOS DE USO DEL PRODUCTO Y DEL SITIO WEB CONTROL
                            B.pdf
                        </a>
                    )}

                    {getMobileOperatingSystem() === 'iOS' && (
                        <a
                            href={
                                'http://controlb.controlbsys.com/pdf/TERMINOS-DE-USO-DEL-PRODUCTO-Y-DEL-SITIO-WEB-CONTROL-B.pdf'
                            }
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target='_blank'
                        >
                            TÉRMINOS DE USO DEL PRODUCTO Y DEL SITIO WEB CONTROL
                            B.pdf
                        </a>
                    )}
                </div>

                <Divider></Divider>

                <div className='policy__container'>
                    <Title level={4}>AVISO DE PRIVACIDAD DE CONTROL B</Title>

                    {getMobileOperatingSystem() !== 'iOS' && (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a href={fileAvisoPrivacidad} target='_blank'>
                            {' '}
                            AVISO DE PRIVACIDAD DE CONTROL B.pdf
                        </a>
                    )}

                    {getMobileOperatingSystem() === 'iOS' && (
                        // eslint-disable-next-line react/jsx-no-target-blank
                        <a
                            href={
                                'http://controlb.controlbsys.com/pdf/AVISO-DE-PRIVACIDAD-DE-CONTROL-B.pdf'
                            }
                            target='_blank'
                        >
                            AVISO DE PRIVACIDAD DE CONTROL B.pdf
                        </a>
                    )}
                </div>
            </Content>
        </Layout>
    );
};

export default Policy;
