import React from 'react';
import { Layout, Typography } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import RecordsOrders from '../../../components/Client/RecordsOrders';

import './OrderHistory.scss';

const OrderHistory = ({ history }) => {
    const { Content } = Layout;
    const { Title } = Typography;

    return (
        <Layout className="order-history">
            <Content>
                <HeaderTop
                    title={'Mis Órdenes'}
                    backIconAuto={true}
                    history={history}
                />

                <Title
                    level={4}
                    className="text-center"
                    style={{ marginTop: 20 }}
                >
                    Todas las órdenes
                </Title>

                <RecordsOrders />
            </Content>
        </Layout>
    );
};

export default OrderHistory;
