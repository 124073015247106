import React, { useState, useEffect } from 'react';
import {
    List,
    Tooltip,
    Tag,
    Button,
    Typography,
    Modal as ModalAntd,
    notification,
    Input,
    Form,
    Spin,
} from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    CheckOutlined,
    CloseOutlined,
    SnippetsOutlined,
    MailOutlined,
} from '@ant-design/icons';
import Modal from '../../../General/Modal';
import EditRunnerForm from '../EditRunnerForm';
import {
    deleteRunner,
    refuseAuthorizationRunner,
} from '../../../../api/runner';
import { getAccessTokenApi } from '../../../../api/auth';
import useAuth from '../../../../hooks/useAuth';
import { userLogOut } from '../../../../utils/general';
import DocumentModal from './DocumentModal';

import './RunnersList.scss';

const { confirm } = ModalAntd;

const RunnersList = (props) => {
    const { runners, setGroupAdmin, groupAdmin, setRunners } = props;
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalContent, setmodalContent] = useState(null);
    const { user } = useAuth();
    const idCurrentUser =
        user[
            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
        ];
    let [updateRunners, setUpdateRunners] = useState(false);

    useEffect(() => {
        setRunners(runners);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateRunners]);

    return (
        <div>
            <div className='list-users'>
                <UsersActive
                    runners={runners}
                    setIsVisibleModal={setIsVisibleModal}
                    setModalTitle={setModalTitle}
                    setmodalContent={setmodalContent}
                    setGroupAdmin={setGroupAdmin}
                    idCurrentUser={idCurrentUser}
                    groupAdmin={groupAdmin}
                    setRunners={setRunners}
                    setUpdateRunners={setUpdateRunners}
                />

                <Modal
                    destroyOnClose
                    title={modalTitle}
                    isVisible={isVisibleModal}
                    setIsVisible={setIsVisibleModal}
                    footer={false}
                >
                    {modalContent}
                </Modal>
            </div>
        </div>
    );
};

function UsersActive(props) {
    const { Title } = Typography;
    const {
        runners,
        setIsVisibleModal,
        setModalTitle,
        setmodalContent,
        setGroupAdmin,
        idCurrentUser,
        groupAdmin,
        setRunners,
        setUpdateRunners,
    } = props;
    const [loadingModal, setLoadingModal] = useState(false);
    const token = getAccessTokenApi();

    const editUser = (runner) => {
        setIsVisibleModal(true);

        setModalTitle(
            `Editar ${runner.name ? runner.name : '...'}  ${
                runner.lastName ? runner.lastName : '...'
            }`
        );
        setmodalContent(
            <EditRunnerForm
                runner={runner}
                setIsVisibleModal={setIsVisibleModal}
                setGroupAdmin={setGroupAdmin}
                groupAdmin={groupAdmin}
                runners={runners}
            />
        );
    };
    const sendReason = async (values) => {
        const data = {
            reason: values.reason,
        };
        let id = values.idRunner;

        setLoadingModal(true);
        if (data !== undefined && id !== undefined) {
            refuseAuthorizationRunner(token, data, id).then((response) => {
                if (response.statusCode === 200) {
                    notification['success']({
                        message: response.description,
                    });
                    setLoadingModal(false);

                    setIsVisibleModal(false);
                    window.location.reload();
                } else {
                    setLoadingModal(false);

                    notification['error']({
                        message: 'Ocurrió un error, intentelo más tarde',
                    });
                }
            });
        }
    };
    const denegarAutorizacion = (runner) => {
        setIsVisibleModal(true);
        setModalTitle(
            `Denegar autorización al runner ${
                runner.name ? runner.name : '...'
            }  ${runner.lastName ? runner.lastName : '...'}`
        );
        setmodalContent(
            <div
                className='list-active-orders__container-button'
                style={{ textAlign: 'center' }}
            >
                <Spin spinning={loadingModal} tip='Cargando'>
                    <Form onFinish={sendReason}>
                        <h3>Razón para denegar la autorización</h3>
                        <p>
                            Esta será enviada a {runner.name} {runner.lastName}{' '}
                            para que actualice los documentos.
                        </p>
                        <Form.Item
                            name='reason'
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese la razón',
                                },
                            ]}
                        >
                            <Input.TextArea
                                placeholder='Razón'
                                rows={6}
                                showCount
                                maxLength={500}
                            />
                        </Form.Item>
                        <Form.Item
                            name='idRunner'
                            hasFeedback
                            initialValue={runner.id}
                        >
                            <p></p>
                        </Form.Item>
                        <Form.Item>
                            <Button
                                block
                                type='primary'
                                htmlType='submit'
                                onClick={() => sendReason(runner)}
                                danger
                            >
                                Enviar
                            </Button>
                        </Form.Item>
                        {/* <Button
                            type="primary"
                            ghost
                            block
                            onClick={() => showDocuments(runner)}
                            style={{ marginBottom: 20 }}
                        >
                            Atras
                        </Button> */}

                        <Button
                            type='ghost'
                            danger
                            block
                            onClick={() => setIsVisibleModal(false)}
                        >
                            Cancelar
                        </Button>
                    </Form>
                </Spin>
            </div>
        );
    };

    const showDocuments = (runner) => {
        setIsVisibleModal(true);
        setModalTitle(
            `Documentos ${runner.name ? runner.name : '...'}  ${
                runner.lastName ? runner.lastName : '...'
            }`
        );
        setmodalContent(
            <DocumentModal
                runner={runner}
                setIsVisibleModal={setIsVisibleModal}
                denegarAutorizacion={denegarAutorizacion}
                setRunners={setRunners}
                runners={runners}
            />
        );
    };

    return (
        <>
            <Title level={3}>Lista de Runners</Title>
            <List
                className='users-active'
                itemLayout='horizontal'
                dataSource={runners}
                pagination={{
                    onChange: (page) => {},
                    pageSize: 6,
                }}
                renderItem={(runner) => (
                    <UserActive
                        runner={runner}
                        editUser={editUser}
                        setGroupAdmin={setGroupAdmin}
                        idCurrentUser={idCurrentUser}
                        runners={runners}
                        setRunners={setRunners}
                        setUpdateRunners={setUpdateRunners}
                        showDocuments={showDocuments}
                    />
                )}
            />
        </>
    );
}

function UserActive(props) {
    const {
        runner,
        editUser,
        idCurrentUser,
        runners,
        setRunners,
        setUpdateRunners,
        showDocuments,
    } = props;
    const token = getAccessTokenApi();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const showDeleteConfirm = () => {
        // const token = getAccessTokenApi();

        confirm({
            title: 'Eliminado runner',
            content: `¿Estás seguro que quieres eliminar a ${runner.name} ${runner.lastName}?`,
            okText: 'Eliminar',
            okType: 'danger',
            cancelText: 'Cancelar',
            onOk() {
                deleteRunner(token, runner.id)
                    .then((response) => {
                        if (response.statusCode === 200) {
                            notification['success']({
                                message: 'Runner eliminado con exito',
                            });
                            const found = runners.findIndex(
                                (obj) => obj.id === runner.id
                            );
                            runners.splice(found, 1);
                            setRunners(true);
                            setUpdateRunners(true);
                        } else {
                            let msg = 'Ocurrió un error, intentelo mas tarde';

                            if (
                                response.statusCode === 409 ||
                                response.statusCode === 400 ||
                                response.statusCode === 404
                            ) {
                                msg = response.description;
                            }
                            notification['error']({
                                message: msg,
                            });
                        }
                    })
                    .catch((err) => {
                        notification['error']({
                            message: err,
                        });
                    });
            },
        });
    };

    return (
        <List.Item
            actions={[
                <Tooltip placement='top' title='Ver documentos'>
                    <Button
                        className='button-documents'
                        type='primary'
                        onClick={() => showDocuments(runner)}
                        icon={<SnippetsOutlined />}
                        disabled={runner.id === idCurrentUser ? true : false}
                    />
                </Tooltip>,
                <Tooltip placement='top' title='Editar Runner'>
                    <Button
                        type='primary'
                        onClick={() => editUser(runner)}
                        icon={<EditOutlined />}
                    />
                </Tooltip>,
                <Tooltip placement='top' title='Eliminar Runner'>
                    <Button
                        danger
                        type='primary'
                        onClick={showDeleteConfirm}
                        icon={<DeleteOutlined />}
                        disabled={runner.id === idCurrentUser ? true : false}
                    />
                </Tooltip>,
            ]}
        >
            {runner.isOnline && (
                <Tooltip placement='top' title={'Runner Conectado'}>
                    <Button
                        className='button-Online'
                        type='primary'
                        shape='circle'
                        size='small'
                        icon={<CheckOutlined />}
                        disabled={runner.id === idCurrentUser ? true : false}
                    />
                </Tooltip>
            )}
            {!runner.isOnline && (
                <Tooltip placement='top' title={'Runner Desconectado'}>
                    <Button
                        className='button-Offline'
                        danger
                        type='primary'
                        shape='circle'
                        size='small'
                        block={true}
                        icon={<CloseOutlined />}
                        disabled={runner.id === idCurrentUser ? true : false}
                    />
                </Tooltip>
            )}

            <List.Item.Meta
                // avatar={<Avatar src={avatar ? avatar : NoAvatar} />}

                title={`
                    ${runner.name ? runner.name : '...'}
                    ${runner.lastName ? runner.lastName : '...'}
                `}
                description={<DescripTionRunner runner={runner} />}
            />
        </List.Item>
    );
}

export default RunnersList;

function DescripTionRunner(props) {
    const { runner } = props;
    const { Paragraph } = Typography;
    return (
        <div>
            <Paragraph type='secondary'>
                <MailOutlined style={{ marginRight: 6 }} /> {runner.email}
            </Paragraph>
            <span>
                {runner.active ? (
                    <Tag color='green'>Activo</Tag>
                ) : (
                    <Tag color='default'>Inactivo</Tag>
                )}
                {runner.authorized ? (
                    <Tag color='blue'>Autorizado</Tag>
                ) : (
                    <Tag color='red'>No Autorizado</Tag>
                )}
            </span>
        </div>
    );
}
