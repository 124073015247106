export const citiesMTY = [
    'Apodaca',
    'Cadereyta Jiménez',
    'Cadereyta Jimenez',
    'El Carmen',
    'García',
    'Garcia',
    'San Pedro Garza García',
    'San Pedro Garza Garcia',
    'General Escobedo',
    'Guadalupe',
    'Juárez',
    'Juarez',
    'Monterrey',
    'Salinas Victoria',
    'San Nicolás de los Garza',
    'San Nicolas de los Garza',
    'Santa Catarina',
    'Santiago',
];
