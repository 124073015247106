import React, { useEffect, useState, useRef } from 'react';

import {
    Table,
    Button,
    Row,
    Col,
    Modal as ModalAntd,
    notification,
} from 'antd';
import {
    PlusOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import Modal from '../../General/Modal';
import { getAccessTokenApi, logout } from '../../../api/auth';
import {
    getAllFriendlyTips,
    deleteFriendlyTips,
} from '../../../api/friendlyTips';
import AddFriendlyTipForm from '../../SuperAdmin/AddFriendlyTipForm/AddFriendlyTipForm';
import './FriendlyTipsTable.scss';
const FriendlyTipsTable = () => {
    const isMounted = useRef(true);

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    // const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const [reloadAdvices, setReloadAdvices] = useState(true);
    const [dataMenu, setDataMenu] = useState([]);
    const { confirm } = ModalAntd;

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (reloadAdvices) {
            const accessToken = getAccessTokenApi();
            getAllFriendlyTips(accessToken).then((response) => {
                if (isMounted.current) {
                    if (response) {
                        if (response.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }

                        let dataCompleteMenu = [];
                        response.result.forEach((element) => {
                            element['key'] = element.id;
                            element['date'] = moment
                                .unix(element.creationDate)
                                .format('DD/MM/YYYY, h:mm a');
                            dataCompleteMenu.push(element);
                        });
                        setDataMenu(dataCompleteMenu);
                        setReloadAdvices(false);
                    }
                }
            });
        }
    }, [reloadAdvices]);

    const columns = [
        {
            title: 'Nombre del mensaje',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.length - b.title.length,
            sortOrder: sortedInfo.columnKey === 'title' && sortedInfo.order,
            ellipsis: false,
        },
        {
            title: 'Mensaje envíado',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Sucursales',
            key: 'companyNames',
            dataIndex: 'companyNames',
        },
        {
            title: 'Fecha',
            key: 'date',
            dataIndex: 'date',
        },
    ];

    const handleAddAdvice = () => {
        setModalTitle('Añadir nuevo Friendly TIP');
        setModalContent(
            <AddFriendlyTipForm
                setIsModalVisible={setIsModalVisible}
                setReloadAdvices={setReloadAdvices}
            />
        );
        setIsModalVisible(true);
    };

    const handleDeleteAdvice = () => {
        confirm({
            title: 'Eliminar Friendly TIP',
            icon: <ExclamationCircleOutlined />,
            content:
                '¿Estás seguro que deseas borrar los Friendly TIPS seleccionados?, no podrá recuperarla después',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteFriendlyTipsSelect();
            },
            onCancel() {},
        });
    };

    const deleteFriendlyTipsSelect = async () => {
        const accessToken = getAccessTokenApi();
        const dataDelete = {
            RemindersId: selectedRowKeys,
        };
        const result = await deleteFriendlyTips(accessToken, dataDelete);
        if (result?.statusCode === 409 || result?.statusCode === 400) {
            notification['error']({
                message: result.description,
            });
        }
        if (result?.statusCode === 200) {
            notification['success']({
                message: 'Se elimino exitosamente.',
            });
            setSelectedRowKeys([]);
            setReloadAdvices(true);
        }
    };

    const handleChange = (pagination, filters, sorter) => {
        // setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    return (
        <div className='table-advice'>
            <Row>
                <Col xs={24}>
                    <p
                        style={{
                            textAlign: 'right',
                            textTransform: 'capitalize',
                        }}
                    >
                        {moment().format('dddd LL')}
                    </p>
                </Col>
            </Row>
            <Row className='table-advice__top'>
                <Col md={24} style={{ textAlign: 'right' }}>
                    {/* <Button
                        danger
                        icon={<FilterOutlined />}
                        onClick={clearAll}
                        style={{ marginRight: 10 }}
                    >
                        Limpiar Filtros
                    </Button> */}

                    <Button
                        onClick={handleAddAdvice}
                        type='primary'
                        icon={<PlusOutlined />}
                    >
                        Agregar Friendly TIP
                    </Button>
                </Col>
            </Row>
            <div style={{ marginBottom: 16 }}>
                <span style={{ marginLeft: 8 }}>
                    {hasSelected
                        ? `Seleccionados ${selectedRowKeys.length} elemento(s)`
                        : ''}
                </span>
            </div>

            <Table
                bordered
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataMenu}
                onChange={handleChange}
                loading={reloadAdvices}
                scroll={{ x: 950 }}
            />

            <div style={{ marginBottom: 16 }}>
                <Button
                    type='primary'
                    danger
                    icon={<DeleteOutlined />}
                    size='large'
                    disabled={!hasSelected}
                    onClick={handleDeleteAdvice}
                >
                    {`Eliminar ${selectedRowKeys.length} ${
                        selectedRowKeys.length > 1 ? 'elementos' : 'elemento'
                    }`}
                </Button>
            </div>

            <Modal
                destroyOnClose
                title={modalTitle}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                footer={true}
            >
                {modalContent}
            </Modal>
        </div>
    );
};

export default FriendlyTipsTable;
