import React, { useState } from 'react';
import {
    Button,
    Row,
    Col,
    Checkbox,
    Divider,
    notification,
    Form,
    Spin,
    Modal as ModalAntd,
    Input,
    InputNumber,
    Switch,
} from 'antd';
import {
    DeleteOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../../api/auth';
import { getCompanyIdApi } from '../../../../../api/company';
import { createItemSection } from '../../../../../api/itemSections';
import './AddSectionForm.scss';

const AddSectionForm = (props) => {
    const { element, setIsModalVisible, itemSection } = props;
    const [name, setName] = useState('');
    const [available, setAvailable] = useState(false);
    const [obligatory, setObligatory] = useState(false);
    const [maxOptions, setMaxOptions] = useState('');
    const [optionName, setOptionName] = useState('');
    const [availableOption, setAvailableOption] = useState(false);
    const [hasExtraPrice, setHasExtraPrice] = useState(false);
    const [priceOption, setPriceOption] = useState();

    const [multipleOptions, setMultipleOptions] = useState(false);
    const [arrayOptions, setArrayOptions] = useState([]);

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [loadingForm, setLoadingForm] = useState(false);
    const [form] = Form.useForm();
    const { confirm } = ModalAntd;

    const clearAddOptions = () => {
        setAvailableOption(false);
        setOptionName('');
        setHasExtraPrice(false);
        setPriceOption(null);
    };

    const onFinish = async (values) => {
        setLoadingForm(true);
        let formData = values;
        formData['companyId'] = companyId;
        formData['sectionOptions'] = arrayOptions;
        formData['itemId'] = element;

        if (!formData['multipleOptions']) {
            formData['maxOptions'] = 1;
        }

        const filtradas = formData.sectionOptions.filter(
            (ele) => ele.available === true
        );
        if (formData.sectionOptions.length === 0) {
            setLoadingForm(false);

            notification['error']({
                message: 'Tiene que haber mínimo una opción añadida.',
            });
        } else if (formData.available === true && filtradas.length === 0) {
            setLoadingForm(false);
            notification['error']({
                message: 'Tiene que haber mínimo una opción disponible.',
            });
        } else if (formData.maxOptions > formData.sectionOptions.length) {
            setLoadingForm(false);
            notification['error']({
                message:
                    'El número de opciones tiene que ser mayor a la cantidad máxima.',
            });
        } else {
            const result = await createItemSection(token, formData, element);
            if (result.statusCode === 200) {
                setLoadingForm(false);
                notification['success']({
                    message: result.description,
                });
                const newArrayOption = {
                    available: result.result.available,
                    companyId: result.result.companyId,
                    itemId: result.result.itemId,
                    itemSectionId: result.result.itemSectionId,
                    maxOptions: result.result.maxOptions,
                    multipleOptions: result.result.multipleOptions,
                    name: result.result.name,
                    obligatory: result.result.obligatory,
                    sectionOptions: result.result.sectionOptions,
                };
                itemSection.push(newArrayOption);

                setIsModalVisible(false);
                setName('');
                setAvailable(false);
                setObligatory(false);
                setMultipleOptions(false);
                setMaxOptions('');
                form.resetFields();
                setArrayOptions([]);
                clearAddOptions();
            }
            if (result.statusCode === 409) {
                setLoadingForm(false);
                let messageError = result.description;
                notification['error']({
                    message: messageError,
                });
            }
            if (result.statusCode === 400) {
                setLoadingForm(false);
                notification['error']({
                    message: result.description,
                });
            }

            if (result.statusCode === undefined) {
                setLoadingForm(false);
                let messageError =
                    'Ocurrio un problema al crear nueva sección, Intentelo mas tarde';

                if (result.statusCode === 404) {
                    setLoadingForm(false);
                    messageError = result.description;
                }

                notification['error']({
                    message: messageError,
                });
                return true;
            }
            setLoadingForm(false);
        }
    };

    const showDeleteConfirm = (option, index) => {
        confirm({
            title: `¿Estás seguro que deseas borrar la opcion ${option.name}?`,
            icon: <ExclamationCircleOutlined />,
            content: 'No podrás recuperarlo después...',
            onOk() {
                const updateArrayOptions = arrayOptions.filter(
                    (_, i) => index !== i
                );
                setArrayOptions(updateArrayOptions);
            },
            onCancel() {},
        });
    };

    const onAddOptionClicked = () => {
        const filtradas = arrayOptions.filter((ele) => ele.name === optionName);
        if (filtradas.length >= 1) {
            notification['error']({
                message: 'Ya existe una opción con ese nombre',
            });
        } else {
            const newArrayOption = {
                name: optionName,
                available: availableOption,
                hasExtraPrice: hasExtraPrice,
                price: priceOption,
                companyId: companyId,
            };
            setArrayOptions([...arrayOptions, newArrayOption]);
            setOptionName('');
            clearAddOptions();
        }
    };

    return (
        <Spin tip='Guardando...' spinning={loadingForm} size='large'>
            <Form form={form} onFinish={onFinish}>
                <div>
                    <Form.Item
                        label='Sección'
                        name='name'
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese un nombre',
                            },
                        ]}
                    >
                        <Input
                            placeholder='Escribe el nombre de la sección'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item
                        initialValue={false}
                        name='available'
                        value={available}
                        label='Disponible:'
                    >
                        <Switch
                            checked={available}
                            onChange={(check) => setAvailable(check)}
                        />
                    </Form.Item>
                    <Form.Item
                        valuePropName='checked'
                        name='obligatory'
                        initialValue={false}
                        label='Sección Obligatoria'
                        value={obligatory}
                    >
                        <Checkbox onChange={(e) => setObligatory(e.target)} />
                    </Form.Item>
                    <Form.Item
                        initialValue={false}
                        valuePropName='checked'
                        name='multipleOptions'
                        label='Múltiples Opciones'
                        value={multipleOptions}
                    >
                        <Checkbox
                            onChange={(e) =>
                                setMultipleOptions(e.target.checked)
                            }
                        />
                    </Form.Item>
                    {multipleOptions && (
                        <Form.Item label='Máximo de opciones' name='maxOptions'>
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                // formatter={(value) =>
                                //     `${value}`.replace(
                                //         /\B(?=(\d{3})+(?!\d))/g,
                                //         ','
                                //     )
                                // }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)\./g, '')
                                }
                                placeholder='2'
                                value={maxOptions}
                                onChange={(value) => setMaxOptions(value)}
                            />
                        </Form.Item>
                    )}
                    <br />
                    <Divider>Agregar Opciones</Divider>
                    <Row>
                        <Col span={20}>
                            <Form.Item label='Opción'>
                                <Input
                                    placeholder='Escribe el nombre de la opción'
                                    value={optionName}
                                    onChange={(e) =>
                                        setOptionName(e.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4} className='add-ingredient'>
                            <Button
                                disabled={optionName === ''}
                                className={[
                                    'btn-icons',
                                    'btn-add',
                                    'add-ingredient-button',
                                ]}
                                type='primary'
                                size={'small'}
                                shape='circle'
                                icon={<PlusOutlined />}
                                onClick={onAddOptionClicked}
                            />
                        </Col>
                    </Row>
                    <Form.Item initialValue={false} label='Disponible:'>
                        <Switch
                            checked={availableOption}
                            onChange={(check) => setAvailableOption(check)}
                        />
                    </Form.Item>
                    <Row className='margin-check'>
                        <Form.Item
                            label='Costo extra'
                            // name="hasExtraPrice"
                            // valuePropName="checked"
                            // value={hasExtraPrice}
                            initialValue={false}
                        >
                            <Checkbox
                                checked={hasExtraPrice}
                                onChange={(e) =>
                                    setHasExtraPrice(e.target.checked)
                                }
                            />
                        </Form.Item>
                    </Row>

                    {hasExtraPrice && (
                        <Form.Item
                            label='Precio'
                            name='priceOption'
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese el precio',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                formatter={(value) =>
                                    `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                placeholder='Precio'
                                value={priceOption}
                                onChange={(value) => setPriceOption(value)}
                            />
                        </Form.Item>
                    )}

                    <div>
                        <OptionTable
                            options={arrayOptions}
                            showDeleteConfirm={showDeleteConfirm}
                        />
                    </div>

                    <Row className='display-button-right'>
                        <Form.Item>
                            <Button
                                className='margin-check'
                                htmlType='submit'
                                type='primary'
                            >
                                Agregar
                            </Button>
                        </Form.Item>
                    </Row>
                </div>
            </Form>
        </Spin>
    );
};

export default AddSectionForm;

function OptionTable(props) {
    const { showDeleteConfirm, options } = props;

    return (
        <>
            {options.map((option, index) => {
                return (
                    <div key={index} className='border-layout-ing '>
                        <Row className='space-content align-items-center'>
                            <Col
                                className='height-items'
                                xs={20}
                                md={20}
                                lg={20}
                            >
                                <h3>{option.name}</h3>
                            </Col>
                            <Row xs={4} className='space-content'>
                                <Button
                                    className='btn-icons margin-button-ing'
                                    type='primary'
                                    shape='circle'
                                    danger
                                    icon={<DeleteOutlined />}
                                    onClick={() =>
                                        showDeleteConfirm(option, index)
                                    }
                                />
                            </Row>
                        </Row>
                    </div>
                );
            })}
        </>
    );
}
