import React, { useEffect, useState, useRef } from 'react';
import {
    Layout,
    Typography,
    List,
    Image,
    Button,
    Divider,
    Row,
    Col,
    notification,
    Skeleton,
} from 'antd';
import {
    RightOutlined,
    PlusCircleTwoTone,
    CheckCircleTwoTone,
} from '@ant-design/icons';

import HeaderTop from '../../../components/General/HeaderTop';
import VisaCard from '../../../assets/img/png/Visa-Emblema.png';
import MasterCard from '../../../assets/img/png/masterCard.png';
import AmericaExpress from '../../../assets/img/png/americaExpress.png';
import { getAccessTokenApi, logout } from '../../../api/auth';
import {
    getCompanyApi,
    getCompanyIdApi,
    getStripePaymentMethodsApi,
} from '../../../api/company';
import { userLogOut } from '../../../utils/general';

import './CreditCards.scss';
import { getCardsConektaApi } from '../../../api/conekta';

const CreditCards = ({ history }) => {
    const isMounted = useRef(true);
    const [cards, setCards] = useState([]);
    const [cardSelect, setCardSelect] = useState('');
    const [loading, setLoading] = useState(true);
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [company, setCompany] = useState(null);

    const { Content } = Layout;

    useEffect(() => {
        if (company === null) {
            const idComany = getCompanyIdApi();
            getCompanyApi(token, idComany)
                .then((response) => {
                    if (response) {
                        if (response?.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }
                        if (response.error) {
                            return false;
                        }
                        setCompany({
                            UseStripe: response.useStripe,
                            UseConekta: response.useConekta,
                        });

                        if (response.useStripe) {
                            stripeMethods();
                        }

                        if (response.useConekta) {
                            conektaMethods();
                        }
                    }
                })
                .catch((err) => {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    useEffect(() => {
        // stripeMethods();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const stripeMethods = () => {
        getStripePaymentMethodsApi(token).then((data) => {
            if (isMounted.current) {
                if (data?.statusCode === 200) {
                    setCards(data.result);
                    if (data.result.length > 0) {
                        setCardSelect(data.result[0]['id']);
                    }
                } else {
                    notification['error']({
                        message:
                            'Ocurrio un error al cargar la información, intentelo mas tarde.',
                    });
                }
            }

            setLoading(false);
        });
    };

    const conektaMethods = () => {
        const companyId = getCompanyIdApi();
        if (companyId && companyId !== null) {
            getCardsConektaApi(token, companyId).then((data) => {
                if (isMounted.current) {
                    if (data?.statusCode === 200) {
                        setCards(data.result);
                        if (data.result.length > 0) {
                            setCardSelect(data.result[0]['id']);
                        }
                    } else {
                        let msgErrorConeka =
                            'Ocurrio un error al cargar la información, intentelo mas tarde.';
                        if (data.statusCode === 404) {
                            msgErrorConeka = data.description;
                        }
                        notification['error']({
                            message: msgErrorConeka,
                        });
                    }
                }

                setLoading(false);
            });
        } else {
            notification['error']({
                message: 'Operación inválida.',
            });
        }
    };

    // const handleSelectCard = (id) => {
    //     setCardSelect(id);
    //     // setStripePaymentMethod(id);
    // };

    return (
        <Layout className='credit-cards'>
            <Content className='credit-cards__content'>
                <HeaderTop
                    backIcon={true}
                    route={'/payment'}
                    title={'Tarjetas'}
                />
                <div className='credit-cards__container'>
                    {/* <Elements stripe={promise}></Elements> */}
                    <Divider>Tarjetas Guardadas</Divider>

                    <ListCard
                        cards={cards}
                        cardSelect={cardSelect}
                        loading={loading}
                        history={history}
                        company={company}
                    />

                    <Divider></Divider>

                    {companyId && companyId !== '' && (
                        <AddCardButton history={history} />
                    )}
                </div>
            </Content>
        </Layout>
    );
};

function ListCard(props) {
    const { cards, cardSelect, loading, history, company } = props;

    const listData = [];
    for (let i = 0; i < 3; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `ant design part ${i}`,
        });
    }

    const handleGoCard = (id) => {
        history.push(`/payment/cards/${id}`);
    };

    return (
        <>
            {!loading && (
                <List
                    itemLayout='horizontal'
                    dataSource={cards}
                    renderItem={(card) => (
                        <List.Item
                            onClick={() => handleGoCard(card.id)}
                            actions={[
                                <Button type='text'>
                                    <RightOutlined
                                        style={{ color: '#b5b3b3' }}
                                    />
                                </Button>,
                            ]}
                        >
                            <List.Item.Meta
                                avatar={
                                    <AvatarCard card={card} company={company} />
                                }
                                title={
                                    <TitleCard
                                        card={card}
                                        cardSelect={cardSelect}
                                        company={company}
                                    />
                                }
                            />
                        </List.Item>
                    )}
                />
            )}

            {loading && (
                <List
                    itemLayout='horizontal'
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item>
                            <Skeleton
                                loading={loading}
                                active
                                paragraph={false}
                                title
                                avatar={{ shape: 'square' }}
                            ></Skeleton>
                        </List.Item>
                    )}
                />
            )}
        </>
    );
}

function TitleCard(props) {
    const { card, cardSelect, company } = props;
    const { Text } = Typography;
    return (
        <Row>
            <Col>
                <Text>{`... ${
                    company.UseConekta ? card.last4 : card.card.last4
                }`}</Text>
            </Col>
            <Col>
                <>
                    {cardSelect === card.id && (
                        <CheckCircleTwoTone
                            twoToneColor='#52c41a'
                            style={{ fontSize: '16px', marginLeft: 20 }}
                        />
                    )}
                </>
            </Col>
        </Row>
    );
}

function AvatarCard({ card, company }) {
    let srcImage = '';
    let type = company.UseConekta ? card.brand : card.card.brand;
    switch (type) {
        case 'visa':
            srcImage = VisaCard;
            break;

        case 'mastercard':
            srcImage = MasterCard;
            break;

        case 'americaExpress':
            srcImage = AmericaExpress;
            break;

        case 'american_express':
            srcImage = AmericaExpress;
            break;

        default:
            srcImage = VisaCard;
            break;
    }
    return <Image width={25} shape='square' src={srcImage} />;
}

function AddCardButton({ history }) {
    return (
        <Row className='add-card'>
            <Col span={24}>
                <Button
                    shape='round'
                    block
                    danger
                    size='large'
                    icon={
                        <PlusCircleTwoTone
                            style={{ fontSize: 18 }}
                            twoToneColor='#ff7875'
                        />
                    }
                    className='add-card__button'
                    onClick={() => history.push('/payment/create/card')}
                >
                    Agregar método de pago
                </Button>
            </Col>
        </Row>
    );
}

export default CreditCards;
