import React from 'react';
import { Layout, Result, Button } from 'antd';
import logoControlB from '../../../assets/img/png/logoControlB.png';
import HeaderTop from '../../../components/General/HeaderTop';
import { logout } from '../../../api/auth';

import './DisableRestaurant.scss';

export default function DisableRestaurant() {
    const { Content } = Layout;

    const logoutUser = () => {
        logout();
        window.location.reload();
    };

    return (
        <Layout className="disable-restaurant">
            <HeaderTop
                logoCompany
                imageCompany={logoControlB}
                title={'Control B'}
            />
            <Content className="disable-restaurant__content">
                <Result
                    status="403"
                    title="Comercio no disponible."
                    subTitle="Lo sentimos, el comercio no esta disponible."
                    extra={
                        <Button type="primary" onClick={logoutUser}>
                            Salir
                        </Button>
                    }
                />
            </Content>
        </Layout>
    );
}
