import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Layout,
    Form,
    Spin,
    Input,
    Upload,
    Tooltip,
    Button,
    Col,
    Row,
    Select,
    DatePicker,
    notification,
    message,
    Divider,
    Alert,
    Typography,
} from 'antd';
import {
    UserOutlined,
    MailOutlined,
    PhoneOutlined,
    CarOutlined,
    UploadOutlined,
    CreditCardOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import MenuTop from '../../components/Admin/MenuTop';
import { getAccessTokenApi, logout, getEmailUserApi } from '../../api/auth';
import {
    getRunner,
    editRunner,
    addDocumentsRunner,
    getEarningsRunnerApi,
    getDocumentsRunnerApi,
    updateDocumentsRunner,
} from '../../api/runner';
import { getUserApi } from '../../api/user';
import useAuth from '../../hooks/useAuth';
import { formatMoney, userLogOut } from '../../utils/general';
import { askForNotificationPermission } from '../../utils/subscriptions';
import './Runner.scss';

export default function Runner() {
    let history = useHistory();
    const [loading, setLoading] = useState(true);
    const [loadingDocuments, setLoadingDocuments] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [idVehicleRunner, setIdVehicleRunner] = useState(0);
    const [idRunner, setIdRunner] = useState(0);
    const [userData, setUserData] = useState({});
    const [userDocuments, setUserDocuments] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const [completeDocuments, setCompleteButtonDocuments] = useState(false);
    const [messageTip, setMessageTip] = useState('Cargando....');
    const [earning, setEarning] = useState(0);
    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const { Option } = Select;
    const token = getAccessTokenApi();
    const { user } = useAuth();
    const [filesList, setFileList] = useState({});
    const [completeFiles, setCompleteFiles] = useState({
        circulationCardFrontId: false,
        circulationCardBackId: false,
        driversLicenseFrontId: false,
        driversLicenseBackId: false,
    });
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [image, setImage] = useState({
        imageUrl: '',
    });
    const [pendingDocumnets, setPendingDocumnets] = useState([]);
    // const { imageUrl } = image;
    const [formDocuments] = Form.useForm();
    const { Paragraph, Text } = Typography;

    const layout = {
        labelCol: {
            span: 8,
        },
        wrapperCol: {
            span: 10,
        },
    };

    const tailLayoutButton = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const layoutCard = {
        labelCol: { span: 12 },
        wrapperCol: { span: 12 },
    };

    useEffect(() => {
        activeNotification();
        if (token == null) {
            userLogOut();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    useEffect(() => {
        const email = getEmailUserApi(token);
        getUserApi(email).then((response) => {
            const data = JSON.parse(response);
            if (!data.isPasswordChanged) {
                window.location.href = '/runner/changePassword';
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];

        getRunner(token, clientId).then((response) => {
            if (response === undefined) {
                return false;
            }

            if (response.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1500);
                return;
            }

            if (response.statusCode === 200) {
                setUserData([
                    {
                        name: ['Name'],
                        value: response.result.name,
                    },
                    {
                        name: ['LastName'],
                        value: response.result.lastName,
                    },
                    {
                        name: ['email'],
                        value: response.result.email,
                    },
                    {
                        name: ['phoneNumber'],
                        value: response.result.phoneNumber,
                    },
                    {
                        name: ['Active'],
                        value: response.result.active,
                    },
                    {
                        name: ['authorized'],
                        value: response.result.authorized,
                    },
                    {
                        name: ['VehicleType'],
                        value: response.result.vehicle.type,
                    },
                    {
                        name: ['Plate'],
                        value: response.result.vehicle.plate,
                    },
                    {
                        name: ['birthDay'],
                        value: moment.unix(response.result.birthDate),
                    },
                ]);
                setIdVehicleRunner(response.result.vehicle.id);
                if (response?.result?.isAuthorized === true) {
                    setIsDisabled(true);
                }
            }
            setLoading(false);
        });

        setIdRunner(clientId);
        return () => {};
    }, [token, user]);

    useEffect(() => {
        setLoading(true);
        getEarningsRunnerApi(token).then((response) => {
            if (response?.statusCode === 200) {
                setEarning(response?.result.earning);
            } else {
                if (response?.statusCode !== 200) {
                    let msg =
                        'Ocurrio un problema, Intente mas tarde por favor.';

                    if (
                        response?.statusCode === 409 ||
                        response?.statusCode === 400
                    ) {
                        msg = response?.description;
                    }

                    notification['error']({
                        message: msg,
                    });
                } else {
                    //recreo correctamenta
                    notification['success']({
                        message: 'Información actualizado exitosamente',
                    });
                    setDisableButton(true);
                }
            }
            setLoading(false);
        });
    }, [token]);

    useEffect(() => {
        getDocumentsRunnerApi(token).then((response) => {
            if (response?.statusCode === 200) {
                const result = response.result;
                let msgError = 'Falta agregar documento(s).';
                let isError = false;
                let docArray = [];

                if (result.circulationCardBackName == null) {
                    isError = true;
                    docArray.push({
                        name: 'Tarjeta de circulación por detrás.',
                    });
                }

                if (result.circulationCardFrontName == null) {
                    isError = true;
                    docArray.push({
                        name: 'Tarjeta de circulación por enfrente.',
                    });
                }

                if (result.driversLicenseBackName == null) {
                    isError = true;
                    docArray.push({
                        name: 'Licencia de manejo por detrás.',
                    });
                }

                if (result.driversLicenseFrontName == null) {
                    isError = true;
                    docArray.push({
                        name: 'Licencia de manejo enfrente',
                    });
                }

                if (isError) {
                    notification['warn']({
                        message: msgError,
                    });

                    setCompleteFiles({
                        circulationCardFrontId: !result.circulationCardFrontName
                            ? false
                            : true,
                        circulationCardBackId: !result.circulationCardBackName
                            ? false
                            : true,
                        driversLicenseFrontId: !result.driversLicenseFrontName
                            ? false
                            : true,
                        driversLicenseBackId: !result.driversLicenseBackName
                            ? false
                            : true,
                    });
                    setPendingDocumnets(docArray);
                } else {
                    //DOCUMENTOS COMPLETOS
                    setCompleteButtonDocuments(true);
                    setCompleteButtonDocuments({
                        circulationCardFrontId: true,
                        circulationCardBackId: true,
                        driversLicenseFrontId: true,
                        driversLicenseBackId: true,
                    });
                }
                setUserDocuments([
                    {
                        name: ['CirculationCardExpirationDate'],
                        value: response.result.circulationCardExpirationDate
                            ? moment.unix(
                                  response.result.circulationCardExpirationDate
                              )
                            : undefined,
                    },
                    {
                        name: ['DriversLicenseExpirationDate'],
                        value: response.result.driversLicenseExpirationDate
                            ? moment.unix(
                                  response.result.driversLicenseExpirationDate
                              )
                            : undefined,
                    },
                ]);
            } else {
                if (response?.statusCode === 404) {
                    setIsFirstTime(true);
                    const arrayDoc = [
                        {
                            name: 'Tarjeta de circulación por enfrente.',
                        },
                        {
                            name: 'Tarjeta de circulación por detrás.',
                        },
                        {
                            name: 'Licencia de manejo enfrente.',
                        },
                        {
                            name: 'Licencia de manejo por detrás.',
                        },
                    ];
                    setPendingDocumnets(arrayDoc);
                    notification['warning']({
                        message: 'Documentación pendiente.',
                        description:
                            'Falta subir los documentos requeridos al establecimiento.',
                    });
                } else {
                    let msg =
                        'Ocurrio un problema, Intente mas tarde por favor.';

                    if (
                        response?.statusCode === 409 ||
                        response?.statusCode === 400
                    ) {
                        msg = response?.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            }
        });
    }, [token]);

    const updateUser = async (values) => {
        setMessageTip('Guardando...');
        setLoading(true);
        let userUpdate = values;
        delete userUpdate.email;
        userUpdate['phoneNumber'] = userUpdate['phoneNumber'].toString();
        userUpdate['birthDate'] = values.birthDay.unix();
        userUpdate['id'] = idRunner;
        userUpdate['Vehicle'] = {
            type: values.VehicleType,
            plate: values.Plate,
            id: idVehicleRunner,
        };
        const result = await editRunner(token, userUpdate);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409 || result.statusCode === 400) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                //recreo correctamenta
                notification['success']({
                    message: 'Información actualizado exitosamente',
                });
                setDisableButton(true);
            }
        }

        setLoading(false);
        setMessageTip('Cargando...');
    };
    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('¡Solo puedes subir archivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
            message.warning('¡La imagen supera los 3MB recomendados!');
        }
        //   return isJpgOrPng && isLt2M;
        return false;
    }

    function disabledDate(current) {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    const updateDocuments = async (values) => {
        if (
            !values.DriversLicenseExpirationDate ||
            !values.CirculationCardExpirationDate
            // !values.CirculationCardFront ||
            // !values.CirculationCardBack ||
            // !values.DriversLicenseFront ||
            // !values.DriversLicenseBack
        ) {
            notification['error']({
                message:
                    'La fecha de vencimiento en los documentos son requeridas',
            });
            return;
        }
        setLoadingDocuments(true);

        if (values.DriversLicenseExpirationDate) {
            values['DriversLicenseExpirationDate'] =
                values.DriversLicenseExpirationDate.unix();
        }

        if (values.CirculationCardExpirationDate) {
            values['CirculationCardExpirationDate'] =
                values.CirculationCardExpirationDate.unix();
        }

        if (values.CirculationCardExpirationDate === undefined) {
            values.CirculationCardExpirationDate = 0;
        }
        if (values.DriversLicenseExpirationDate === undefined) {
            values.DriversLicenseExpirationDate = 0;
        }

        if (isFirstTime) {
            const result = await addDocumentsRunner(token, values);

            if (result === undefined) {
                notification['error']({
                    message:
                        'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                });
            } else {
                if (result.statusCode !== 200) {
                    let msg =
                        'Ocurrio un problema, Intente mas tarde por favor.';

                    if (
                        result.statusCode === 409 ||
                        result.statusCode === 400
                    ) {
                        msg = result.description;
                    }

                    // if(result.status === 400){
                    //     if(result.errors){
                    //         if(result.errors['CirculationCardBack']){

                    //         }
                    //     }
                    // }

                    notification['error']({
                        message: msg,
                    });
                } else {
                    //recreo correctamenta
                    notification['success']({
                        message: 'Documentos enviados exitosamente.',
                    });
                    setIsDisabled(true);
                    formDocuments.resetFields();
                    // setCompleteButtonDocuments(true);
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }
        } else {
            const result = await updateDocumentsRunner(token, values);

            if (result === undefined) {
                notification['error']({
                    message:
                        'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                });
            } else {
                if (result.statusCode !== 200) {
                    let msg =
                        'Ocurrio un problema, Intente mas tarde por favor.';

                    if (
                        result.statusCode === 409 ||
                        result.statusCode === 400
                    ) {
                        msg = result.description;
                    }

                    notification['error']({
                        message: msg,
                    });
                } else {
                    //recreo correctamenta
                    notification['success']({
                        message: 'Documentos enviados exitosamente.',
                    });

                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                    // setIsDisabled(true);
                    // formDocuments.resetFields();
                    // setCompleteButtonDocuments(true);
                }
            }
        }

        setLoadingDocuments(false);
    };

    function activeNotification() {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];

        askForNotificationPermission(clientId, roleId, '', token);
    }

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        if (Array.isArray(fileList) && fileList.length) {
            // 1. Limit the number of uploaded files
            // Only to show two recent uploaded files, and old ones will be replaced by the new
            fileList = fileList.slice(-1);

            let file = fileList[0];

            getBase64(file.originFileObj, (imageUrl) =>
                setImage({
                    imageUrl: imageUrl,
                })
            );
        } else {
            setImage({
                imageUrl: null,
            });
        }
        setFileList({ fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList.slice(-1);
    };

    function disabledBithDate(current) {
        return current > moment().endOf('day');
    }

    return (
        <Layout className="runner">
            <MenuTop title={'Perfil'} />
            <Divider>
                <h1>Ganancias</h1>
            </Divider>

            <div
                className="div-ganancias"
                onClick={() => history.push('/runner/orderHistory')}
            >
                <h3 className="text-ganancias">MXN {formatMoney(earning)}</h3>
            </div>
            <div className="div-ganancias-text">
                <p>Total de ganancias de las ultimas 24 horas</p>
            </div>
            <Spin tip={messageTip} spinning={loading}>
                <Form
                    {...layout}
                    className="form-edit"
                    onFinish={updateUser}
                    fields={userData}
                    scrollToFirstError={true}
                    onFieldsChange={(changedFields, allFields) => {
                        setDisableButton(false);
                    }}
                >
                    <Divider>
                        <h1>Información general</h1>
                    </Divider>
                    <Row gutter={[0, 24]}>
                        <Col md={24} xs={24}>
                            <Alert
                                message="Información"
                                description="Una vez autorizado en algún establecimiento, no sera posible actualizar tu información y documentos hasta su fecha de vencimiento o te desafilies de todos los establecimientos laborando."
                                type="info"
                                showIcon
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col md={24} xs={24}>
                            <Form.Item
                                name="Name"
                                label="Nombre del runner"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese un nombre',
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder="Nombre"
                                    disabled={isDisabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col md={24} xs={24}>
                            <Form.Item
                                name="LastName"
                                label="Apellido del runner"
                                hasFeedback
                                rules={[
                                    {
                                        message:
                                            'Por favor ingrese un apellido',
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<UserOutlined />}
                                    placeholder="Apellido"
                                    disabled={isDisabled}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="birthDay"
                                label={'Fecha de nacimiento'}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingresa tú fecha de nacimiento',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            const years = moment().diff(
                                                value,
                                                'years',
                                                true
                                            );
                                            if (years >= 18) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(
                                                    'Debe de tener 18 años o más'
                                                );
                                            }
                                        },
                                    }),
                                ]}
                            >
                                <DatePicker
                                    disabledDate={disabledBithDate}
                                    className={'picker-widt'}
                                    initialValues={moment(
                                        '01/01/2002',
                                        dateFormatList[0]
                                    )}
                                    format={dateFormatList}
                                    disabled={isDisabled}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col md={24} xs={24}>
                            <Form.Item
                                label="Correo Electrónico"
                                name="email"
                                hasFeedback
                                rules={[
                                    {
                                        type: 'email',
                                        message:
                                            'El correo electrónico no es válido',
                                    },
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingrese un correo electrónico',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<MailOutlined />}
                                    placeholder="Email"
                                    disabled
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="phoneNumber"
                                label={
                                    <span>
                                        Teléfono&nbsp;
                                        <Tooltip title="Tú número telefónico debe contener 10 dígitos">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </span>
                                }
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingresa tú número telefónico',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(rule, value) {
                                            if (
                                                !value ||
                                                value.toString().length === 10
                                            ) {
                                                return Promise.resolve();
                                            }

                                            return Promise.reject(
                                                'Por favor ingresa tú número telefónico a 10 dígitos'
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    prefix={<PhoneOutlined />}
                                    placeholder="Teléfono"
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                label="Vehículo"
                                name="VehicleType"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor seleccione un vehículo',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Selecciona"
                                    name="type"
                                    prefix={<CarOutlined />}
                                >
                                    <Option value={0}>Automóvil</Option>
                                    <Option value={1}>Motocicleta</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={24}>
                            <Form.Item
                                name="Plate"
                                label="Placas"
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese las placas',
                                        whitespace: true,
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<CreditCardOutlined />}
                                    placeholder="ABCD123"
                                />
                            </Form.Item>
                        </Col>
                        <Col></Col>
                    </Row>
                    <Form.Item
                        {...tailLayoutButton}
                        className="perfil-form__container-button"
                    >
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            disabled={disableButton}
                        >
                            Actualizar Datos
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
            <Divider>
                <h1>Documentos</h1>
            </Divider>

            {!completeDocuments && (
                <Spin tip="Enviando..." spinning={loadingDocuments}>
                    <div className="container-alert">
                        <Alert
                            message="Documentos pendientes:"
                            description={
                                <ListPendindDocuments
                                    pendingDocumnets={pendingDocumnets}
                                />
                            }
                            type="warning"
                            showIcon
                        />
                    </div>
                    <Form
                        {...layout}
                        className="form-edit-documents"
                        onFinish={updateDocuments}
                        scrollToFirstError={true}
                        form={formDocuments}
                        fields={userDocuments}
                    >
                        <Form.Item
                            name="CirculationCardFront"
                            label="Tarjeta de circulación (Frente)"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            hidden={loading}
                            rules={[
                                {
                                    required: isFirstTime,
                                    message: 'Suba una imagen',
                                },
                            ]}
                        >
                            <Upload
                                name="tarjetaf"
                                beforeUpload={beforeUpload}
                                listType="picture"
                                multiple={false}
                                onChange={handleChange}
                                fileList={filesList}
                                disabled={completeFiles.circulationCardFrontId}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    disabled={
                                        completeFiles.circulationCardFrontId
                                    }
                                >
                                    Click para cargar la imagen
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="CirculationCardBack"
                            label="Tarjeta de circulación (Detrás)"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            hidden={loading}
                            rules={[
                                {
                                    required: isFirstTime,
                                    message: 'Suba una imagen',
                                },
                            ]}
                        >
                            <Upload
                                name="tarjeta"
                                beforeUpload={beforeUpload}
                                listType="picture"
                                multiple={false}
                                onChange={handleChange}
                                fileList={filesList}
                                disabled={completeFiles.circulationCardBackId}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    disabled={
                                        completeFiles.circulationCardBackId
                                    }
                                >
                                    Click para cargar la imagen
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="CirculationCardExpirationDate"
                            label="Fecha de vencimiento de la tarjeta de circulación"
                            {...layoutCard}
                            rules={[
                                {
                                    required: true,
                                    // required: isFirstTime,
                                    message: 'Debe introducir una fecha',
                                },
                            ]}
                        >
                            <DatePicker
                                allowClear={false}
                                disabledDate={disabledDate}
                                disabled={isDisabled}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            name="DriversLicenseFront"
                            label="Licencia de manejo (Frente)"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            hidden={loading}
                            rules={[
                                {
                                    required: isFirstTime,
                                    message: 'Suba una imagen',
                                },
                            ]}
                        >
                            <Upload
                                name="licenciaf"
                                beforeUpload={beforeUpload}
                                listType="picture"
                                multiple={false}
                                onChange={handleChange}
                                fileList={filesList}
                                disabled={completeFiles.driversLicenseFrontId}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    disabled={
                                        completeFiles.driversLicenseFrontId
                                    }
                                >
                                    Click para cargar la imagen
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="DriversLicenseBack"
                            label="Licencia de manejo (Detrás)"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            hidden={loading}
                            rules={[
                                {
                                    required: isFirstTime,
                                    message: 'Suba una imagen',
                                },
                            ]}
                        >
                            <Upload
                                name="licencia"
                                beforeUpload={beforeUpload}
                                listType="picture"
                                multiple={false}
                                onChange={handleChange}
                                fileList={filesList}
                                disabled={completeFiles.driversLicenseBackId}
                            >
                                <Button
                                    icon={<UploadOutlined />}
                                    disabled={
                                        completeFiles.driversLicenseBackId
                                    }
                                >
                                    Click para cargar la imagen
                                </Button>
                            </Upload>
                        </Form.Item>

                        <Form.Item
                            name="DriversLicenseExpirationDate"
                            label="Fecha de vencimiento de la licencia de manejo"
                            {...layoutCard}
                            rules={[
                                {
                                    // required: isFirstTime,
                                    required: true,
                                    message: 'Debe introducir una fecha',
                                },
                            ]}
                        >
                            <DatePicker
                                // allowClear={false}
                                disabledDate={disabledDate}
                                disabled={isDisabled}
                                style={{ width: 200 }}
                            />
                        </Form.Item>

                        <Form.Item
                            hidden={loading}
                            {...tailLayoutButton}
                            className="perfil-form__container-button"
                        >
                            <Button
                                htmlType="submit"
                                type="primary"
                                size="large"
                                block
                                style={{ marginBottom: 20 }}
                                disabled={isDisabled}
                            >
                                Enviar documentos
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            )}
            {completeDocuments && (
                <>
                    <div className="container-alert">
                        <Alert
                            message="Documentación Completada"
                            description="Usted ha enviado todos los documentos correctamente."
                            type="success"
                            showIcon
                        />
                    </div>
                    <div>
                        <div className="container-alert">
                            <Alert
                                message="Fechas de vencimiento"
                                description={
                                    <>
                                        <Paragraph>
                                            Tarjeta de circulación:{' '}
                                            <Text strong>
                                                {moment(
                                                    userDocuments[0]?.value
                                                ).format('L')}
                                            </Text>
                                        </Paragraph>
                                        <Paragraph>
                                            Licencia de manejo:{' '}
                                            <Text strong>
                                                {moment(
                                                    userDocuments[1]?.value
                                                ).format('L')}
                                            </Text>
                                        </Paragraph>
                                    </>
                                }
                                type="info"
                                showIcon
                            />
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
}
function ListPendindDocuments(props) {
    const { pendingDocumnets } = props;

    return (
        <ul>
            {pendingDocumnets.map((doc, index) => {
                return <li key={index}>{doc.name}</li>;
            })}
        </ul>
    );
}
