import { ACCESS_TOKEN } from '../utils/constants';
import jwtDecode from 'jwt-decode';

export function getAccessTokenApi() {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);

    if (!accessToken || accessToken === 'null' || accessToken === null) {
        return null;
    }

    return willExpireToken(accessToken) ? null : accessToken;
}

export function getEmailUserApi(token) {
    if (token) {
        const metaToken = jwtDecode(token);
        const email =
            metaToken[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
            ];
        return email;
    } else {
        return '';
    }
}

export function getRolUserApi(token) {
    if (token) {
        const metaToken = jwtDecode(token);
        const rol =
            metaToken[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        return rol;
    } else {
        return '';
    }
}

export function logout() {
    // localStorage.removeItem(ACCESS_TOKEN);
    // localStorage.removeItem(REFRESH_TOKEN);
    localStorage.clear();
}

function willExpireToken(token) {
    const seconds = 60;
    const metaToken = jwtDecode(token);
    const { exp } = metaToken;
    const now = (Date.now() + seconds) / 1000;

    return now > exp ? true : false;
}
