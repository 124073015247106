import React, { useEffect, useState } from 'react';
import {
    Card,
    Row,
    Col,
    Image,
    Button,
    Divider,
    Alert,
    notification,
    Spin,
} from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';

import VisaCard from '../../../assets/img/png/Visa-Emblema.png';
import MasterCard from '../../../assets/img/png/masterCard.png';
import AmericaExpress from '../../../assets/img/png/americaExpress.png';

import './CheckoutConekta.scss';
import {
    createCardAndCustomerApi,
    getCardsConektaApi,
} from '../../../api/conekta';
import { messageError } from '../../../utils/general';

export default function CheckoutConekta(props) {
    const {
        setConfirmOrder,
        setConektaPaymentMethod,
        setActiveSpin,
        action = 'general',
        conektaPublicKey,
    } = props;

    const [formInputs, setFormInputs] = useState({
        cardholderName: '',
        cardExpirationMonth: '',
        cardExpirationYear: '',
        cardNumber: '',
        securityCode: '',
        paymentMethodId: '',
        token: '',
    });
    const [isDisable, setIsDisable] = useState(false);
    const [addCard, setAddCard] = useState(false);
    const [showCardSave, setShowCardSave] = useState(false);
    const [cardSave, setCardSave] = useState(null);
    const [cardSelect, setCardSelect] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [brandCard, setBrandCard] = useState(null);
    const [loadingConekta, setLoadingConekta] = useState(false);
    const tokenUser = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    useEffect(() => {
        if (conektaPublicKey !== '') {
            window.Conekta.setPublicKey(conektaPublicKey);
            // window.Conekta.setPublicKey('key_KJysdbf6PotS2ut2');

            // console.log(window.Conekta.getPublicKey());

            window.Conekta.setLanguage('es');

            // console.log(window.Conekta.getLanguage());
            conektaMethods();
        } else {
            notification['error']({
                message: 'Servicio no disponible.',
            });
            setIsDisable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conektaPublicKey]);

    useEffect(() => {
        if (action === 'add-card') {
            setShowCardSave(false);
            setAddCard(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {
        cardholderName,
        cardExpirationMonth,
        cardExpirationYear,
        cardNumber,
        securityCode,
    } = formInputs;

    const clearInputs = () => {
        setFormInputs({
            cardholderName: '',
            cardExpirationMonth: '',
            cardExpirationYear: '',
            cardNumber: '',
            securityCode: '',
            paymentMethodId: '',
            token: '',
        });
    };

    const conektaMethods = () => {
        const companyId = getCompanyIdApi();
        if (companyId && companyId !== null) {
            if (action !== 'add-card') {
                setActiveSpin(true);
            }
            getCardsConektaApi(tokenUser, companyId).then((data) => {
                if (data?.statusCode === 200) {
                    const allCards = data.result;
                    console.log();
                    setCardSave(allCards);
                    if (action !== 'add-card') {
                        setShowCardSave(true);
                        setCardSelect(data.result[0]['id']);
                        setConektaPaymentMethod(data.result[0]['id']);
                        setConfirmOrder(true);
                    }
                } else {
                    let messageError =
                        'Ocurrió un error al cargar la información, intentelo mas tarde.';
                    if (data?.statusCode === 404) {
                        notification['warn']({
                            message: data?.description,
                        });
                    } else {
                        notification['error']({
                            message: messageError,
                        });
                    }
                }

                if (action !== 'add-card') {
                    setActiveSpin(false);
                }
            });
        } else {
            notification['error']({
                message: 'Operación inválida.',
            });
        }
    };

    const handleInputOnBlur = (e) => {
        if (cardExpirationMonth > 0 && cardExpirationMonth <= 9) {
            setFormInputs({
                ...formInputs,
                cardExpirationMonth: ('0' + cardExpirationMonth).slice(-2),
            });
        }
        // console.log(('0' + cardExpirationMonth).slice(-2));
    };

    const handleInputChange = (e) => {
        // console.log('value', e.target.value);
        // console.log('target', e.target.name);

        if (e.target.name === 'cardNumber') {
            if (e.target.value.length > 19) {
                return;
            } else {
                const brand = window.Conekta.card.getBrand(e.target.value);

                setBrandCard(brand);
            }
        }

        if (e.target.name === 'cardExpirationYear') {
            if (e.target.value.length > 4) {
                return;
            }
        }

        if (e.target.name === 'cardExpirationMonth') {
            if (e.target.value.length > 2) {
                return;
            }
        }

        if (e.target.name === 'securityCode') {
            if (e.target.value.length > 4) {
                return;
            }
        }

        setFormInputs({
            ...formInputs,
            [e.target.name]: e.target.value,
        });

        // if (e.target.name === 'cardNumber') {
        //     guessPaymentMethod(e);
        // }
    };

    const handleTokenCard = (event) => {
        event.preventDefault();

        if (
            !cardholderName ||
            !cardExpirationMonth ||
            !cardExpirationYear ||
            !cardNumber ||
            !securityCode
        ) {
            notification['error']({
                message: 'Todos los campos son obligatorios.',
            });
            return false;
        }

        let $form = document.getElementById('card-form');
        // console.log($form);
        window.Conekta.Token.create($form, successHandler, errorHandler);
        return false;
    };

    const successHandler = function (token) {
        setLoadingConekta(true);
        /* token keys: id, livemode, used, object */
        setShowAlert(false);
        // console.log(token);

        setFormInputs({
            ...formInputs,
            paymentMethodId: token,
        });

        const accessToken = getAccessTokenApi();
        const data = {
            TokenizedCardId: token.id,
            Name: cardholderName,
        };
        createCardAndCustomerApi(accessToken, companyId, data).then(
            (response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        if (action !== 'add-card') {
                            conektaMethods();
                            setConfirmOrder(true);
                            setConektaPaymentMethod(response.result.id);
                        }
                        clearInputs();
                        notification['success']({
                            message: 'Tarjeta guardada exitosamente',
                        });
                    } else {
                        let msgErrorConeka =
                            'Ocurrió un error, intentelo mas tarde.';
                        if (
                            response.statusCode === 404 ||
                            response.statusCode === 409 ||
                            response.statusCode === 400
                        ) {
                            msgErrorConeka = response.description;
                        }

                        notification['error']({
                            message: msgErrorConeka,
                        });
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                }
                setLoadingConekta(false);
            }
        );
    };

    const errorHandler = function (err) {
        /* err keys: object, type, message, message_to_purchaser, param, code */

        setShowAlert(true);
        if (err.object === 'error') {
            notification['error']({
                message: err.message_to_purchaser,
            });
        }
    };

    function selectCard(id) {
        setCardSelect(id);
        if (action !== 'add-card') {
            setConfirmOrder(true);
            setConektaPaymentMethod(id);
        }
    }

    return (
        <div className='conekta text-center'>
            <Spin spinning={loadingConekta}>
                {showCardSave && (
                    <div className='checkout-mercado-pago__caontainer'>
                        <Divider>Tarjetas Guardadas</Divider>
                    </div>
                )}
                {showCardSave &&
                    cardSave.map((cardItem) => {
                        return (
                            <Card
                                style={{ marginBottom: 20 }}
                                className={'card-payment'}
                                key={cardItem.id}
                                onClick={() => selectCard(cardItem.id)}
                            >
                                <Row>
                                    <Col span={4}>
                                        <Image
                                            width={25}
                                            src={
                                                cardItem.brand === 'visa'
                                                    ? VisaCard
                                                    : cardItem.brand ===
                                                      'mastercard'
                                                    ? MasterCard
                                                    : AmericaExpress
                                            }
                                        />
                                    </Col>

                                    <Col
                                        span={16}
                                        style={{ textAlign: 'left' }}
                                    >
                                        <p className='mb-0'>
                                            ...{cardItem.last4}
                                        </p>
                                    </Col>
                                    <Col span={4}>
                                        {cardSelect === cardItem.id && (
                                            <CheckCircleTwoTone
                                                style={{ fontSize: '20px' }}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Card>
                        );
                    })}

                {action !== 'add-card' && !isDisable && (
                    <Button
                        type='primary'
                        disabled={isDisable}
                        block
                        onClick={() => setAddCard(true)}
                    >
                        Agregar Tarjeta
                    </Button>
                )}

                {addCard && (
                    <div className='form-conekta'>
                        <Divider>Agregar una tarjeta de crédito/débito</Divider>
                        {showAlert && (
                            <Alert
                                type='error'
                                message='Revise que los datos de su tarjeta sean correctos'
                                banner
                            />
                        )}
                        <form
                            // action=""
                            // method="post"
                            id='card-form'
                            onSubmit={handleTokenCard}
                        >
                            <span className='card-errors'></span>
                            <div className='form-row'>
                                <label>
                                    <span>Nombre del propietario</span>
                                    <input
                                        name='cardholderName'
                                        type='text'
                                        size='20'
                                        data-conekta='card[name]'
                                        className='ant-input'
                                        value={cardholderName}
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </label>
                            </div>
                            <div className='form-row'>
                                <label>
                                    <span>Número de tarjeta de crédito</span>
                                    <div>
                                        {brandCard !== null && (
                                            <img
                                                src={
                                                    brandCard === 'mastercard'
                                                        ? MasterCard
                                                        : brandCard === 'visa'
                                                        ? VisaCard
                                                        : AmericaExpress
                                                }
                                                style={{
                                                    width: 28,
                                                    marginRight: 10,
                                                }}
                                                alt='marca de la tarjeta'
                                            />
                                        )}
                                        <input
                                            name='cardNumber'
                                            value={cardNumber}
                                            type='number'
                                            size='20'
                                            data-conekta='card[number]'
                                            className='ant-input number-card'
                                            onChange={(e) =>
                                                handleInputChange(e)
                                            }
                                        />
                                    </div>
                                </label>
                            </div>
                            <div className='form-row'>
                                <label>
                                    <span className='cvv-label'>CVC</span>
                                    <input
                                        name='securityCode'
                                        value={securityCode}
                                        type='number'
                                        size='4'
                                        data-conekta='card[cvc]'
                                        className='ant-input cvv-input'
                                        onChange={(e) => handleInputChange(e)}
                                    />
                                </label>
                            </div>
                            <div className='form-row'>
                                <label>
                                    <span style={{ display: 'block' }}>
                                        Fecha de expiración (MM/AAAA)
                                    </span>
                                </label>
                                <input
                                    name='cardExpirationMonth'
                                    value={cardExpirationMonth}
                                    type='number'
                                    size='2'
                                    data-conekta='card[exp_month]'
                                    className='ant-input month-input'
                                    onChange={(e) => handleInputChange(e)}
                                    onBlur={(e) => handleInputOnBlur(e)}
                                    placeholder='06'
                                />
                                <span
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                >
                                    /
                                </span>
                                <input
                                    name='cardExpirationYear'
                                    value={cardExpirationYear}
                                    type='number'
                                    size='4'
                                    data-conekta='card[exp_year]'
                                    className='ant-input year-input'
                                    onChange={(e) => handleInputChange(e)}
                                    placeholder='2025'
                                />
                            </div>
                            {/* PENDIENTE */}
                            {/* <Divider>Direcíon de Facturación</Divider>
                            <div className='form-row'>
                                <label>
                                    <input
                                        type='checkbox'
                                        defaultChecked={useUserAddress}
                                        onChange={() =>
                                            setUseUserAddress(!useUserAddress)
                                        }
                                        className='ant-input year-input'

                                        // className="ant-checkbox-input"
                                    />
                                    Usar dirección de envío como dirección de
                                    facturación
                                </label>
                            </div> */}
                            {/* <div className="form-row">
                            <label>
                                <span>Dirección</span>
                                <input
                                    name="address"
                                    type="text"
                                    className="ant-input"
                                    // value={cardholderName}
                                    // onChange={(e) => handleInputChange(e)}
                                />
                            </label>
                        </div> */}
                            <button
                                className='ant-btn ant-btn-primary ant-btn-block'
                                type='submit'
                                // disabled={token !== '' ? true : false}
                            >
                                Guardar
                            </button>
                        </form>
                    </div>
                )}
                {isDisable && (
                    <Alert
                        type='error'
                        message={'Método de pago no disponible por el momento'}
                        banner
                    />
                )}
            </Spin>
        </div>
    );
}
