import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Typography,
    Divider,
    List,
    Button,
    Skeleton,
    notification,
} from 'antd';
import { CaretRightOutlined, LikeFilled } from '@ant-design/icons';
import { formatMoney, messageError } from '../../../../utils/general';
import Modal from '../../../../components/General/Modal';

import './Packages.scss';
import {
    getProductsSubscriptionApi,
    getPriceProductSubscriptionApi,
} from '../../../../api/subscription';
import { getAccessTokenApi } from '../../../../api/auth';
import PaymentMethods from '../../../General/PaymentMethods/PaymentMethods';
import {
    getCompanyIdApi,
    getCompanySuscriptionApi,
} from '../../../../api/company';

const { Title, Paragraph, Text } = Typography;

export default function Packages() {
    const [products, setProducts] = useState([]);
    const [current, setCurrent] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalTitle, setModalTitle] = useState('');
    const token = getAccessTokenApi();

    useEffect(() => {
        getProductsSubscriptionApi(token)
            .then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        setProducts(response.result);
                        setLoading(false);
                    } else {
                        notification['error']({
                            message: 'Ocurrió un error, intentelo mas tarde.',
                        });
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                }
            })
            .catch((err) => console.log(err));
    }, [token]);

    useEffect(() => {
        const companyId = getCompanyIdApi();

        getCompanySuscriptionApi(token, companyId).then((response) => {
            if (response) {
                if (response.statusCode === 200) {
                    setCurrent(response.result.suscriptionType);
                } else {
                    let msg =
                        'Ocurrio un error al cargar la información, intentelo de nuevo.';
                    if (response.statusCode === 404) {
                        msg = response.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            } else {
                notification['error']({
                    message: messageError(),
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const basic = [
        { title: 'Perfil de Usuario y Administrador' },
        { title: 'Arma tu Platillo' },
        { title: 'Notificaciones' },
        { title: 'Indicadores' },
        { title: 'Diferentes Pedidos' },
        { title: 'Canasta de Compra' },
        { title: 'Método de Pago' },
        { title: 'Estatus de Seguimiento de Orden' },
    ];

    const plus = [
        { title: 'Descuentos Automatizados' },
        { title: 'Perfil para Runner' },
        { title: 'Banners Promocionales' },
        { title: 'Código de Confirmación de Pedido' },
        { title: 'Seguimiento GPS de Pedido' },
        { title: '+ Paquete Básico' },
    ];

    const premium = [
        { title: 'App Propia' },
        { title: 'Landing Page' },
        { title: 'Integración a su PAC de Facturación' },
        { title: 'Personalización de Funcionalidades y Módulos' },
        { title: 'Programa de Lealtad' },
        { title: 'Perfil de Colaboradores' },
        { title: 'Pedido a la Mesa' },
        { title: '+ Paquete Plus' },
    ];

    return (
        <div className='packages'>
            <Row gutter={[0, 20]} className='packages__container'>
                <Col xs={24} md={8} className='packages__container-basic'>
                    {loading ? (
                        <PackageLoading
                            name='Básico'
                            content={basic}
                            current={current === 1 ? true : false}
                            type='Control B - Basico'
                        />
                    ) : (
                        <Package
                            name='Básico'
                            content={basic}
                            current={current === 1 ? true : false}
                            type='Control B - Basico'
                            products={products}
                            loading={loading}
                            setIsModalVisible={setIsModalVisible}
                            setModalContent={setModalContent}
                            setModalTitle={setModalTitle}
                        />
                    )}
                </Col>
                <Col xs={24} md={8} className='packages__container-plus'>
                    {loading ? (
                        <PackageLoading
                            name='Plus'
                            popular
                            content={plus}
                            type='Control B - Plus'
                        />
                    ) : (
                        <Package
                            name='Plus'
                            popular
                            content={plus}
                            type='Control B - Plus'
                            products={products}
                            setIsModalVisible={setIsModalVisible}
                            setModalContent={setModalContent}
                            setModalTitle={setModalTitle}
                            current={current === 2 ? true : false}
                        />
                    )}
                </Col>
                <Col xs={24} md={8} className='packages__container-premium'>
                    {loading ? (
                        <PackageLoading
                            name='Premium'
                            content={premium}
                            type='Control B - Premium'
                        />
                    ) : (
                        <Package
                            name='Premium'
                            content={premium}
                            type='Control B - Premium'
                            products={products}
                            setIsModalVisible={setIsModalVisible}
                            setModalContent={setModalContent}
                            setModalTitle={setModalTitle}
                            current={current === 3 ? true : false}
                        />
                    )}
                </Col>
            </Row>
            <Modal
                destroyOnClose
                title={modalTitle}
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                footer={true}
            >
                {modalContent}
            </Modal>
        </div>
    );
}

function Package(props) {
    const {
        name,
        popular,
        content,
        current,
        products,
        type,
        setIsModalVisible,
        setModalContent,
        setModalTitle,
    } = props;
    const [price, setPrice] = useState(0);
    const [priceId, setPriceId] = useState('');
    const [loadingPrice, setLoadingPrice] = useState(true);
    const token = getAccessTokenApi();

    useEffect(() => {
        const product = products.find((item) => item.name === type);

        getPriceProductSubscriptionApi(token, product.stripeProductId).then(
            (res) => {
                if (res) {
                    if (res.statusCode === 200) {
                        setPrice(res.result[0]['unitAmount'] / 100);
                        setPriceId(res.result[0]['id']);
                        setLoadingPrice(false);
                    } else {
                        notification['error']({
                            message: 'Ocurrió un error, intentelo mas tarde.',
                        });
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                }
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showModal = () => {
        setModalTitle(`Suscripción: ${type}`);
        setModalContent(
            <SubscriptionPayment
                setIsModalVisible={setIsModalVisible}
                priceId={priceId}
            />
        );
        setIsModalVisible(true);
    };

    return (
        <div className='zoom'>
            {popular && (
                <div className='popular-individual-top'>
                    <LikeFilled style={{ marginRight: 6 }} />
                    Más Recomendado
                </div>
            )}
            <div
                className={`packages__individual ${popular ? 'no-radius' : ''}`}
            >
                <div
                    className={`packages__individual-top text-center ${
                        popular ? 'no-radius' : ''
                    } ${
                        name === 'Básico'
                            ? 'bg-color-rose'
                            : name === 'Plus'
                            ? 'bg-color-light-violet'
                            : 'bg-color-violet'
                    }`}
                >
                    <Title className='name' level={3}>
                        {name}
                    </Title>
                    <Paragraph style={{ color: 'white' }}>
                        Comienza a disfrutar
                    </Paragraph>
                </div>

                <div className='packages__individual-price text-center'>
                    {loadingPrice && (
                        <Skeleton.Input
                            style={{ width: 200 }}
                            active={true}
                            size={'default'}
                        />
                    )}
                    {!loadingPrice && (
                        <Text className='container-price'>
                            $
                            <Text className='price'>{formatMoney(price)} </Text>
                            Mensuales
                        </Text>
                    )}
                </div>
                <Divider>Contiene:</Divider>
                <List
                    itemLayout='horizontal'
                    dataSource={content}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <CaretRightOutlined className='package__icon' />
                                }
                                title={<Text>{item.title}</Text>}
                            />
                        </List.Item>
                    )}
                />
                <Divider />
                <div className='packages__individual-button text-center'>
                    <Button
                        size='large'
                        disabled={current}
                        className={`package-button ${
                            name === 'Básico'
                                ? 'bg-color-rose'
                                : name === 'Plus'
                                ? 'bg-color-light-violet'
                                : 'bg-color-violet'
                        }`}
                        onClick={showModal}
                    >
                        {current ? 'Actual' : 'Suscribir'}
                    </Button>
                </div>
            </div>
            {popular && <div className='popular-individual-bottom' />}
        </div>
    );
}

function PackageLoading(props) {
    const { name, popular, content } = props;

    return (
        <div className='zoom'>
            {popular && (
                <div className='popular-individual-top'>
                    <LikeFilled style={{ marginRight: 6 }} />
                    Más Recomendado
                </div>
            )}
            <div
                className={`packages__individual ${popular ? 'no-radius' : ''}`}
            >
                <div
                    className={`packages__individual-top text-center ${
                        popular ? 'no-radius' : ''
                    } ${
                        name === 'Básico'
                            ? 'bg-color-rose'
                            : name === 'Plus'
                            ? 'bg-color-light-violet'
                            : 'bg-color-violet'
                    }`}
                >
                    <Title className='name' level={3}>
                        {name}
                    </Title>
                    <Paragraph style={{ color: 'white' }}>
                        Comienza a disfrutar
                    </Paragraph>
                </div>

                <div className='packages__individual-price text-center'>
                    <Text className='container-price'>
                        <Skeleton.Input
                            style={{ width: 200 }}
                            active={true}
                            size={'default'}
                        />
                    </Text>
                </div>
                <Divider>Contiene:</Divider>
                <List
                    itemLayout='horizontal'
                    dataSource={content}
                    renderItem={(item) => (
                        <List.Item>
                            <Skeleton
                                loading={true}
                                active
                                paragraph={{ rows: 2 }}
                            ></Skeleton>
                        </List.Item>
                    )}
                />
                <Divider />
                <div className='packages__individual-button text-center'>
                    <Skeleton.Button
                        active
                        size='large'
                        shape='round'
                        style={{ width: 200 }}
                    />
                    {/* <Button
                        size='large'
                        disabled={current}
                        className={`package-button ${
                            name === 'Básico'
                                ? 'bg-color-rose'
                                : name === 'Plus'
                                ? 'bg-color-light-violet'
                                : 'bg-color-violet'
                        }`}
                    >
                        {current ? 'Actual' : 'Subscribir'}
                    </Button> */}
                </div>
            </div>
            {popular && <div className='popular-individual-bottom' />}
        </div>
    );
}

function SubscriptionPayment(props) {
    const { setIsModalVisible, priceId } = props;
    return (
        <div className='subscription-payment'>
            <Row>
                <Col span={24}>
                    <Title level={4}>Tipos de pagos:</Title>
                </Col>
            </Row>
            <PaymentMethods
                subscription
                setIsModalVisible={setIsModalVisible}
                priceId={priceId}
                card
            />
        </div>
    );
}
