import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import './InstallPWA.scss';

const InstallPWA = (props) => {
    const { login } = props;
    const [supportsPWA, setSupportsPWA] = useState(false);
    const [promptInstall, setPromptInstall] = useState(null);

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            console.log('we are being triggered :D');
            setSupportsPWA(true);
            setPromptInstall(e);
        };
        window.addEventListener('beforeinstallprompt', handler);

        return () => window.removeEventListener('transitionend', handler);
    }, []);

    const onClick = (evt) => {
        evt.preventDefault();
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };
    if (!supportsPWA) {
        console.log('Aplicacion instalada');
        return null;
    }
    return (
        <>
            {!login && <Divider>Instalar Aplicación</Divider>}
            <button
                className={`
        link-button ant-btn ant-btn-primary  ant-btn-lg  login-form-login 
        ${
            login
                ? 'btn-install-login'
                : 'btn-install-update ant-btn ant-btn-block'
        }`}
                id='setup_button'
                aria-label='Install app'
                title='Install app'
                onClick={onClick}
            >
                <DownloadOutlined /> Instalar
            </button>
        </>
    );
};

export default InstallPWA;
