import { basePath } from './config';

// GET PRODUCTS SUBCRIPTION
export function getProductsSubscriptionApi(token) {
    const url = `${basePath}/stripe/products`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

// GET PRICE PRODUCT SUBCRIPTION
export function getPriceProductSubscriptionApi(token, productid) {
    const url = `${basePath}/stripe/prices?productid=${productid}`;

    const params = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

// ADD SUBSCRIPTION
export function addSubscriptionAdminStripeApi(token, data) {
    const url = `${basePath}/stripe/subscriptions`;

    const params = {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

// EDIT PAYMENT SUBSCRIPTION
export function editPaymentMethodSubscriptionApi(token, idCompany, data) {
    const url = `${basePath}/admin/company-subscription/${idCompany}`;

    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}

// DELETE SUBSCRIPTION
export function deleteStripeSubscriptionApi(token) {
    const url = `${basePath}/stripe/subscriptions`;

    const params = {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

// ADD SUBSCRIPTION
export function addSubscriptionCompanyApi(token, companyId, data) {
    const url = `${basePath}/Companies/${companyId}/Suscription`;

    const params = {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: '*/*',
        },
    };

    return fetch(url, params)
        .then((response) => {
            return response.json();
        })
        .then((result) => {
            return result;
        })
        .catch((err) => {});
}
