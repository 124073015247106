import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Select,
    InputNumber,
    Switch,
    Upload,
    Button,
    Image,
    Spin,
    message,
    notification,
    Row,
    Col,
    Checkbox,
    Divider,
    Modal,
    Typography,
} from 'antd';
import { UploadOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import imageDefault from '../../../../assets/img/png/camara.ico';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import {
    editDishMenu,
    addImageDishMenu,
    uploadExtraImagesApi,
    getExtraImagesApi,
    deleteExtraImagesApi,
} from '../../../../api/menu';
import { resizeFile } from '../../../../utils/formValidation';
import useAuth from '../../../../hooks/useAuth';

import './EditDishForm.scss';
import { messageError } from '../../../../utils/general';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const layoutSwitch = {
    labelCol: {
        span: 16,
    },
    wrapperCol: {
        span: 8,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const EditDishForm = (props) => {
    const {
        setIsModalVisible,
        element,
        menuAdmin,
        setMenuAdmin,
        imageDish,
        groupId,
        showPickUpMenu,
    } = props;
    const { user } = useAuth();

    const [dishForm, setDishForm] = useState([]);
    const [disabledPrice, setDisabledPrice] = useState(true);
    const [form] = Form.useForm();

    const [unitOfMeasureCheck, setUnitOfMeasureCheck] = useState();
    const [maximum, setMaximum] = useState();
    const [minimum, setMinimum] = useState();

    const [filesList, setFileList] = useState({});
    const [filesListImages, setFileListImages] = useState([
        // {
        //     uid: '1',
        //     name: 'imagen1.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        //     thumbUrl:
        //         'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        //     response: { status: 'success' },
        // },
    ]);

    const [loadingForm, setLoadingForm] = useState(false);
    const [loadingImage, setLoadingImage] = useState(false);
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [image, setImage] = useState({
        imageUrl: '',
    });

    const { imageUrl } = image;
    const { Option } = Select;
    const { confirm } = Modal;
    const { Paragraph } = Typography;

    useEffect(() => {
        setDishForm([
            {
                name: ['name'],
                value: element.name,
            },
            {
                name: ['price'],
                value: element.price,
            },
            {
                name: ['description'],
                value: element.description,
            },
            {
                name: ['toGO'],
                value: element.toGO,
            },
            {
                name: ['toDeliver'],
                value: element.toDeliver,
            },
            // {
            //     name: ['toTable'],
            //     value: element.toTable,
            // },
            {
                name: ['available'],
                value: element.available,
            },
            {
                name: ['priority'],
                value: element.priority,
            },
            {
                name: ['unitOfMeasureCheck'],
                value: element.unitOfMeasure ? true : false,
            },
            {
                name: ['Type'],
                value: element.unitOfMeasure
                    ? element.unitOfMeasure.unitType
                    : null,
            },
            {
                name: ['minimum'],
                value: element.unitOfMeasure
                    ? element.unitOfMeasure.minimumPurchase
                    : null,
            },
            {
                name: ['maximum'],
                value: element.unitOfMeasure
                    ? element.unitOfMeasure.maximumPurchase
                    : null,
            },
        ]);

        if (element.unitOfMeasure !== null) {
            setUnitOfMeasureCheck(true);
        } else {
            setUnitOfMeasureCheck(false);
        }
    }, [element]);

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        if (roleId === 'Admin') {
            setDisabledPrice(false);
        } else {
            setDisabledPrice(true);
        }
    }, [user]);

    useEffect(() => {
        return () => {
            form.resetFields();
            setDishForm([]);
        };
    }, [form]);

    useEffect(() => {
        getExtraImagesApi(token, element.itemId).then((response) => {
            if (response === undefined) {
                notification['error']({
                    message: messageError(),
                });
            } else {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1200);

                    return false;
                }

                if (response.statusCode === 200) {
                    //LENAR ARREGLO
                    let arrayImages = [];
                    if (response.result) {
                        if (response.result.length > 0) {
                            response.result.forEach((img) => {
                                const urlImage = `data:image/${img.extension};base64, ${img.imageData}`;

                                const dataImageApi = {
                                    uid: img.imageId,
                                    name: `${img.fileName}.${img.extension}`,
                                    status: 'done',
                                    // url: urlImage,
                                    thumbUrl: urlImage,
                                    response: { status: 'success' },
                                };

                                arrayImages.push(dataImageApi);
                            });
                        }
                    }
                    setFileListImages(arrayImages);
                } else {
                    let msg =
                        'Lo sentimos, ocurrió un error vuelva a intertarlo.';

                    if (
                        response.statusCode === 400 ||
                        response.statusCode === 409 ||
                        response.statusCode === 404
                    ) {
                        msg = response.description;
                    }
                    setFileListImages([]);
                    notification['error']({
                        message: msg,
                    });
                }
            }
        });
    }, [token, element.itemId]);

    const onFinish = async (values) => {
        window.scrollTo(0, 0);
        setLoadingForm(true);
        let formData = values;
        if (formData.Type !== undefined || formData.Type === 0) {
            if (formData.maximum <= formData.minimum) {
                notification['error']({
                    message:
                        'La cantidad máxima debe ser mayor a la cantidad mínima',
                });
                setLoadingForm(false);
                return false;
            } else {
                const newUnitOfMeasure = {
                    unitType: values.Type,
                    minimumPurchase: formData.minimum,
                    maximumPurchase: formData.maximum,
                };
                formData['unitOfMeasure'] = newUnitOfMeasure;

                element.unitOfMeasure = newUnitOfMeasure;
                setUnitOfMeasureCheck(true);
            }
        } else {
            element.unitOfMeasure = null;
            setUnitOfMeasureCheck(false);
        }
        formData['CompanyId'] = companyId;
        formData['CategoryId'] = element.categoryId;
        formData['itemId'] = element.itemId;

        let includeImage = false;

        let imageItemJSON;

        if (values.upload !== undefined) {
            if (values.upload.length > 0) {
                includeImage = true;

                let image = values.upload[0];

                const isJpgOrPng =
                    image.type === 'image/jpeg' ||
                    image.type === 'image/png' ||
                    image.type === 'image/jpg';

                if (!isJpgOrPng) {
                    notification['error']({
                        message: '¡Solo puedes subir archivos JPG/PNG/JPEG!',
                    });
                    setLoadingForm(true);
                    return false;
                }

                const isLt2M = image.size / 1000 < 250;
                let imageResize = '';
                if (!isLt2M) {
                    notification['warning']({
                        message:
                            'La imagen supera los 250kb, por lo tanto será reducida de tamaño.',
                        placement: 'bottomRight',
                        duration: 3,
                    });
                    imageResize = await resizeFile(image.originFileObj);
                }

                let posData64;
                // let imageBase64;

                if (isLt2M) {
                    posData64 = image.thumbUrl.indexOf(',') + 1;
                    // imageBase64 = image.thumbUrl.substr(posData64);
                } else {
                    posData64 = imageResize.indexOf(',') + 1;
                    // imageBase64 = imageResize.substr(posData64);
                }

                // let posData64 = image.thumbUrl.indexOf(",")+1;
                let posType = image.type.indexOf('/') + 1;

                let formDataImage = {
                    CompanyId: companyId,
                    ImageData: isLt2M
                        ? image.thumbUrl.substr(posData64)
                        : imageResize.substr(posData64),
                    Extension: image.type.substr(posType),
                    MenuCategoryId: element.categoryId,
                    MenuItemId: element.itemId,
                };

                const resultImage = await addImageDishMenu(
                    token,
                    formDataImage
                );

                if (resultImage?.statusCode === 200) {
                    imageItemJSON = {
                        companyId: companyId,
                        extension: image.type.substr(posType),
                        fileLocation: null,
                        fileName: `${companyId}_Item_${values.Name}`,
                        fileType: 'Image',
                        imageData: isLt2M
                            ? image.thumbUrl.substr(posData64)
                            : imageResize.substr(posData64),
                    };
                } else {
                    notification['error']({
                        message: 'Ocurrio un problema al modificar la imagen',
                    });
                }
            }
        }

        const result = await editDishMenu(token, formData);

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message: 'Ocurrió un error, inténtelo de nuevo .',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);

            return false;
        }

        if (result.statusCode === 200) {
            setLoadingForm(false);

            notification['success']({
                message: 'Platillo editado exitosamente',
            });

            const found = menuAdmin.findIndex((obj) => obj.groupId === groupId);
            const foundCat = menuAdmin[found].categories.findIndex(
                (cat) => cat.categoryId === element.categoryId
            );
            const foundItem = menuAdmin[found].categories[
                foundCat
            ].items.findIndex((item) => item.itemId === element.itemId);

            const {
                name,
                price,
                priority,
                toGO,
                description,
                available,
                toDeliver,
            } = result.result;
            // const {name, price, priority, toGO, description, available, toTable } = result.result;

            menuAdmin[found].categories[foundCat].items[foundItem].name = name;
            menuAdmin[found].categories[foundCat].items[foundItem].description =
                description;
            menuAdmin[found].categories[foundCat].items[foundItem].price =
                price;
            menuAdmin[found].categories[foundCat].items[foundItem].priority =
                priority;
            menuAdmin[found].categories[foundCat].items[foundItem].toGO = toGO;
            menuAdmin[found].categories[foundCat].items[foundItem].toDeliver =
                toDeliver;
            // menuAdmin[found].categories[foundCat].items[foundItem].toTable = toTable;
            menuAdmin[found].categories[foundCat].items[foundItem].available =
                available;

            if (includeImage) {
                menuAdmin[found].categories[foundCat].items[
                    foundItem
                ].itemImage = imageItemJSON;
            }

            function compare(a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }
                return 0;
            }

            menuAdmin[found].categories[foundCat].items.sort(compare);

            setMenuAdmin(menuAdmin);

            // setLoadingForm(false);

            setImage({
                imageUrl: null,
            });
            setIsModalVisible(false);
        } else {
            let messageError =
                'Ocurrió un problema al editar un  platillo , Intentelo otra vez';

            if (result.statusCode === 404) {
                messageError = result.description;
            }
            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    const uploadExtraImageServe = async () => {
        if (imageDish === '') {
            notification['error']({
                message: 'Primero debe subir una imágen principal.',
            });
            return;
        }
        setLoadingImage(true);
        let dataImage = [];
        if (filesListImages.length > 0) {
            filesListImages.forEach((image) => {
                if (image.status !== 'done') {
                    const posExtension = image.type.indexOf('/') + 1;
                    const posImageData = image.thumbUrl.indexOf(',') + 1;

                    const jsonImage = {
                        Extension: image.type.substr(posExtension),
                        ImageData: image.thumbUrl.substr(posImageData),
                    };

                    dataImage.push(jsonImage);
                }
            });

            const result = await uploadExtraImagesApi(
                token,
                element.itemId,
                dataImage
            );

            if (result === undefined) {
                notification['error']({
                    message: messageError(),
                });
            } else {
                if (result.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1200);

                    return false;
                }

                if (result.statusCode === 200) {
                    const newImagesArray = result.result;
                    const arrayImages = [];

                    newImagesArray.forEach((image) => {
                        const urlImage = `data:image/${image.extension};base64, ${image.imageData}`;

                        const dataImageApi = {
                            uid: image.imageId,
                            name: `${image.fileName}.${image.extension}`,
                            status: 'done',
                            // url: urlImage,
                            thumbUrl: urlImage,
                            response: { status: 'success' },
                        };

                        arrayImages.push(dataImageApi);
                    });

                    setFileListImages(arrayImages);

                    notification['success']({
                        message: 'Imágenes guardadas exitosamente',
                    });
                } else {
                    let msg =
                        'Lo sentimos, ocurrió un error vuelva a intertarlo.';

                    if (
                        result.statusCode === 400 ||
                        result.statusCode === 409 ||
                        result.statusCode === 404
                    ) {
                        msg = result.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            }
            setLoadingImage(false);
        }
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng =
            file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Solo puedes subir archivos JPG/PNG!');
        }
        const isLt2M = file.size / 1000 < 250;
        if (!isLt2M) {
            message.warning(
                'La imegen debe ser menos de 250 kb para que no pierda calidad'
            );
        }
        //   return isJpgOrPng && isLt2M;
        return false;
    }

    const handleChange = (info) => {
        let fileList = [...info.fileList];

        if (Array.isArray(fileList) && fileList.length) {
            fileList = fileList.slice(-1);
            let file = fileList[0];

            getBase64(file.originFileObj, (imageUrl) =>
                setImage({
                    imageUrl: imageUrl,
                })
            );
        } else {
            setImage({
                imageUrl: null,
            });
        }
        setFileList({ fileList });
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }

        return e && e.fileList.slice(-1);
    };

    function Cancel() {
        // form.resetFields();
        setImage({
            imageUrl: null,
        });
        setIsModalVisible(false);
    }

    const extraImagesChange = (info) => {
        let fileList = [...info.fileList];

        if (fileList.length > 2) {
            notification['warn']({
                message: 'No puede subir más de 2 imágenes',
            });
        }

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(0, 2); //toma solo las 2 primeras
        // fileList = fileList.slice(-2); // remplaza las pasada y siempre son 2

        // 2. Read from response and show file link
        // fileList = fileList.map((file) => {
        //     if (file.response) {
        //         // Component will show file.url as link
        //         file.url = file.response.url;
        //     }
        //     return file;
        // });
        setFileListImages(fileList);
    };

    const propsUpload = {
        onRemove: async (file) => {
            if (file.response?.status === 'success') {
                showDeleteConfirm(file);
                return false;
            } else {
                const index = filesListImages.indexOf(file);
                const newFileListImage = filesListImages.slice();
                newFileListImage.splice(index, 1);
                setFileListImages(newFileListImage);
                return true;
            }
        },
        beforeUpload: (file) => {
            return false;
        },
        onChange: extraImagesChange,
    };

    const showDeleteConfirm = async (file) => {
        confirm({
            title: '¿Estás seguro que deseas borrar la imagen?',
            icon: <ExclamationCircleOutlined />,
            content:
                'Esta imágen ya esta guardada, si la elimina no podrá recuperarla después.',
            okText: 'Si',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                deleteImage(file);
            },
            onCancel() {},
        });
    };

    const deleteImage = async (file) => {
        const result = await deleteExtraImagesApi(
            token,
            element.itemId,
            file.uid
        );

        if (result === undefined) {
            notification['error']({
                message: messageError(),
            });
        } else {
            if (result.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1200);

                return false;
            }

            if (result.statusCode === 200) {
                //borrado
                const index = filesListImages.indexOf(file);
                const newFileListImage = filesListImages.slice();
                newFileListImage.splice(index, 1);
                setFileListImages(newFileListImage);
                notification['success']({
                    message: 'Se ha eliminado exitosamente.',
                });
            } else {
                let msg = 'Lo sentimos, ocurrió un error vuelva a intertarlo.';

                if (
                    result.statusCode === 400 ||
                    result.statusCode === 409 ||
                    result.statusCode === 404
                ) {
                    msg = result.description;
                }
                notification['error']({
                    message: msg,
                });
            }
        }
    };

    return (
        <Spin tip='Guardando...' spinning={loadingForm} size='large'>
            <Form
                {...layout}
                className='dish-form'
                form={form}
                fields={dishForm}
                onFieldsChange={(changedFields, allFields) => {
                    setDishForm(allFields);
                }}
                onFinish={onFinish}
            >
                <Form.Item
                    label='Nombre del platillo'
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder='Nombre del platillo' />
                </Form.Item>

                <Form.Item
                    label='Precio'
                    name='price'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese el precio',
                        },
                    ]}
                >
                    <InputNumber
                        style={{
                            width: '100%',
                        }}
                        formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder='Precio'
                        disabled={disabledPrice}
                    />
                </Form.Item>

                <Form.Item
                    name='description'
                    label='Descripción'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese una descripcion',
                        },
                    ]}
                >
                    <Input.TextArea />
                </Form.Item>

                <div className='dish-form__container-image'>
                    <Image
                        width={200}
                        src={
                            imageUrl
                                ? imageUrl
                                : imageDish !== ''
                                ? imageDish
                                : imageDefault
                        }
                    />
                </div>

                <Form.Item
                    name='upload'
                    valuePropName='fileList'
                    getValueFromEvent={normFile}
                    extra='Imagen adjunta'
                >
                    <Upload
                        name='logo'
                        beforeUpload={beforeUpload}
                        listType='picture'
                        multiple={false}
                        onChange={handleChange}
                        fileList={filesList}
                    >
                        <Button icon={<UploadOutlined />}>
                            Click para cargar la imagen
                        </Button>
                    </Upload>
                </Form.Item>

                <Row>
                    {showPickUpMenu && (
                        <Col span={12} xs={12} xl={12}>
                            <Form.Item
                                name='toGO'
                                label='Menú para llevar'
                                valuePropName='checked'
                                {...layoutSwitch}
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    )}
                    <Col span={12} xs={12} xl={12}>
                        <Form.Item
                            name='toDeliver'
                            label='Menú a domicilio'
                            valuePropName='checked'
                            {...layoutSwitch}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                    {/* <Col span={12} xs={12} xl={12}>
                    <Form.Item name="toTable" label="Menú a la mesa" valuePropName="checked" {...layoutSwitch}>
                        <Switch />
                    </Form.Item>
                </Col> */}
                    <Col span={12} xs={12} xl={12}>
                        <Form.Item
                            name='available'
                            label='Disponible'
                            valuePropName='checked'
                            {...layoutSwitch}
                        >
                            <Switch />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    name='priority'
                    label='Prioridad'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione una prioridad',
                        },
                    ]}
                >
                    <Select placeholder='Seleccione una prioridad' allowClear>
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label='Unidad de medida'
                    name='unitOfMeasureCheck'
                    valuePropName='checked'
                    value={unitOfMeasureCheck}
                >
                    <Checkbox
                        onChange={(e) =>
                            setUnitOfMeasureCheck(e.target.checked)
                        }
                    />
                </Form.Item>
                {unitOfMeasureCheck && (
                    <>
                        <Form.Item
                            name='Type'
                            label='Tipo'
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor seleccione un tipo',
                                },
                            ]}
                        >
                            <Select placeholder='Seleccione un tipo' allowClear>
                                <Option name='kg' value={1}>
                                    Kilogramo
                                </Option>
                                <Option value={2}>Gramo</Option>
                                <Option value={3}>Litro</Option>
                                <Option value={4}>Mililitro</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            label='Mínimo'
                            name='minimum'
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese la cantidad mínima',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                formatter={(value) =>
                                    `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                placeholder='2'
                                value={minimum}
                                onChange={(value) => setMinimum(value)}
                            />
                        </Form.Item>
                        <Form.Item
                            label='Máximo'
                            name='maximum'
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese la cantidad máxima',
                                },
                            ]}
                        >
                            <InputNumber
                                style={{
                                    width: '100%',
                                }}
                                formatter={(value) =>
                                    `$ ${value}`.replace(
                                        /\B(?=(\d{3})+(?!\d))/g,
                                        ','
                                    )
                                }
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)/g, '')
                                }
                                placeholder='2'
                                value={maximum}
                                onChange={(value) => setMaximum(value)}
                            />
                        </Form.Item>
                    </>
                )}
                <Divider>Más Imágenes (max 2)</Divider>
                {filesListImages.length === 0 && (
                    <Paragraph type='secondary'>
                        Sin imágenes extras guardadas.
                    </Paragraph>
                )}
                <Upload
                    {...propsUpload}
                    accept='image/png,image/jpg'
                    multiple
                    listType='picture'
                    fileList={filesListImages}
                    defaultFileList={filesListImages}
                >
                    <Button icon={<UploadOutlined />}>
                        Seleccionar Archivo
                    </Button>
                </Upload>

                <Button
                    loading={loadingImage}
                    onClick={uploadExtraImageServe}
                    type='primary'
                    ghost
                    block
                    style={{ marginTop: 20 }}
                >
                    Guardar Imágenes
                </Button>
                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type='ghost' onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit'>
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default EditDishForm;
