export const mexicanStates = [
    'Aguascalientes',
    'Baja California',
    'Baja California Sur',
    'Chihuahua',
    'Colima',
    'Campeche',
    'Coahuila',
    'Chiapas',
    'Federal District',
    'Durango',
    'Guerrero',
    'Guanajuato',
    'Hidalgo',
    'Jalisco',
    'México State',
    'Michoacán',
    'Morelos',
    'Nayarit',
    'Nuevo León',
    'Oaxaca',
    'Puebla',
    'Querétaro',
    'Quintana Roo',
    'Sinaloa',
    'San Luis Potosí',
    'Sonora',
    'Tabasco',
    'Tlaxcala',
    'Tamaulipas',
    'Veracruz',
    'Yucatán',
    'Zacatecas',
];
