import React, { useState, useEffect } from 'react';
import {
    Image,
    Button,
    Divider,
    Skeleton,
    message,
} from 'antd';

import './CodigoQr.scss';

export default function CodigoQr(props) {

    const { loading, imageQr, urlPage } = props;
    const [textUrl, setTextUrl ] = useState(null);
    const [urlSite, setUrlSite] = useState(urlPage)

    useEffect(() => {
        setUrlSite(urlPage)
        return () => {
            setUrlSite("")
        }
    }, [urlPage])

    const copyCodeToClipboard = () => {
        let context = textUrl;
        context.select();
        document.execCommand("copy");
        message.success('¡Enlace Copiado!');
    }

    const downloadQR = () =>{
        let a = document.createElement('a');
        a.href = imageQr;
        a.download = "QR.png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    

    return(
        <>

            <Divider><h1>Código QR</h1></Divider>

            <Skeleton loading={loading ? true: false} active={true} paragraph={true} />

            <div className="perfil-form__container-image">

                {!loading && imageQr &&
                    <Image
                        width={200}
                        src={imageQr}
                    />
                }

                <Button onClick={()=>downloadQR()} type="link">Descargar código QR</Button>
            </div>

          
            <textarea
                className="hide" 
                readOnly 
                ref={(textarea) => setTextUrl(textarea)}
                style={{ resize: 'none', }} 
                value={urlSite}
                // disabled
            />

            <div className="perfil-form__container-copy">
                <Button type="primary" size="small" ghost onClick={copyCodeToClipboard}>Copiar enlace</Button>
            </div>

         </>
    )
}


