/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import {
    Form,
    Button,
    Input,
    Typography,
    InputNumber,
    Divider,
    Alert,
    notification,
    Spin,
} from 'antd';
import { getAccessTokenApi } from '../../../../api/auth';
import { getAddressByZipcodeApi } from '../../../../api/user';
import {
    createStripeSetupIntentApi,
    getCompanyIdApi,
} from '../../../../api/company';

export default function AddCardStripe() {
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [clientSecret, setClientSecret] = useState('');
    const [showBillingInputs, setShowBillingInputs] = useState(true);
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(true);

    const token = getAccessTokenApi();
    // const companyId = getCompanyIdApi();

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const { Title } = Typography;
    const [form] = Form.useForm();

    useEffect(() => {
        //get client secret
        const companyId = getCompanyIdApi();
        createStripeSetupIntentApi(token, companyId)
            .then((data) => {
                if (data === undefined) {
                    notification['error']({
                        message: 'No esta disponible el servicio.',
                    });
                    setLoading(false);
                    setShowBillingInputs(false);

                    return false;
                }

                if (data.statusCode === 200) {
                    setClientSecret(data.result.clientSecret);
                    setLoading(false);
                    // setAcceptCardCredit(true);
                } else {
                    let msgError = '';
                    if (data.statusCode === 404) {
                        msgError = 'No esta disponible el servicio';
                    } else {
                        msgError = 'Ocurrió un error, intentelo mas tarde';
                    }

                    notification['error']({
                        message: msgError,
                    });
                    setLoading(false);
                    setShowBillingInputs(false);
                }

                setLoading(false);
            })
            .catch((err) => {
                
                setLoading(false);
                setLoading(false);
            });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: '${label} es requido',
        types: {
            email: '${label} no es un email válido!',
            number: '${label} no es un numero!',
        },
        number: {
            range: '${label} debe ser minimo ${min} and ${max}',
            length: '${label} debe de tener 5 dígitos',
        },
    };
    const cardStyle = {
        style: {
            base: {
                color: '#32325d',
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#32325d',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const handleChange = async (event) => {
        // Listen for changes in the CardElement
        // and display any errors as the customer types their card details
        setDisabled(event.empty);
        setError(event.error ? event.error.message : '');
    };

    const handleSaveCard = async (values) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        // event.preventDefault();
        setProcessing(true);

        getAddressByZipcodeApi(values.postal_code).then(async (res) => {
            if (res === undefined) {
                notification['error']({
                    message:
                        'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                });
                return false;
            }
            if (res.CodigoRetorno) {
                let message = 'Ocurrio un error, intentelo de nuevo';
                if (res.CodigoRetorno === '000') {
                    message = 'El código postal no es valido';
                }
                notification['error']({
                    message: message,
                });
                setProcessing(false);
                return false;
            }

            if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
            }

            const infoZipCode = res;
            let billing_address = {};

            if (infoZipCode.length > 0) {
                const location = res[0];
                if (location.code_error === 0 && !location.error) {
                    const addressApi = {
                        city: location.Municipio,
                        country: 'México',
                        line1: values.street,
                        postal_code: location.CP,
                        state: location.Entidad,

                        // city: location.response['ciudad'],
                        // country: location.response['pais'],
                        // line1: values.street,
                        // postal_code: location.response['cp'],
                        // state: location.response['estado'],
                    };
                    billing_address = addressApi;
                }
            }

            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        address: {
                            city: billing_address.city,
                            country: 'MX',
                            line1: billing_address.line1,
                            line2: null,
                            postal_code: billing_address.postal_code,
                            state: billing_address.state,
                        },
                        email: values.email,
                        name: `${values.name} ${values.lastName}`,
                        phone: values.phone,
                    },
                },
            });

            if (result.error) {
                // Display result.error.message in your UI.
                setError(`Tarjeta invalida: ${result.error.message}`);
                setProcessing(false);
            } else {
                // The setup has succeeded. Display a success message and send
                // result.setupIntent.payment_method to your server to save the
                // card to a Customer

                setError(null);
                setProcessing(false);
                setSucceeded(true);
                // setStripePaymentMethod(result.setupIntent.payment_method);
                // stripeMethods();
                notification['success']({
                    message: 'Tarjeta registrada con éxito',
                });
                form.resetFields();
                setTimeout(() => {
                    window.location.href = '/payment/cards';
                }, 1500);

                //RECARGAR
            }
        });
    };

    return (
        <Spin spinning={loading}>
            <Form
                form={form}
                {...layout}
                name='nest-messages'
                className='payment-form'
                onFinish={handleSaveCard}
                validateMessages={validateMessages}
                scrollToFirstError={true}
            >
                {/* <form onSubmit={handleSaveCard} className="payment-form"> */}
                {showBillingInputs && (
                    <>
                        <Divider>Tarjeta de crédito / debito</Divider>
                        <Title
                            level={4}
                            style={{ marginTop: 15, marginBottom: 10 }}
                        >
                            Datos de Facturación
                        </Title>

                        <CardElement
                            options={cardStyle}
                            onChange={handleChange}
                        />

                        <Divider>Información del propietario</Divider>

                        <Form.Item
                            name={['name']}
                            label='Nombre'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['lastName']}
                            label='Apellido'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['phone']}
                            label='Télefono'
                            rules={[
                                { required: true },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            value.toString().length === 10
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Por favor ingresa tú número telefónico a 10 dígitos'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name={['email']}
                            label='Email'
                            rules={[{ type: 'email' }, { required: true }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name='postal_code'
                            label='Código Postal'
                            rules={[{ type: 'number', required: true }]}
                        >
                            <InputNumber
                                parser={(value) =>
                                    value.replace(/\$\s?|(,*)\./g, '')
                                }
                            />
                        </Form.Item>

                        <Form.Item
                            name='street'
                            label='Dirección'
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{ ...layout.wrapperCol, offset: 0 }}
                        >
                            <Button
                                htmlType='submit'
                                className='mt-20'
                                disabled={processing || disabled || succeeded}
                                type='primary'
                                loading={processing}
                                block
                                // onClick={handleSaveCard}
                            >
                                Guardar Tarjeta
                            </Button>
                        </Form.Item>
                    </>
                )}

                {/* Show any error that happens when processing the payment */}
                {error && (
                    <div className='card-error' role='alert'>
                        <br />
                        <Alert type='error' message={error} banner />
                    </div>
                )}

                {/* Show a success message upon completion */}
                {succeeded && (
                    <div className='card-error' role='alert'>
                        <br />
                        <Alert
                            type='success'
                            message={'Tarjeta Guardada'}
                            banner
                        />
                    </div>
                )}
                {/* </form> */}
            </Form>
        </Spin>
    );
}
