import React from 'react';
import {
    List,
    Avatar,
    Image,
    Skeleton,
    Row,
    Col,
    Typography,
    Card,
    Tag,
} from 'antd';
import { formatMoney } from '../../../utils/general';
import { COMPANY_ID } from '../../../utils/constants';
import logoControlB from '../../../assets/img/png/logoControlB.png';

import './ListRestaurant.scss';

export default function ListRestaurant(props) {
    const { restaurants, loadingPage, history } = props;
    // const heightScreen = getHeightScreen();

    const data = [
        {
            title: 'Ant Design Title 1',
        },
        {
            title: 'Ant Design Title 2',
        },
        {
            title: 'Ant Design Title 3',
        },
        {
            title: 'Ant Design Title 4',
        },
    ];
    return (
        <div
            className={`list-restaurant`}

            // className={`list-restaurant ${!search && 'scroll'} ${
            //     heightScreen < 641 && 'small-cell'
            // }  ${heightScreen > 700 && heightScreen < 850 && 'large-cell'}`}
        >
            {!loadingPage && (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    itemLayout="horizontal"
                    dataSource={restaurants}
                    renderItem={(item) => (
                        <List.Item>
                            <CardRestaurant
                                restaurant={item}
                                history={history}
                            />
                        </List.Item>
                    )}
                />
            )}
            {loadingPage && (
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 4,
                        xl: 4,
                        xxl: 4,
                    }}
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <CardLoading />
                        </List.Item>
                    )}
                />
            )}
        </div>
    );
}

function CardRestaurant(props) {
    const { restaurant, history } = props;
    const { Title, Paragraph } = Typography;

    const handleSelectRest = (companyId) => {
        console.log(companyId);
        localStorage.setItem(COMPANY_ID, companyId);
        history.push(`/type/${companyId}`);
    };
    return (
        <>
            <Card
                className="card-restaurant "
                onClick={() => handleSelectRest(restaurant.companyId)}
            >
                <Row>
                    <Col span={8} className="card-restaurant__image-container">
                        <Image
                            width={100}
                            src={
                                restaurant.companyImage !== null
                                    ? `data:image/${restaurant.companyImage.extension};base64, ${restaurant.companyImage.imageData}`
                                    : logoControlB
                            }
                            // src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        />
                    </Col>
                    <Col span={16} className="card-restaurant__info">
                        <Title level={4}>{restaurant.name}</Title>

                        {restaurant.companyCategories.length === 0 && (
                            <Paragraph type="secondary">...</Paragraph>
                        )}

                        <Paragraph type="secondary">
                            {restaurant.companyCategories.length > 0 &&
                                restaurant.companyCategories.map((cat) => {
                                    return (
                                        <Tag
                                            key={cat.companyCategoryId}
                                        >{`${cat.name} `}</Tag>
                                    );
                                })}
                        </Paragraph>

                        <Paragraph type="secondary" style={{ marginBottom: 0 }}>
                            {/* <Image width={40} src={delivery} preview={false} /> */}
                            <i
                                className="fas fa-motorcycle"
                                style={{ marginRight: 5 }}
                            ></i>
                            ${formatMoney(restaurant.deliveryCost)}
                        </Paragraph>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

function CardLoading() {
    return (
        <Card className="card-restaurant">
            <Skeleton loading={true} avatar active>
                <Card.Meta
                    avatar={
                        <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                    }
                    title="Card title"
                    description="This is the description"
                />
            </Skeleton>
        </Card>
    );
}
