import React, { useState } from 'react';
import {
    Form,
    Input,
    InputNumber,
    Button,
    Select,
    notification,
    Spin,
} from 'antd';
import { getAddressByZipcodeApi } from '../../../api/user';
import { addPlace } from '../../../api/places';
import useUser from '../../../hooks/useUser';
import { toTitleCase } from '../../../utils/general';
import { getAccessTokenApi } from '../../../api/auth';
import { messageError } from '../../../utils/general';

const AddressForm = ({
    setAddressUser,
    setIsModalVisibleAddress,
    updateAddressClient,
    type = 'client',
    setAddressInput,
    setShowButtomValidAddress,
    setAddressRest,
    setDisableButton,
    getAddress,
}) => {
    const { person, setPerson } = useUser();
    const [showAddressInputs, setShowAddressInputs] = useState(false);
    const [cityInput, setCityInput] = useState('');
    // const [zipCode, setZipCode] = useState('');
    const [stateInput, setStateInput] = useState('initialState');
    const [addressApi, setAddressApi] = useState([]);
    const [addressForm, setAddressForm] = useState([]);
    const [isValidZipCode, setIsValidZipCode] = useState(false);
    const [showForm, setShowForm] = useState(true);
    const [loading, setloading] = useState(false);
    const [buttonDisabled, setButonDisabled] = useState(false);

    const token = getAccessTokenApi();

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 12 },
    };

    const tailLayoutButton = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    const cancelValidAddress = () => {
        setIsValidZipCode(false);
        setShowAddressInputs(false);
    };

    const onFinish = (values) => {
        setButonDisabled(true);
        if (!isValidZipCode) {
            setloading(true);
            getAddressByZipcodeApi(values.zipcode).then((respuesta) => {
                if (respuesta === undefined) {
                    notification['error']({
                        message:
                            'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                    });
                    if (type === 'client') {
                        setIsModalVisibleAddress(false);
                    }
                    setloading(false);

                    return false;
                }

                if (respuesta.CodigoRetorno) {
                    let message = 'Ocurrio un error, intentelo de nuevo';
                    if (respuesta.CodigoRetorno === '000') {
                        message = 'El código postal no es valido';
                    }

                    // if (res.code_error === 105) {
                    //     message =
                    //         'No se encontro el código postal, revise la informácion.';
                    // }

                    notification['error']({
                        message: message,
                    });
                    setloading(false);

                    return false;
                }

                let state = toTitleCase(respuesta[0]['Entidad']);
                if (state === 'Nuevo Leon') {
                    state = 'Nuevo León';
                }
                // setZipCode(respuesta[0].cp);
                setAddressApi(respuesta);
                setCityInput(toTitleCase(respuesta[0]['Municipio']));
                setStateInput(state);

                setAddressForm([
                    {
                        name: ['city'],
                        value: toTitleCase(respuesta[0]['Municipio']),
                    },
                    {
                        name: ['state'],
                        value: state,
                    },
                    {
                        name: ['colonia'],
                        value: toTitleCase(respuesta[0]['Colonia']),
                    },
                ]);
                setShowAddressInputs(true);
                setIsValidZipCode(true);
                setloading(false);
                setButonDisabled(false);
            });
        } else {
            let numberInterior = '';
            if (values.interiorNumber === undefined) {
                values.interiorNumber = '';
            }
            if (values.interiorNumber !== '') {
                numberInterior = `-${values.interiorNumber}`;
            }
            const addressComplete = `${values.street} ${values.exteriorNumber}${numberInterior} ${values.colonia} ${values.city} ${values.zipcode} ${values.state} `;

            if (type === 'client') {
                const addressCompleteJSON = {
                    alias: values.alias,
                    address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                    neighborhood: values.colonia,
                    city: values.city,
                    zipCode: values.zipcode.toString(),
                    state: values.state,
                };

                addPlace(token, addressCompleteJSON).then((response) => {
                    if (response === undefined) {
                        const msg = messageError();

                        notification['error']({
                            message: msg,
                        });
                        return;
                    }
                    if (response.statusCode === 400) {
                        notification['error']({
                            message: response.description,
                        });
                        return;
                    }
                    if (response.statusCode === 409) {
                        notification['error']({
                            message: response.description,
                        });
                        return;
                    }
                    if (response.statusCode === 200) {
                        notification['success']({
                            message: 'Dirección agregada exitosamente',
                        });
                        setPerson({
                            ...person,
                            address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                            city: values.city,
                            neighborhood: values.colonia,
                            zipCode: values.zipcode,
                            state: values.state,
                        });

                        setAddressUser(addressComplete);
                        updateAddressClient(addressCompleteJSON);
                        setIsModalVisibleAddress(false);
                        getAddress();
                    }
                });
            } else if (type === 'adminPerfil') {
                let numberInterior = '';
                if (values.interiorNumber === undefined) {
                    values.interiorNumber = '';
                }
                if (values.interiorNumber !== '') {
                    numberInterior = `-${values.interiorNumber}`;
                }
                setAddressRest({
                    address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                    zipCode: values.zipcode,
                    neighborhood: values.colonia,
                    city: values.city,
                    state: values.state,
                });
                setIsModalVisibleAddress(false);
                setDisableButton(false);
            } else {
                setPerson({
                    ...person,
                    address: `${values.street} ${values.exteriorNumber}${numberInterior}`,
                    city: values.city,
                    neighborhood: values.colonia,
                    zipCode: values.zipcode,
                    state: values.state,
                });
                setAddressInput(addressComplete);
                setShowButtomValidAddress(true);
                setShowForm(false);
            }
        }
        setTimeout(() => setButonDisabled(false), 5000);
    };

    return (
        <Spin spinning={loading}>
            {showForm && (
                <Form
                    {...layout}
                    name="nest-messages"
                    onFinish={onFinish}
                    fields={addressForm}
                    onFieldsChange={(changedFields, allFields) => {
                        setAddressForm(allFields);
                    }}
                >
                    <Form.Item
                        name="zipcode"
                        label="Código Postal"
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese el código postal',
                            },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (
                                        !value ||
                                        value.toString().length === 5
                                    ) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        'Por favor ingresa código postal válido'
                                    );
                                },
                            }),
                        ]}
                    >
                        <InputNumber
                            disabled={showAddressInputs ? true : false}
                            placeholder="Código Postal"
                            style={{
                                width: '100%',
                            }}
                        />
                    </Form.Item>

                    {showAddressInputs && (
                        <>
                            {type !== 'adminPerfil' && (
                                <Form.Item
                                    name="alias"
                                    label="Alias"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor ingrese un alias',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Casa" />
                                </Form.Item>
                            )}
                            <Form.Item
                                name="street"
                                label="Calle"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Por favor ingrese la calle',
                                    },
                                ]}
                            >
                                <Input placeholder="Calle" />
                            </Form.Item>
                            <Form.Item
                                name="exteriorNumber"
                                label="Número exterior"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor ingrese el número exterior',
                                    },
                                ]}
                            >
                                <Input placeholder="Número exterior" />
                            </Form.Item>
                            <Form.Item
                                name="interiorNumber"
                                label="Número interior"
                                rules={[
                                    {
                                        required: false,
                                        message:
                                            'Por favor ingrese el número exterior',
                                    },
                                ]}
                            >
                                <Input placeholder="Número interior" />
                            </Form.Item>

                            <Form.Item
                                label="Colonia"
                                name="colonia"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor seleccione una colonia',
                                    },
                                ]}
                            >
                                <Select placeholder="Colonia">
                                    {/* {addressApi.map((ele, i) => {
                                        return (
                                            <Select.Option
                                                key={'col' + i}
                                                value={
                                                    ele['response'].asentamiento
                                                }
                                            >
                                                {ele['response'].asentamiento}
                                            </Select.Option>
                                        );
                                    })} */}
                                    {addressApi.map((ele, i) => {
                                        return (
                                            <Select.Option
                                                key={'col' + i}
                                                value={toTitleCase(
                                                    ele['Colonia']
                                                )}
                                            >
                                                {toTitleCase(ele['Colonia'])}
                                            </Select.Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Municipio / Ciudad"
                                name="city"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor seleccione un municipio',
                                    },
                                ]}
                            >
                                <Select placeholder="Ciudad">
                                    <Select.Option value={cityInput}>
                                        {cityInput}
                                    </Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Estado"
                                name="state"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor seleccione un municipio',
                                    },
                                ]}
                            >
                                <Select placeholder="Estado">
                                    <Select.Option value={stateInput}>
                                        {stateInput}
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </>
                    )}
                    <Form.Item {...tailLayoutButton}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            disabled={buttonDisabled}
                        >
                            {!showAddressInputs
                                ? 'Verificar'
                                : type === 'adminPerfil'
                                ? 'Aceptar dirección'
                                : 'Guardar dirección'}
                        </Button>
                    </Form.Item>
                    {showAddressInputs && (
                        <Form.Item {...tailLayoutButton}>
                            <Button
                                type="ghost"
                                className="mt-0"
                                block
                                onClick={cancelValidAddress}
                            >
                                Cancelar
                            </Button>
                        </Form.Item>
                    )}
                </Form>
            )}
        </Spin>
    );
};

export default AddressForm;
