import React from 'react';
import { Layout } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import TableAdvices from '../../../components/Admin/Advices/TableAdvices';

const Advices = () => {
    const { Content } = Layout;

    return (
        <Layout className="collaborators">
            <HeaderTop
                title={'Avisos configurables'}
                // backIcon={true}
                backUrl="/admin"
            />
            <Content className="collaborators__content">
                <TableAdvices />
            </Content>
        </Layout>
    );
};

export default Advices;
