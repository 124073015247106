import React from 'react';
import { Result } from 'antd';

export default function Error404() {
    return(
        <div>
            <Result
                status="404"
                title="404"
                subTitle="Lo siento, La página que visitaste no existe."
            />
        </div>
    )
}