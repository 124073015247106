import React, { useEffect, useState } from 'react';
import { Divider, Layout, notification, Skeleton } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import OrdersList from '../../../components/Runner/OrdersList/OrdersList';
import { getRunner } from '../../../api/runner';
import { getAccessTokenApi, logout, getEmailUserApi } from '../../../api/auth';
import useAuth from '../../../hooks/useAuth';
import { userLogOut } from '../../../utils/general';
import { getUserApi } from '../../../api/user';
import { askForNotificationPermission } from '../../../utils/subscriptions';
import './Orders.scss';

const Orders = () => {
    const { Content } = Layout;
    const token = getAccessTokenApi();
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [showOrderList, setShowOrderList] = useState(false);

    useEffect(() => {
        activeNotification();
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];

        const email = getEmailUserApi(token);
        getUserApi(email).then((response) => {
            const data = JSON.parse(response);
            if (!data.isPasswordChanged) {
                window.location.href = '/runner/changePassword';
            } else {
                getRunner(token, clientId).then((response) => {
                    if (response === undefined) {
                        return false;
                    }

                    if (response.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado',
                        });

                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1500);
                        return;
                    }

                    if (response.statusCode === 200) {
                        if (response?.result?.isAuthorized === false) {
                            window.location.href = '/runner';
                        } else {
                            setShowOrderList(true);
                        }
                    }
                    setLoading(false);
                });
            }
        });

        return () => {};
    }, [token, user]);

    function activeNotification() {
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        askForNotificationPermission(clientId, roleId, '', token);
    }

    return (
        <Layout className="active-orders-list">
            <Content className="active-orders-list">
                <HeaderTop title={'Ordenes'} />
                <div className="active-orders-list__container">
                    {loading && (
                        <>
                            <div className="container-skeleton">
                                <Skeleton.Input
                                    style={{ width: 300 }}
                                    active={true}
                                    size={'large'}
                                />
                            </div>

                            <div className="container-skeleton">
                                <Skeleton.Button
                                    style={{ marginTop: 10 }}
                                    active={true}
                                    size={'large'}
                                />
                            </div>
                            <Divider>
                                <h3>Órdenes a recolectar</h3>
                            </Divider>

                            <Skeleton active paragraph={{ rows: 4 }} />
                        </>
                    )}

                    {showOrderList && <OrdersList />}
                </div>
            </Content>
        </Layout>
    );
};

export default Orders;
