import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Result, Button, Timeline, Divider } from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi, processStripeAccountApi } from '../../../api/company';
import { userLogOut } from '../../../utils/general';

import './ErrorStripeAccount.scss';

const ErrorStripeAccount = () => {
    const { Content } = Layout;
    let history = useHistory();
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    function redirectHome() {
        history.push(`/admin/`);
    }

    useEffect(() => {
        processStripeAccountApi(token, companyId).then((res) => {
            console.log(res);
        });
    }, [companyId, token]);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    return (
        <Layout className='success-account'>
            <Content>
                <Result
                    status='warning'
                    title='¡Registro Incompleto!'
                    subTitle='No ha terminado de configurar la cuenta. Termine el proceso por favor.'
                    extra={[
                        <Button
                            type='primary'
                            key='redirectHome'
                            onClick={redirectHome}
                        >
                            Ir a Inicio
                        </Button>,
                    ]}
                />

                <Divider>
                    {' '}
                    Pasos a seguir para finalizar el registro de la cuenta del
                    cliente{' '}
                </Divider>

                <div className='success-account__container'>
                    <Timeline>
                        <Timeline.Item>
                            Entrar a la página principal de{' '}
                            <a
                                href='https://dashboard.stripe.com/login'
                                rel='noopener noreferrer'
                                target='_blank'
                            >
                                Stripe
                            </a>
                        </Timeline.Item>
                        <Timeline.Item>
                            En menú lateral seleccionar "Balance"
                        </Timeline.Item>
                        <Timeline.Item>
                            Ingresar a "Enviados a tu banco"
                        </Timeline.Item>
                        <Timeline.Item>
                            Seleccionar "Configuración de transferencias"
                        </Timeline.Item>
                        <Timeline.Item>
                            En "Calendario de transferencias" elegir la
                            frecuencia de pago-Automatica: dia, semana,
                            mes-Manual
                        </Timeline.Item>
                        <Timeline.Item>Guardar</Timeline.Item>
                    </Timeline>
                </div>
            </Content>
        </Layout>
    );
};

export default ErrorStripeAccount;
