import React from 'react';
import { Layout } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import FriendlyTipsTable from '../../../components/SuperAdmin/FriendlyTipsTable';
const FriendlyTips = () => {
    const { Content } = Layout;

    return (
        <Layout className="collaborators">
            <HeaderTop
                title={'Friendly TIPS'}
                // backIcon={true}
                backUrl="/administrative"
            />
            <Content className="collaborators__content">
                <FriendlyTipsTable />
            </Content>
        </Layout>
    );
};

export default FriendlyTips;
