import React, { useState, useEffect } from 'react';
import {
    Typography,
    Table,
    Select,
    DatePicker,
    Skeleton,
    Tabs,
    Row,
    Col,
    notification,
} from 'antd';
import { TableOutlined, LineChartOutlined } from '@ant-design/icons';
import {
    BarChart,
    Bar,
    Cell,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { getTopSelling } from '../../../api/order';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi, getCompaniesApi } from '../../../api/company';
import moment from 'moment';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import { userLogOut } from '../../../utils/general';

import './TopSelling.scss';

const { Title, Paragraph } = Typography;
const { Option } = Select;

const TopSelling = ({ allRest }) => {
    const { TabPane } = Tabs;
    const [dataChart, setDataChart] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [numberOfItems, setNumberOfItems] = useState(5);
    const [dateSearch, setDateSearch] = useState({
        startDate: moment().subtract(7, 'days'),
        endDate: moment(),
    });
    const [loading, setLoading] = useState(true);
    const token = getAccessTokenApi();
    // const companyId = getCompanyIdApi();
    const [restaurants, setRestaurants] = useState([]);
    const [companyId, setCompanyId] = useState(getCompanyIdApi());
    const [activeTab, setActiveTab] = useState('table');

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        setLoading(true);
        const data = {
            CompanyId: companyId,
            NumberOfItems: numberOfItems,
            StatusList: [1, 2, 3, 4, 5, 6],
            StartDate: dateSearch.startDate.format('YYYY-MM-DD'),
            EndDate: dateSearch.endDate.format('YYYY-MM-DD'),
        };

        getTopSelling(token, data).then((res) => {
            if (res?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1000);
            } else {
                if (res) {
                    createDataChart(res);
                }
            }
        });
    }, [
        companyId,
        dateSearch.endDate,
        dateSearch.startDate,
        numberOfItems,
        token,
    ]);

    useEffect(() => {
        if (allRest) {
            const filters = `isTestingCompany=${false}&active=${true}`;
            getCompaniesApi(token, filters)
                .then((response) => {
                    setRestaurants(response);
                })
                .catch((err) => {});
        }
    }, [token, allRest]);

    const createDataChart = (res) => {
        if (res !== undefined) {
            res.map((ele, index) => {
                ele['Cantidad'] = ele.count;
                ele['key'] = index;
                return ele;
            });
            setDataChart(res);
            setDataTable(res);
        }
        setLoading(false);
    };

    const searchDates = (val) => {
        if (val !== null) {
            if (val[0] !== null && val[1] !== null) {
                setLoading(true);
                setDateSearch({
                    startDate: val[0],
                    endDate: val[1],
                });
            }
        }
    };

    function handleChangeNumbersItems(value) {
        setNumberOfItems(value);
    }

    function handleChangeRest(value) {
        setCompanyId(value);
    }

    function callback(key) {
        setActiveTab(key);
        //     if (key === 'chart') {
        //         setShowChart(true);
        //     } else {
        //         setShowChart(false);
        //     }
    }

    return (
        <div className='top-selling'>
            <Row className='top-selling__title'>
                <Col span={24}>
                    <Title level={3}>Platillos más pedidos</Title>
                    <Paragraph>
                        Fecha de búsqueda: {dateSearch.startDate.format('LL')}{' '}
                        al {dateSearch.endDate.format('LL')}
                    </Paragraph>
                </Col>
            </Row>

            <Row className='top-selling__container-search'>
                <Col
                    span={16}
                    xs={24}
                    lg={16}
                    className='top-selling__container-search-left'
                >
                    <Row>
                        <Col span={8} xs={18} lg={8}>
                            <Paragraph>
                                Esta buscando los {numberOfItems} platillos más
                                pedidos:
                            </Paragraph>
                        </Col>
                        <Col span={12} xs={4} lg={12}>
                            <Select
                                defaultValue={5}
                                placeholder='Selecciona una cantidad'
                                style={{ width: 80 }}
                                onChange={handleChangeNumbersItems}
                            >
                                <Option value={3}>3</Option>
                                <Option value={5}>5</Option>
                                <Option value={10}>10</Option>
                            </Select>
                        </Col>
                    </Row>
                </Col>
                <Col
                    span={8}
                    xs={24}
                    lg={8}
                    className='top-selling__container-search-right'
                >
                    <DatePicker.RangePicker
                        onCalendarChange={(val) => searchDates(val)}
                        size='large'
                    />

                    {allRest && (
                        <Select
                            defaultValue={companyId}
                            style={{ width: 'auto', marginTop: 20 }}
                            onChange={handleChangeRest}
                            size='large'
                            placeholder='Selec. Restaurante'
                        >
                            {restaurants.map((rest) => {
                                return (
                                    <Option
                                        key={rest.companyId}
                                        value={rest.companyId}
                                    >
                                        {rest.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    )}
                </Col>
            </Row>

            <Tabs defaultActiveKey='table' centered onChange={callback}>
                <TabPane
                    tab={
                        <span>
                            <TableOutlined />
                            Tabla
                        </span>
                    }
                    key='table'
                >
                    {activeTab === 'table' && (
                        <TableMode
                            loading={loading}
                            dataChart={dataTable}
                            activeTab={activeTab}
                        />
                    )}
                </TabPane>

                <TabPane
                    tab={
                        <span>
                            <LineChartOutlined />
                            Gráfica
                        </span>
                    }
                    key='chart'
                >
                    {activeTab === 'chart' && (
                        <ChartMode loading={loading} dataChart={dataChart} />
                    )}
                </TabPane>
            </Tabs>
        </div>
    );
};

function ChartMode(props) {
    const { loading, dataChart } = props;

    const colors = scaleOrdinal(schemeCategory10).range();

    return (
        <>
            <Row className='top-selling__container-chart'>
                <Col span={24}>
                    {loading && <Skeleton active />}
                    {!loading && (
                        <ResponsiveContainer width='100%' height={350}>
                            <BarChart
                                // width={730}
                                // height={250}
                                data={dataChart}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray='3 3' />
                                {/* <XAxis dataKey="" /> */}
                                <XAxis dataKey='itemName' />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar
                                    dataKey='Cantidad'
                                    fill='#000000'
                                    label={{ position: 'top' }}
                                >
                                    {dataChart.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={colors[index % 20]}
                                        />
                                    ))}
                                </Bar>
                            </BarChart>
                        </ResponsiveContainer>
                    )}
                    {!loading && dataChart.length === 0 && (
                        <Paragraph>No hay resultados</Paragraph>
                    )}
                </Col>
            </Row>
        </>
    );
}

function TableMode(props) {
    const { loading, dataChart } = props;
    const columns = [
        {
            title: 'Cantidad',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Platillo',
            dataIndex: 'itemName',
            key: 'itemName',
        },
    ];

    return (
        <>
            {!loading && (
                <Table
                    columns={columns}
                    dataSource={dataChart}
                    loading={loading}
                />
            )}
            {loading && <Skeleton active />}
        </>
    );
}

export default TopSelling;
