import React from 'react';
import { Tabs } from 'antd';
import { DollarCircleOutlined, BarChartOutlined } from '@ant-design/icons';
import SellTable from '../../../components/Admin/SellTable';
import TopSelling from '../../../components/Admin/TopSelling';

export default function Reports() {
    const { TabPane } = Tabs;

    return (
        <div>
            <Tabs defaultActiveKey="1" centered className="report-tabs">
                <TabPane
                    tab={
                        <span className="report-tabs__title">
                            <DollarCircleOutlined />
                            Control de Ventas
                        </span>
                    }
                    key="1"
                >
                    <SellTable allRest />
                </TabPane>
                <TabPane
                    tab={
                        <span className="report-tabs__title">
                            <BarChartOutlined />
                            Ranking
                        </span>
                    }
                    key="2"
                >
                    <TopSelling allRest />
                </TabPane>
            </Tabs>
        </div>
    );
}
