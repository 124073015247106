import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Button,
    Spin,
    notification,
    Row,
    Col,
    Divider,
    InputNumber,
} from 'antd';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import { addGroupRunner } from '../../../../api/runner';
import { userLogOut } from '../../../../utils/general';
import './RunnersGroupForm.scss';

const layout = {
    labelCol: {
        span: 12,
    },
    wrapperCol: {
        span: 12,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 10,
        span: 14,
    },
};

const RunnersGroupForm = (props) => {
    const { setIsVisible, runnerGroupsList, setRunnerGroupsList } = props;

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [loadingForm, setLoadingForm] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const onFinish = async (values) => {
        setLoadingForm(true);

        let formData = values;

        formData['CompanyId'] = companyId;

        const result = await addGroupRunner(token, formData);

        if (result === undefined) {
            let messageError =
                'Ocurrio un problema al crear un nuevo grupo, Intentelo mas tarde';

            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);

            setTimeout(() => {
                setIsVisible(false);
            }, 100);

            return true;
        }

        if (result?.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado',
            });
            setLoadingForm(false);

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1500);
            return false;
        }
        if (result?.statusCode === 400) {
            notification['error']({
                message: 'Solo números positivos son permitidos',
            });
            setLoadingForm(false);
            return false;
        }
        if (result?.statusCode === 409) {
            notification['error']({
                message: result.description,
            });
            setLoadingForm(false);
            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Grupo agregado exitosamente',
            });

            const idGroup = result.result.id;

            const categoryJSON = {
                companyId: companyId,
                id: parseInt(idGroup),
                name: result.result.name,
                distanceKm: result.result.distanceKm,
                exceededPricePerKm: result.result.exceededPricePerKm,
                pricePerKm: result.result.pricePerKm,
            };
            runnerGroupsList.push(categoryJSON);
            setRunnerGroupsList(runnerGroupsList);
            setLoadingForm(false);
            form.resetFields();

            setTimeout(() => {
                setIsVisible(false);
            }, 100);
        } else {
            let messageError =
                'Ocurrio un problema al crear un nuevo grupo, Intentelo otra vez';

            if (result.statusCode === 404) {
                messageError = result.description;
            }
            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    function Cancel() {
        form.resetFields();
        setIsVisible(false);
    }

    return (
        <Spin tip='Guardando...' spinning={loadingForm} size='large'>
            <Form
                {...layout}
                className='groupName'
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label='Nombre del grupo'
                    name='Name'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder='Nombre del grupo' />
                </Form.Item>
                <Form.Item
                    label='Distancia base (Km)'
                    name='DistanceKm'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese la distancia',
                        },
                    ]}
                >
                    <InputNumber placeholder='1' min='0' />
                </Form.Item>
                <Form.Item
                    label='Precio base'
                    name='PricePerKm'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese el precio',
                        },
                    ]}
                >
                    <InputNumber placeholder='50' min='0' />
                </Form.Item>
                <Form.Item
                    label='Precio por kilómetro excedido'
                    name='ExceededPricePerKm'
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese el precio',
                        },
                    ]}
                >
                    <InputNumber placeholder='50' min='0' />
                </Form.Item>

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type='ghost' onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type='primary' htmlType='submit'>
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default RunnersGroupForm;
