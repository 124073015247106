import Resizer from 'react-image-file-resizer';

export function validatePassword(password) {
    // eslint-disable-next-line no-useless-escape
    const re =
        // eslint-disable-next-line no-useless-escape
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\/\?\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]).{6,100}$/;
    return re.test(password);
}

export function validateTextHasCapitalize(password) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(?=.*[A-Z])/;
    return re.test(password);
}

export function validateTextHasLowercase(password) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(?=.*[a-z])/;
    return re.test(password);
}

export function validateTextHasNumber(password) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(?=.*[0-9])/;
    return re.test(password);
}

export function validateTextHasCharacter(password) {
    // eslint-disable-next-line no-useless-escape
    // const re = /^(?=.*[?¡\¿\()\!@#\$%\^&\*\[\]"\';:_\-<>\., =\+\/\\])/;
    const re =
        /[\/\?\!\@\#\$\%\^\&\*\)\(\+\=\.\<\>\{\}\[\]\:\;\'\"\|\~\`\_\-]/g;
    return re.test(password);
}

export const resizeFile = (file) =>
    new Promise((resolve) => {
        Resizer.imageFileResizer(
            file,
            300,
            300,
            'JPEG',
            85,
            0,
            (uri) => {
                resolve(uri);
            },
            'base64'
        );
    });
