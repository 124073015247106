import React, { useRef, useEffect, useState } from 'react';
import {
    Button,
    Col,
    Divider,
    List,
    Row,
    Switch,
    Typography,
    notification,
    message,
} from 'antd';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompaniesRunner, onlineStatusRunner } from '../../../api/runner';
import { messageError } from '../../../utils/general';
import './ConnectedRunner.scss';

export default function ConnectedRunner(props) {
    const isMounted = useRef(true);
    const { setIsModalVisible, setIsChangeStatus } = props;
    const [companies, setCompanies] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const token = getAccessTokenApi();

    useEffect(() => {
        getCompanies();
        return () => {
            isMounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCompanies = () => {
        getCompaniesRunner(token).then((response) => {
            if (response === undefined || response.error) {
                notification['error']({
                    message: 'Ocurrio un error, intentelo mas tarde',
                });
                return false;
            }
            if (isMounted.current) {
                setCompanies(response.result);
                setLoadingData(false);
            }
        });
    };

    return (
        <div className='connected-runner'>
            <Divider style={{ marginTop: 0 }}>Compañias</Divider>
            <List
                loading={loadingData}
                itemLayout='horizontal'
                dataSource={companies}
                renderItem={(item) => (
                    <List.Item>
                        <CompanyStatus
                            company={item}
                            setIsChangeStatus={setIsChangeStatus}
                        />
                    </List.Item>
                )}
            />
            <Button
                style={{ marginTop: 20 }}
                type='primary'
                block
                onClick={() => setIsModalVisible(false)}
            >
                Aceptar
            </Button>
        </div>
    );
}

function CompanyStatus({ company, setIsChangeStatus }) {
    const { Title } = Typography;
    const { companyName, companyId, isOnline } = company;
    const token = getAccessTokenApi();

    const onChangeStatus = async (checked) => {
        const data = {
            companyId: companyId,
            isOnline: checked,
        };
        const response = await onlineStatusRunner(token, data);

        if (response) {
            if (response.statusCode === 400) {
                message.error(response.description);
            }

            if (response.statusCode === 200) {
                setIsChangeStatus(true);
                const { companyName, isOnline } = response.result;
                message.success(
                    `Usted se ha ${
                        isOnline ? 'conectado ' : 'desconectado'
                    } en la compañia: ${companyName}`
                );
            }
        } else {
            message.error(messageError());
        }
    };

    return (
        <Row className='company-status'>
            <Col span={14}>
                <Title level={5}>{companyName}</Title>
            </Col>
            <Col span={10}>
                <div className='toggle-container'>
                    <Switch
                        className='status-toggle'
                        defaultChecked={isOnline}
                        checkedChildren='Conectado'
                        unCheckedChildren='Desconectado'
                        onChange={onChangeStatus}
                    />
                </div>
            </Col>
        </Row>
    );
}
