import React from 'react';
import { Divider, Layout } from 'antd';
import MenuTop from '../../../components/Admin/MenuTop';
import ChangePasswordForm from '../../../components/General/ChangePasswordForm';

import './ChangePassword.scss';

const ChangePassword = () => {
    const { Content } = Layout;

    return (
        <Layout className="change-password">
            <Content className="change-password__content">
                <MenuTop title={"Ajustes"}/>
                <Divider>Cambio de Contraseña</Divider>
                <ChangePasswordForm />
            </Content>
        </Layout>
    )
}

export default ChangePassword;
