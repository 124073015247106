import React, { useRef, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import moment from 'moment';
import {
    Row,
    Col,
    Button,
    Typography,
    Avatar,
    Image,
    Divider,
    Progress,
    Steps,
    Card,
    Input,
    Modal as ModalAntd,
    notification,
    Spin,
    Result,
    Alert,
} from 'antd';
import {
    ArrowLeftOutlined,
    CarOutlined,
    CarryOutOutlined,
    EnvironmentOutlined,
    ShopOutlined,
    ExclamationCircleOutlined,
    PhoneOutlined,
    DollarCircleOutlined,
    ProfileOutlined,
    CloseCircleFilled,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../api/auth';
import {
    getDeliveryRunnerApi,
    updateOrderRunner,
    cancelDeliveryRunnerApi,
    cancelDeliveryRunnerLocationApi,
} from '../../../api/runner';
import { getOrderApi, editOrderStatus } from '../../../api/order';
import { getAddressUser } from '../../../api/user';
import Modal from '../../../components/General/Modal';
import { formatMoney, messageError } from '../../../utils/general';

import './ActiveOrder.scss';
import { getCompanyApi } from '../../../api/company';

const { Title, Text, Paragraph } = Typography;

export default function ActiveOrder() {
    const token = getAccessTokenApi();
    const isMounted = useRef(true);
    let history = useHistory();
    const { idDelivery } = useParams();
    const [progressStatus, setProgressStatus] = useState(17);
    const [activeDelivery, setActiveDelivery] = useState();
    const [loading, setLoading] = useState(false);
    const [lastTimeUpdate, setLastTimeUpdate] = useState(0);
    const [isModalOrderVisible, setIsModalOrderVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [showCancelBtn, setShowCancelBtn] = useState(false);
    const [coords, setCoords] = useState(null);
    const [isActiveLocation, setIsActiveLocation] = useState(false);
    const [addressRestaurant, setAddressRestaurant] = useState('');
    const { confirm } = ModalAntd;
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
    };

    useEffect(() => {
        setLoading(true);
        getDeliveryRunnerApi(token, idDelivery)
            .then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        getCompanyApi(token, response.result.companyId).then(
                            (response) => {
                                if (response === undefined) {
                                    notification['error']({
                                        message: messageError(),
                                    });
                                    setLoading(false);
                                    return false;
                                }
                                if (!response.error) {
                                    const address = `${response.address} Col. ${response.neighborhood} CP. ${response.zipCode} ${response.city} ${response.state}`;
                                    setAddressRestaurant(address);
                                }
                            }
                        );
                        setActiveDelivery(response.result);
                        setLastTimeUpdate(response.result.lastUpdate);
                        setLoading(false);

                        if (response.result.status <= 2) {
                            setShowCancelBtn(true);
                        } else {
                            setShowCancelBtn(false);
                        }
                    } else {
                        notification['error']({
                            message: 'Ocurrió un error, intentelo de nuevo.',
                        });
                        setLoading(false);
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                    setLoading(false);
                }

                // getCompanyApi(token,)
            })
            .catch((err) => {});
        return () => {
            isMounted.current = false;
        };
    }, [idDelivery, token]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error, options);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    function success(pos) {
        // const crd = pos.coords;
        setCoords(pos.coords);
        setIsActiveLocation(true);
        // console.log('Your current position is:');
        // console.log('Latitude : ' + crd.latitude);
        // console.log('Longitude: ' + crd.longitude);
        // console.log('More or less ' + crd.accuracy + ' meters.');
    }

    function error(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
        let msg = 'Ocurrio un error al conocer tu ubicación';
        if (err.code === 1) {
            msg = 'No podemos conocer tu ubicación';
        }
        notification['error']({
            message: msg,
            description: err.code === 1 ? 'Activa la ubicación' : '',
        });
    }

    const viewOrderDetails = async (idCompany, idOrder) => {
        const result = await getOrderApi(token, idCompany, idOrder);
        if (result === undefined) {
            notification['error']({
                message: messageError(),
            });
            return false;
        }
        if (result.statusCode === 404) {
            notification['error']({
                message: result.message,
            });
        } else {
            setIsModalOrderVisible(true);
            setContextModal(
                <div>
                    <Row>
                        <Paragraph
                            strong
                            style={{
                                marginBottom: 5,
                            }}
                        >
                            Total de la orden:
                        </Paragraph>
                        <Text
                            type='secondary'
                            style={{
                                marginBottom: 0,
                                marginLeft: 5,
                            }}
                        >
                            ${formatMoney(result.total)}
                        </Text>
                    </Row>
                    {result.changeOf > 0 && result.payType === 1 && (
                        <>
                            <Row>
                                <Col span={24}>
                                    <Paragraph
                                        strong
                                        style={{
                                            marginBottom: 5,
                                        }}
                                    >
                                        Dar cambio de:
                                    </Paragraph>
                                    <Text
                                        strong
                                        type='secondary'
                                        style={{
                                            marginBottom: 0,
                                            marginLeft: 5,
                                        }}
                                    >
                                        $
                                        {formatMoney(
                                            result.changeOf - result.total
                                        )}{' '}
                                    </Text>
                                    <Text
                                        type='secondary'
                                        style={{
                                            marginBottom: 0,
                                            marginLeft: 5,
                                        }}
                                    >
                                        {' '}
                                        en caso de que el cliente pague con
                                    </Text>
                                    <Text
                                        strong
                                        type='secondary'
                                        style={{
                                            marginBottom: 0,
                                            marginLeft: 5,
                                        }}
                                    >
                                        ${formatMoney(result.changeOf)}
                                    </Text>
                                </Col>
                            </Row>
                        </>
                    )}

                    <h3 style={{ marginTop: 20 }}>Productos:</h3>
                    {result.details.map((item, index) => {
                        return (
                            <>
                                <div key={index}>
                                    <p>
                                        [{item.quantity}] {item.itemName}{' '}
                                        {item?.orderDetailUom?.units}{' '}
                                        {item?.orderDetailUom?.unitTypeName}
                                    </p>
                                </div>
                                {item.selectedOptions !== undefined && (
                                    <>
                                        {' '}
                                        {item.selectedOptions.map(
                                            (option, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Paragraph
                                                            className='section-items'
                                                            key={
                                                                option.name +
                                                                option.id
                                                            }
                                                        >
                                                            <Text
                                                                strong
                                                            >{`${option.sectionName}: `}</Text>
                                                            <Text>{`${option.name}`}</Text>
                                                        </Paragraph>
                                                    </div>
                                                );
                                            }
                                        )}
                                    </>
                                )}
                            </>
                        );
                    })}
                </div>
            );
        }
    };

    function showConfirmCancel(status) {
        confirm({
            title: '¿Seguro que quiere cancelar la entrega?',
            icon: <ExclamationCircleOutlined />,

            okText: 'Si',
            okType: 'danger',
            onOk() {
                cancelDelivery(status);
            },
            onCancel() {},
        });
    }

    const cancelDelivery = (status) => {
        if (status === 0 || status === 1) {
            setLoading(true);
            cancelDeliveryRunnerApi(token, activeDelivery.id).then((res) => {
                if (res?.statusCode === 200) {
                    setLoading(false);

                    notification['success']({
                        message: 'Entrega cancelada exitosamente.',
                    });

                    setTimeout(() => {
                        window.location.href = '/runner/orders';
                    }, 1000);
                } else {
                    let msg = messageError();

                    if (res?.statusCode === 400 || res?.statusCode === 409) {
                        msg = res.description;
                    }
                    notification['error']({
                        message: msg,
                    });
                }
            });
        } else if (status === 2) {
            if (isActiveLocation) {
                setLoading(true);
                const point = `${coords.latitude},${coords.longitude}`;
                getAddressUser(point, 2, 20).then((response) => {
                    if (response === undefined) {
                        notification['error']({
                            message: messageError(),
                        });
                        return false;
                    }
                    if (response.statusCode === 200) {
                        const responseApi =
                            response.resourceSets[0].resources[0]
                                .addressOfLocation[0];

                        const { formattedAddress } = responseApi;
                        const data = {
                            runnerLocation: formattedAddress,
                        };

                        cancelDeliveryRunnerLocationApi(
                            token,
                            activeDelivery.id,
                            data
                        ).then((result) => {
                            if (result?.statusCode === 200) {
                                notification['success']({
                                    message: 'Entrega cancelada exitosamente.',
                                });
                                setLoading(false);
                                setTimeout(() => {
                                    window.location.href = '/runner/orders';
                                }, 1000);
                            } else {
                                setLoading(false);

                                let msg = messageError();

                                if (
                                    result?.statusCode === 400 ||
                                    result?.statusCode === 409
                                ) {
                                    msg = result.description;
                                }
                                notification['error']({
                                    message: msg,
                                });
                            }
                        });
                    } else {
                        setLoading(false);

                        notification['error']({
                            message: 'Ocurrio un error',
                            description: 'Por favor activa la ubicación',
                        });
                    }
                });
            } else {
                notification['error']({
                    message: 'Necesitamos conocer tu ubicación',
                    description: 'Por favor activa la ubicación',
                });
            }
        } else {
            setShowCancelBtn(false);
            notification['error']({
                message: 'No puede cancelar la entrega.',
            });
        }
    };

    return (
        <Spin spinning={loading} tip='Cargando...'>
            <div className='runner-active-order'>
                <Row className='runner-active-order__back'>
                    <Col span={24}>
                        <Button type='link' onClick={() => history.goBack()}>
                            <ArrowLeftOutlined /> Atras
                        </Button>
                    </Col>
                </Row>

                {activeDelivery !== undefined && (
                    <>
                        <Row>
                            <Col span={3}>
                                <Avatar
                                    src={
                                        <Image
                                            className='avatar-company'
                                            src={`data:image/${activeDelivery.companyImage.extension};base64, ${activeDelivery.companyImage.imageData}`}
                                            alt='logo de la compañia'
                                        />
                                    }
                                />
                            </Col>
                            <Col span={18}>
                                <Title level={4}>
                                    Recoger en :{' '}
                                    {addressRestaurant ===
                                    activeDelivery.companyAddress
                                        ? activeDelivery.companyName
                                        : ''}
                                </Title>
                                <Text
                                    type='secondary'
                                    style={{ marginTop: 15, marginBottom: 5 }}
                                >
                                    {activeDelivery.companyAddress}
                                </Text>
                                <Row gutter={[0, 20]}>
                                    <Col span={24}>
                                        <Paragraph
                                            strong
                                            style={{
                                                marginTop: 20,
                                                marginBottom: 5,
                                            }}
                                        >
                                            Indicaciones de recogida:
                                        </Paragraph>
                                        <Text
                                            type='secondary'
                                            style={{
                                                marginTop: 20,
                                                marginBottom: 0,
                                                marginLeft: 5,
                                            }}
                                        >
                                            {addressRestaurant ===
                                            activeDelivery.companyAddress
                                                ? activeDelivery.instructions
                                                : 'El pedido ha sido cancelado anteriormente, el lugar de recogida sera distinto al establecimiento, ya que se encontraba en camino a entrega.'}
                                        </Text>
                                    </Col>
                                </Row>

                                <Button
                                    type='primary'
                                    icon={<ProfileOutlined />}
                                    block
                                    ghost
                                    onClick={() =>
                                        viewOrderDetails(
                                            activeDelivery.companyId,
                                            activeDelivery.order.orderId
                                        )
                                    }
                                >
                                    Pedido
                                </Button>
                            </Col>
                        </Row>
                        <Divider>Datos de la Orden</Divider>

                        <div className='runner-active-order__info'>
                            <Text strong>No. Orden: </Text>
                            <Text> {activeDelivery.order.orderNum} </Text>

                            <Row>
                                <Paragraph
                                    strong
                                    style={{ marginTop: 15, marginBottom: 5 }}
                                >
                                    Cliente:
                                </Paragraph>
                                <Text
                                    type='secondary'
                                    style={{
                                        marginTop: 15,
                                        marginBottom: 5,
                                        marginLeft: 5,
                                    }}
                                >
                                    {activeDelivery.clientName}
                                </Text>
                            </Row>

                            <Row>
                                <Paragraph
                                    strong
                                    style={{ marginTop: 15, marginBottom: 5 }}
                                >
                                    Dirección de entrega:
                                </Paragraph>
                                <Text
                                    type='secondary'
                                    style={{
                                        marginTop: 15,
                                        marginBottom: 5,
                                        marginLeft: 5,
                                    }}
                                >
                                    {activeDelivery.clientAddress}
                                </Text>
                            </Row>
                            <Row>
                                <Paragraph
                                    strong
                                    style={{ marginTop: 15, marginBottom: 5 }}
                                >
                                    Distancia:
                                </Paragraph>
                                <Text
                                    type='secondary'
                                    style={{
                                        marginTop: 15,
                                        marginBottom: 5,
                                        marginLeft: 5,
                                    }}
                                >
                                    {activeDelivery.order.distance} Km
                                </Text>
                            </Row>
                            <Row>
                                <Paragraph
                                    strong
                                    style={{ marginTop: 15, marginBottom: 5 }}
                                >
                                    Método de pago:
                                </Paragraph>
                                <Text
                                    type='secondary'
                                    style={{
                                        marginTop: 15,
                                        marginBottom: 5,
                                        marginLeft: 5,
                                    }}
                                >
                                    {activeDelivery.order.payType === 1
                                        ? 'Efectivo'
                                        : 'Tarjeta'}
                                </Text>
                            </Row>

                            {progressStatus <= 34 && (
                                <Row>
                                    <Paragraph
                                        strong
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 5,
                                        }}
                                    >
                                        Hora estimada del platillo listo:
                                    </Paragraph>
                                    <Text
                                        type='secondary'
                                        style={{
                                            marginTop: 15,
                                            marginBottom: 5,
                                            marginLeft: 5,
                                        }}
                                    >
                                        {moment
                                            .unix(
                                                activeDelivery.order.lastUpdate
                                            )
                                            .add(
                                                activeDelivery.order
                                                    .preparationTime,
                                                'm'
                                            )
                                            .format('h:mm a')}
                                    </Text>
                                </Row>
                            )}
                            <Paragraph
                                style={{ marginTop: 15, marginBottom: 5 }}
                            >
                                <Text strong>Tiempo de preparación: </Text>

                                <Text type='secondary'>
                                    {activeDelivery.order.preparationTime} min
                                </Text>
                            </Paragraph>
                            <Text type='secondary'>
                                Última actualización a las{' '}
                                {lastTimeUpdate !== 0
                                    ? moment
                                          .unix(lastTimeUpdate)
                                          .format('h:mm a')
                                    : ''}
                            </Text>

                            {progressStatus < 100 &&
                                activeDelivery?.status <= 4 && (
                                    <Progress
                                        percent={progressStatus}
                                        showInfo={false}
                                        status='active'
                                    />
                                )}

                            {progressStatus >= 100 && (
                                <Progress percent={100} />
                            )}

                            {activeDelivery?.status >= 6 && (
                                <Progress percent={50} status='exception' />
                            )}

                            {activeDelivery?.status < 6 && (
                                <StepOrder
                                    setProgressStatus={setProgressStatus}
                                    progressStatus={progressStatus}
                                    activeDelivery={activeDelivery}
                                    setLoading={setLoading}
                                    setLastTimeUpdate={setLastTimeUpdate}
                                    setActiveDelivery={setActiveDelivery}
                                    setShowCancelBtn={setShowCancelBtn}
                                />
                            )}

                            {activeDelivery?.status === 6 && (
                                <Alert
                                    style={{ marginTop: 15 }}
                                    message='Órden Cancelada'
                                    description='Esta orden fue cancelada.'
                                    type='error'
                                    showIcon
                                />
                            )}

                            {showCancelBtn && (
                                <Button
                                    danger
                                    block
                                    size='large'
                                    icon={<CloseCircleFilled />}
                                    onClick={() =>
                                        showConfirmCancel(activeDelivery.status)
                                    }
                                    disabled={loading}
                                >
                                    Cancelar entrega
                                </Button>
                            )}

                            {/* {progressStatus <= 60 && (
                                <Button danger block size="large">
                                    Retraso de Pedido
                                </Button>
                            )} */}
                        </div>
                        <Modal
                            isVisible={isModalOrderVisible}
                            setIsVisible={setIsModalOrderVisible}
                            title={'Detalle de la orden'}
                            footer={false}
                            // destroyOnClose
                        >
                            {contextModal}
                        </Modal>
                    </>
                )}
            </div>
        </Spin>
    );
}

function StepOrder(props) {
    const {
        setProgressStatus,
        progressStatus,
        activeDelivery,
        setLastTimeUpdate,
        setActiveDelivery,
        setShowCancelBtn,
        setLoading,
    } = props;
    const { Step } = Steps;
    const [step, setStep] = useState(activeDelivery?.status);
    const { confirm } = ModalAntd;
    const token = getAccessTokenApi();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [code, setCode] = useState('');
    const [infoModal, setInfoModal] = useState('delivery');
    const [loadingState, setLoadingState] = useState(false);
    const [loadingCode, setLoadingCode] = useState(false);
    const [showPaymentButton, setShowPaymentButton] = useState(false);

    useEffect(() => {
        if (activeDelivery?.status === 5) {
            setProgressStatus(100);
        } else {
            setProgressStatus((activeDelivery?.status + 1) * 17);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const applyCode = () => {
        let data = {
            status: 4,
            code: code,
            id: activeDelivery.id,
        };

        if (code === '') {
            notification['error']({
                message: 'El código no puede estar vacío',
            });
        } else {
            setLoadingCode(true);

            updateOrderRunner(token, data).then((response) => {
                if (response) {
                    if (response.statusCode === 200) {
                        notification['success']({
                            message: 'Código correcto',
                        });

                        setInfoModal('payment');
                        onChangeStatus(4);
                        if (activeDelivery.order.payType === 1) {
                            setShowPaymentButton(true);
                        } else {
                            onChangeStatus(5);
                            setProgressStatus(100);
                        }
                    } else {
                        notification['error']({
                            message: response.description,
                        });
                    }
                } else {
                    notification['error']({
                        message: messageError(),
                    });
                }

                setLoadingCode(false);
            });
        }
    };

    const onChangeStatus = (current) => {
        if (step < current) {
            setActiveDelivery({ ...activeDelivery, status: current });
            setStep(current);
            setProgressStatus(progressStatus + 17);

            if (current >= 3) {
                setShowCancelBtn(false);
            }
        }
    };

    function showConfirm(current) {
        let nextStatus;

        if (step < current && step + 1 === current) {
            switch (current) {
                case 1:
                    nextStatus = 'Llegué al establecimiento';
                    break;
                case 2:
                    nextStatus = 'En camino al cliente';
                    break;
                case 3:
                    nextStatus = 'Llego al punto de entrega';
                    break;
                // 4: Entregada
                // 5: Pagada
                // 6: Cancelada

                default:
                    break;
            }
            confirm({
                title: `¿Estás seguro que deseas cambiar el estatus a: ${nextStatus}?`,
                icon: <ExclamationCircleOutlined />,
                content: 'No podra deshacer esta acción después.',
                onOk() {
                    setLoadingState(true);
                    setLoading(true);
                    let data = {
                        status: current,
                        id: activeDelivery.id,
                    };
                    updateOrderRunner(token, data).then((response) => {
                        if (response === undefined) {
                            notification['error']({
                                message: messageError(),
                            });
                            return false;
                        }
                        if (response.statusCode === 200) {
                            notification['success']({
                                message: 'Estatus actualizado correctamente',
                            });
                            onChangeStatus(current);
                            if (
                                current === 3 ||
                                (current === 4 &&
                                    activeDelivery.order.payType === 1)
                            ) {
                                setIsModalVisible(true);
                            }

                            if (
                                current === 4 &&
                                activeDelivery.order.payType === 2
                            ) {
                                setProgressStatus(100);
                                setStep(5);
                            }
                            setLastTimeUpdate(response.result.lastUpdate);
                        } else {
                            setTimeout(() => {
                                if (
                                    response.description ===
                                    "Para cambiar el estatus a 'Entregada' debes introducir el código"
                                ) {
                                    setIsModalVisible(true);
                                }
                            }, 1500);

                            notification['error']({
                                message: response.description,
                            });
                        }
                        setLoadingState(false);
                        setLoading(false);
                    });
                },
                onCancel() {},
            });
        }
    }

    function completeDeliveryOrder() {
        if (activeDelivery.order.payType === 1) {
            setLoadingCode(true);
            const data = {
                fromClient: false,
                companyId: activeDelivery.companyId,
                orderId: activeDelivery.order.orderId,
                status: 6,
            };

            editOrderStatus(token, data).then((response) => {
                setLoadingCode(false);
                if (response === undefined) {
                    notification['error']({
                        message: messageError(),
                    });
                    return false;
                }
                if (response?.statusCode === 200) {
                    setIsModalVisible(false);
                    setProgressStatus(100);
                    setStep(5);

                    notification['success']({
                        message: 'Orden pagada exitosamente.',
                    });

                    setTimeout(() => {
                        window.location.href = '/runner/orders';
                    }, 2500);
                } else {
                    notification['error']({
                        message:
                            'Ocurrio un problema al confirmar el pago, intentelo de nuevo.',
                    });
                }
            });
        } else {
            onChangeStatus(5);
            setIsModalVisible(false);
        }
    }

    function confirmPaymentOrder() {
        setInfoModal('payment');
        setIsModalVisible(true);
        setShowPaymentButton(true);
    }
    function googleMaps() {
        let url = '';

        if (step <= 1) {
            url =
                'https://www.google.com.sa/maps/search/' +
                activeDelivery.companyAddress +
                '?hl=en';
        } else {
            url =
                'https://www.google.com.sa/maps/search/' +
                activeDelivery.clientAddress +
                '?hl=en';
        }

        // const urls =
        // 'https://www.google.com.sa/maps/dir/Villa+Flamenca+14,+Villas+del+Mediterraneo,+La+Manga,+83220+Hermosillo,+Son./San+Pedro+94,+Insurgentes,+Hermosillo,+Son./@29.1088623,-111.0182116,14z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x86ce83c5185fea69:0xe8da8c751015bc2b!2m2!1d-111.0316997!2d29.0830955!1m5!1m1!1s0x86ce86f2010b300d:0x9fb4d9442468cebd!2m2!1d-110.9623203!2d29.1345152';
        window.open(url, '_blank');
    }

    return (
        <Spin spinning={loadingState}>
            <Card
                className='card-step'
                style={{ marginTop: 20, marginBottom: 20 }}
            >
                <Title level={4} style={{ textAlign: 'center' }}>
                    Estatus
                </Title>
                <Steps
                    current={step}
                    onChange={showConfirm}
                    direction='vertical'
                >
                    <Step
                        icon={<CarOutlined />}
                        title='Camino al establecimiento'
                        description='Usted se encuentra en camino al establecimiento.'
                    />
                    <Step
                        icon={<ShopOutlined />}
                        title='Llegue al establecimiento'
                        description='Usted se encuentra en el establecimiento.'
                    />

                    <Step
                        icon={<CarOutlined />}
                        title='En camino al cliente'
                        description='Usted se encuentra en camino hacia la ubicación del cliente.'
                    />

                    <Step
                        icon={<EnvironmentOutlined />}
                        title='Llegó al punto de entrega'
                        description='Solicitar código de entrega.'
                    />
                    <Step
                        icon={<CarryOutOutlined />}
                        title='Orden entregada'
                        description='Orden fue entregada al cliente'
                        disabled={step === 4 ? false : true}
                    />
                    <Step
                        icon={<DollarCircleOutlined />}
                        title='Orden pagada'
                        description='Orden Finalizada'
                        disabled={
                            step === 4
                                ? activeDelivery.order.status === 6
                                    ? false
                                    : true
                                : true
                        }
                    />
                </Steps>
            </Card>

            {step === 3 && (
                <Button
                    type='primary'
                    block
                    style={{ marginBottom: 20 }}
                    size='large'
                    onClick={() => setIsModalVisible(true)}
                >
                    Introducir código de entrega
                </Button>
            )}

            {step <= 3 && (
                <a
                    href={`tel:+52${activeDelivery.clientPhone}`}
                    className='ant-btn ant-btn-lg ant-btn-block ant-btn-primary'
                    style={{ marginBottom: 20 }}
                >
                    <PhoneOutlined style={{ marginRight: 5 }} /> Llamar al
                    cliente
                </a>
            )}

            {step <= 3 && (
                <Button
                    onClick={() =>
                        googleMaps(29.083291499999998, -111.03176909999999)
                    }
                    size='large'
                    block
                    style={{ marginBottom: 20 }}
                    type='primary'
                    icon={<EnvironmentOutlined style={{ marginRight: 5 }} />}
                >
                    Ver ubicación del {step <= 1 ? 'pedido' : 'cliente'}
                </Button>
            )}

            {((step === 4 && activeDelivery.order.status === 5) ||
                (step === 4 && showPaymentButton)) && (
                <Button
                    type='primary'
                    block
                    style={{ marginBottom: 20 }}
                    size='large'
                    onClick={() => confirmPaymentOrder()}
                >
                    Confirmar pago
                </Button>
            )}

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={step === 2 ? 'Introducir código' : 'Confirmar pago'}
                footer={false}
                // destroyOnClose
            >
                {infoModal === 'delivery' && (
                    <Spin spinning={loadingCode} tip='Cargando...'>
                        <Row>
                            <p>Introduce el código del cliente:</p>
                            <Input
                                maxLength={8}
                                placeholder='CÓDIGO'
                                onChange={(event) =>
                                    setCode(
                                        event.target.value.toLocaleUpperCase()
                                    )
                                }
                                value={code}
                            />
                        </Row>
                        <div className='list-active-orders__container-button'>
                            <Button
                                style={{ marginTop: 20 }}
                                type='primary'
                                onClick={() => applyCode(step)}
                            >
                                Aceptar
                            </Button>
                        </div>
                    </Spin>
                )}
                {infoModal === 'payment' && activeDelivery.order.payType === 1 && (
                    <Spin spinning={loadingCode} tip='Cargando...'>
                        <div
                            className='list-active-orders__container-button'
                            style={{ textAlign: 'center' }}
                        >
                            <div>
                                <h3>El cliente pagará:</h3>
                                <h1 style={{ color: '#4dcc0f' }}>
                                    ${formatMoney(activeDelivery.order.total)}{' '}
                                    MXN
                                </h1>
                                {activeDelivery.order.changeOf !== 0 && (
                                    <p style={{ textTransform: 'uppercase' }}>
                                        En caso de que el cliente pague con{' '}
                                        <span className='mark-price'>
                                            $
                                            {formatMoney(
                                                activeDelivery.order.changeOf
                                            )}{' '}
                                        </span>
                                        favor de dar{' '}
                                        <span className='mark-price'>
                                            $
                                            {formatMoney(
                                                activeDelivery.order.changeOf -
                                                    activeDelivery.order.total
                                            )}
                                        </span>{' '}
                                        de cambio.
                                    </p>
                                )}
                                <Button
                                    style={{ marginTop: 20 }}
                                    type='primary'
                                    onClick={() => completeDeliveryOrder()}
                                >
                                    Órden pagada
                                </Button>
                            </div>
                        </div>
                    </Spin>
                )}
                {infoModal === 'payment' && activeDelivery.order.payType === 2 && (
                    <Result
                        style={{ padding: 0 }}
                        status='success'
                        title='¡Pago completado!'
                        subTitle='El cliente ha realizado el pago con tarjeta exitosamente.'
                        extra={
                            <Button
                                type='primary'
                                onClick={() => completeDeliveryOrder()}
                            >
                                Aceptar
                            </Button>
                        }
                    />
                )}
            </Modal>
        </Spin>
    );
}
