import React, { useState, createContext, useEffect } from 'react';
import { getAccessTokenApi } from '../api/auth';
import { getCompanyApi } from '../api/company';

export const CompanyContext = createContext();

export default function CompanyProvider(props) {
    const { children } = props;
    const [company, setCompany] = useState(null);
    const [companyId, setCompanyId] = useState(null);

    useEffect(() => {
        getInfoCompany(companyId, setCompany);
    }, [companyId]);

    return (
        <CompanyContext.Provider value={{ company, setCompany, setCompanyId }}>
            {children}
        </CompanyContext.Provider>
    );
}

const getInfoCompany = (companyId, setCompany) => {
    const accessToken = getAccessTokenApi();

    if (accessToken && companyId !== null) {
        getCompanyApi(accessToken, companyId).then((res) => {
            if (!res.error) {
                let avatar = '';
                if (
                    res.companyImage !== undefined ||
                    res.companyImage !== null
                ) {
                    const extensionImage = res.companyImage.extension;
                    const imageProfile = `data:image/${extensionImage};base64, ${res.companyImage.imageData}`;
                    avatar = imageProfile;
                }
                setCompany({
                    Location: res.address,
                    State: res.state,
                    City: res.city,
                    Neighborhood: res.neighborhood,
                    ZipCode: res.zipCode,
                    Telephone: res.phone,
                    CompanyImage: res.companyImage,
                    DeliveryCostKM: res.deliveryCostKM,
                    DeliveryCost: res.deliveryCost,
                    DeliveryExtraKmCost: res.deliveryExtraKmCost,
                    DeliverryMaxDistance: res.deliverryMaxDistance,
                    ServiceMaxDistance: res.serviceMaxDistance,
                    Name: res.name,
                    PickUp: res.pickUp,
                    ToTable: res.toTable,
                    Delivery: res.delivery,
                    Latitude: res.latitude,
                    Longitude: res.longitude,
                    CreditCard: res.creditCard,
                    CashOnly: res.cashOnly,
                    OrderMinimumPurchase: res.orderMinimumPurchase,
                    Avatar: avatar,
                    loading: false,
                });
            }
        });

        //     getCompanySchedule(accessToken, company._id).then(resp=>{

        //         setCompany({
        //             ...company,
        //            Schedule: resp,
        //        })
        //    })
    }
};
