import React, { useEffect, useState } from 'react';
import { Form, Input, Button, notification, Spin, Select } from 'antd';
import { addFriendlyTip } from '../../../api/friendlyTips';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompaniesApi } from '../../../api/company';
const AddFriendlyTipForm = ({ setIsModalVisible, setReloadAdvices }) => {
    const [loadingForm, setLoadingForm] = useState(false);
    const [restaurantsActive, setRestaurantsActive] = useState([]);
    const token = getAccessTokenApi();

    const [form] = Form.useForm();
    const { TextArea } = Input;

    const layout = {
        labelCol: { span: 10 },
        wrapperCol: { span: 14 },
    };

    const tailLayout = {
        wrapperCol: { offset: 0, span: 24 },
    };

    useEffect(() => {
        const token = getAccessTokenApi();
        const filter = `isTestingCompany=${false}`;
        getCompaniesApi(token, filter).then((response) => {
            const activeRest = response.filter((ele) => ele.active === true);
            const activeOptions = activeRest.map((ele) => ({
                label: ele.name,
                value: ele.companyId,
            }));
            setRestaurantsActive(activeOptions);
        });
    }, [token]);

    const addFriendlyTipFunction = async (values) => {
        setLoadingForm(true);

        const result = await addFriendlyTip(token, values);
        if (result === undefined) {
            let messageError =
                'Ocurrio un problema al crear nuevo Friendly TIP, Intentelo mas tarde';

            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
            return;
        }

        if (result.statusCode === 200) {
            form.resetFields();
            setIsModalVisible(false);
            setReloadAdvices(true);
            notification['success']({
                message: 'Friendly TIP agregado exitosamente',
            });
        } else {
            let messageError =
                'Ocurrio un problema al crear nuevo Friendly TIP, Intentelo otra vez';

            if (result.statusCode === 400) {
                messageError = result.description;
            }
            if (result.statusCode === 404) {
                messageError = result.description;
            }

            if (result.statusCode === 409) {
                messageError = result.description;
            }

            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    return (
        <Spin spinning={loadingForm} tip="Guardando...">
            <Form
                {...layout}
                name="add-promo"
                form={form}
                onFinish={addFriendlyTipFunction}
                initialValues={{ DurationType: 'range-dates' }}
            >
                <Form.Item
                    label="Nombre del Friendly TIP"
                    name="Title"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre.',
                        },
                    ]}
                    tooltip="Nombre del Friendly TIP."
                >
                    <Input placeholder="Nombre" />
                </Form.Item>

                <Form.Item
                    label="Mensaje"
                    name="Message"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un mensaje.',
                        },
                    ]}
                    tooltip="Breve mensaje del Friendly TIP, este concepto se mostrará en la sección de noticias de la sucursal."
                >
                    <TextArea showCount maxLength={100} />
                </Form.Item>
                <Form.Item
                    name="CompaniesId"
                    label="Sucursales"
                    rules={[
                        {
                            required: true,
                            message:
                                'Por favor seleccione al menos una sucursal.',
                        },
                    ]}
                    tooltip="Sucursales a las que se les mostrará el mensaje en la sección de noticias."
                >
                    <Select
                        mode="multiple"
                        showArrow
                        style={{ width: '100%' }}
                        options={restaurantsActive}
                    />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ marginTop: 20 }}>
                    <Button type="primary" htmlType="submit" block>
                        Guardar
                    </Button>
                </Form.Item>
            </Form>
        </Spin>
    );
};

export default AddFriendlyTipForm;
