import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Divider, Layout, Skeleton, Typography, notification } from 'antd';
import {
    EnvironmentOutlined,
    PhoneOutlined,
    ClockCircleOutlined,
} from '@ant-design/icons';
import HeaderTop from '../../../components/General/HeaderTop';
import {
    getCompanyApi,
    getCompanyIdApi,
    getCompanySchedule,
} from '../../../api/company';
import { getAccessTokenApi } from '../../../api/auth';
import { formatPhoneNumber, userLogOut } from '../../../utils/general';

import './Help.scss';

const Help = ({ history }) => {
    const isMounted = useRef(true);
    const { Content } = Layout;
    const { Title, Paragraph } = Typography;
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const [infoCompany, setInfoCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingSchedule, setSchedule] = useState(true);
    const [scheduleCompany, setScheduleCompany] = useState([]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        getCompanyApi(token, companyId).then((response) => {
            if (isMounted.current) {
                if (response === undefined || response.error) {
                    notification['error']({
                        message: 'Ocurrio un error, intentelo mas tarde',
                    });
                    return false;
                }
                setInfoCompany({
                    nameComany: response.name,
                    addressCompany: `${response.address}  Col. ${response.neighborhood} CP. ${response.zipCode}, ${response.city} ${response.state}`,
                    phoneCompany: response.phone,
                    phoneAlternateCompany: response.phoneAlternate,
                });

                setLoading(false);
            }
        });
    }, [token, companyId]);

    useEffect(() => {
        getCompanySchedule(token, companyId).then((resp) => {
            if (isMounted.current) {
                if (resp === undefined) {
                    return false;
                }

                let daysSchedule = [];
                const now = moment().format('YYYY-MM-DD');

                resp.forEach((day) => {
                    let dayOfWeek = '';

                    switch (day.dayOfWeek) {
                        case 1:
                            dayOfWeek = 'Lunes';
                            break;
                        case 2:
                            dayOfWeek = 'Martes';
                            break;
                        case 3:
                            dayOfWeek = 'Miercoles';
                            break;
                        case 4:
                            dayOfWeek = 'Jueves';
                            break;
                        case 5:
                            dayOfWeek = 'Viernes';
                            break;
                        case 6:
                            dayOfWeek = 'Sábado';
                            break;
                        case 7:
                            dayOfWeek = 'Domingo';
                            break;

                        default:
                            break;
                    }

                    const daySchedule = {
                        dayOfWeek: dayOfWeek,
                        NumberOfday: day.dayOfWeek,
                        timeStart: moment(`${now} ${day.timeStart}`),
                        timeEnd: moment(`${now} ${day.timeEnd}`),
                        isClosed: false,
                    };
                    daysSchedule.push(daySchedule);
                });

                for (let index = 1; index <= 7; index++) {
                    const found = resp.find((item) => item.dayOfWeek === index);

                    if (found === undefined) {
                        let dayOfWeek = '';

                        switch (index) {
                            case 1:
                                dayOfWeek = 'Lunes';
                                break;
                            case 2:
                                dayOfWeek = 'Martes';
                                break;
                            case 3:
                                dayOfWeek = 'Miercoles';
                                break;
                            case 4:
                                dayOfWeek = 'Jueves';
                                break;
                            case 5:
                                dayOfWeek = 'Viernes';
                                break;
                            case 6:
                                dayOfWeek = 'Sábado';
                                break;
                            case 7:
                                dayOfWeek = 'Domingo';
                                break;

                            default:
                                break;
                        }
                        const daySchedule = {
                            dayOfWeek: dayOfWeek,
                            NumberOfday: index,
                            isClosed: true,
                        };

                        daysSchedule.push(daySchedule);
                    }
                }

                setScheduleCompany(daysSchedule);
                setSchedule(false);
            }
        });
    }, [token, companyId]);

    useEffect(() => {
        if (scheduleCompany.length > 0) {
            let companySchedule = scheduleCompany;
            function compare(a, b) {
                if (a.NumberOfday < b.NumberOfday) {
                    return -1;
                }
                if (a.NumberOfday > b.NumberOfday) {
                    return 1;
                }
                return 0;
            }

            companySchedule.sort(compare);
            setScheduleCompany(companySchedule);
        }
    }, [scheduleCompany]);

    return (
        <Layout className="help">
            <Content className="help_content">
                <HeaderTop
                    title={'Ayuda'}
                    backIconAuto={true}
                    history={history}
                />

                {loading && (
                    <>
                        <Skeleton active paragraph={{ rows: 4 }} />
                        <Skeleton active paragraph={{ rows: 4 }} />
                    </>
                )}

                {!loading && (
                    <div className="help__container">
                        <Title
                            level={3}
                            style={{ marginTop: 20, textAlign: 'center' }}
                        >
                            {infoCompany.nameComany}
                        </Title>
                        <Divider>
                            <EnvironmentOutlined
                                style={{
                                    marginRight: 6,
                                    color: 'tomato',
                                    fontSize: 18,
                                }}
                            />{' '}
                            Dirección
                        </Divider>
                        <Paragraph className="text-center">
                            {infoCompany.addressCompany}
                        </Paragraph>
                        {/* <Divider>Horario de atención</Divider> */}
                        <Divider>
                            <PhoneOutlined
                                style={{
                                    marginRight: 6,
                                    color: 'tomato',
                                    fontSize: 18,
                                }}
                            />{' '}
                            Télefono
                        </Divider>
                        <Paragraph className="text-center">
                            {formatPhoneNumber(infoCompany.phoneCompany)}
                        </Paragraph>
                        {infoCompany.phoneAlternateCompany !== null && (
                            <Paragraph className="text-center">
                                {formatPhoneNumber(
                                    infoCompany.phoneAlternateCompany
                                )}
                            </Paragraph>
                        )}
                    </div>
                )}

                {loadingSchedule && <Skeleton active paragraph={{ rows: 4 }} />}

                {!loadingSchedule && (
                    <div>
                        <Divider>
                            <ClockCircleOutlined
                                style={{
                                    marginRight: 6,
                                    color: 'tomato',
                                    fontSize: 18,
                                }}
                            />{' '}
                            Horario
                        </Divider>
                        {scheduleCompany.map((day) => {
                            return (
                                <Paragraph
                                    className="text-center"
                                    key={day.dayOfWeek}
                                >
                                    {day.dayOfWeek} :{' '}
                                    {day.isClosed
                                        ? 'Cerrado'
                                        : day.timeStart.format('h:mm A') +
                                          ' - ' +
                                          day.timeEnd.format('h:mm A')}
                                </Paragraph>
                            );
                        })}
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default Help;
