import React from 'react';
import imageDefault from '../../../assets/img/png/camara.ico';
import { Image, Button, notification } from 'antd';
import { AdviceApplySeen } from '../../../api/advice';
import { getAccessTokenApi } from '../../../api/auth';
import { messageError } from '../../../utils/general';
import './AdviceForm.scss';

const AdviceForm = (props) => {
    const { item, setIsModalVisible, setAdviceSeen } = props;
    const token = getAccessTokenApi();

    const ChangeStatusAdvice = () => {
        setIsModalVisible(false);
        AdviceApplySeen(token, item.id).then((response) => {
            if (response === undefined) {
                const msg = messageError();

                notification['error']({
                    message: msg,
                });
                return;
            }
            if (response.statusCode === 200) {
                setAdviceSeen(true);
            }
        });
    };
    return (
        <div>
            <Image
                className="image-item"
                src={
                    item.image
                        ? `data:image/${item.image.extension};base64, ${item.image.imageData}`
                        : null
                }
            />
            <p className="margin-pharagraf">{item.description}</p>
            <Button
                className="center-element"
                type="primary"
                onClick={() => ChangeStatusAdvice(item)}
            >
                Entendido
            </Button>
        </div>
    );
};

export default AdviceForm;
