import React, { useState, useEffect } from 'react';
import {
    Button,
    Row,
    Checkbox,
    Form,
    Spin,
    Input,
    InputNumber,
    notification,
    Switch,
} from 'antd';
import { getAccessTokenApi } from '../../../../../api/auth';
import { editSectionOptions } from '../../../../../api/itemSections';
import { getCompanyIdApi } from '../../../../../api/company';

const EditOptionForm = (props) => {
    const { option, arrayOptions, setIsModalVisibleForm } = props;
    const [hasExtraPrice, setHasExtraPrice] = useState(option.hasExtraPrice);
    const [price, setPrice] = useState();
    const [name, setName] = useState('');
    const [optionForm, setOptionForm] = useState([]);
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const [form] = Form.useForm();
    const [loadingForm, setLoadingForm] = useState(false);

    useEffect(() => {
        setOptionForm([
            {
                name: ['sectionOptionId'],
                value: option.sectionOptionId,
            },
            {
                name: ['name'],
                value: option.name,
            },

            {
                name: ['available'],
                value: option.available,
            },
            {
                name: ['hasExtraPrice'],
                value: option.hasExtraPrice,
            },
            {
                name: ['price'],
                value: option.price,
            },
        ]);

        if (option.hasExtraPrice === true) {
            setHasExtraPrice(true);
        }
        if (option.hasExtraPrice === false) {
            setHasExtraPrice(false);
        }
    }, [option]);

    const onFinish = async (values) => {
        setLoadingForm(true);
        let data = values;
        let id = option.sectionOptionId;
        data['companyId'] = companyId;
        data['sectionOptionId'] = id;
        if (values.name === '') {
            notification['error']({
                message: 'El nombre de la opción no puede estar vacío .',
            });
            setLoadingForm(false);
            return false;
        }

        const result = await editSectionOptions(token, data, id);

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message: 'Ocurrió un error, inténtelo de nuevo .',
            });
        }
        if (result.statusCode === 200) {
            setLoadingForm(false);

            notification['success']({
                message: result.description,
            });
            const optionJSON = {
                available: result.result.available,
                hasExtraPrice: result.result.hasExtraPrice,
                name: result.result.name,
                price: result.result.price,
                sectionOptionId: result.result.sectionOptionId,
            };
            const onEdited = arrayOptions.indexOf(option);
            if (onEdited !== -1) arrayOptions[onEdited] = optionJSON;
            setIsModalVisibleForm(false);
        }
        if (result.statusCode === 409) {
            notification['error']({
                message: result.description,
            });
            setLoadingForm(false);
        }
        if (result.statusCode === 404) {
            notification['error']({
                message: result.description,
            });
            setLoadingForm(false);
        }
        if (result.status === 400) {
            notification['error']({
                message: 'El nombre no puede estar vacío.',
            });
            setLoadingForm(false);
        }
    };

    return (
        <Spin tip='Guardando...' spinning={loadingForm} size='large'>
            <Form form={form} onFinish={onFinish} fields={optionForm}>
                <Form.Item name='name' label='Opción'>
                    <Input
                        placeholder='Escribe el nombre de la opción'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Form.Item>
                <Form.Item
                    valuePropName='checked'
                    name='available'
                    label='Disponible:'
                >
                    <Switch />
                </Form.Item>
                <Form.Item
                    label='Costo extra'
                    name='hasExtraPrice'
                    valuePropName='checked'
                    value={hasExtraPrice}
                >
                    <Checkbox
                        onChange={(e) => setHasExtraPrice(e.target.checked)}
                    />
                </Form.Item>
                {hasExtraPrice && (
                    <Form.Item
                        label='Precio'
                        name='price'
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese el precio',
                            },
                        ]}
                    >
                        <InputNumber
                            style={{
                                width: '100%',
                            }}
                            formatter={(value) =>
                                `$ ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ','
                                )
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                            placeholder='Precio'
                            value={price}
                            onChange={(value) => setPrice(value)}
                        />
                    </Form.Item>
                )}
                <Row className='display-button-right'>
                    <Button
                        className='margin-check'
                        type='primary'
                        htmlType='submit'
                        // onChange={() => console.log(option)}
                    >
                        Guardar
                    </Button>
                </Row>
            </Form>
        </Spin>
    );
};

export default EditOptionForm;
