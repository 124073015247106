import React, { useState } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import useAuth from '../hooks/useAuth';
import useUser from '../hooks/useUser';
import MenuLeftSider from '../components/Runner/MenuLeftSider';
import Login from '../pages/Login';
import ClientHome from '../pages/Client';
import AdminHome from '../pages/Admin/Perfil';
import SuperAdminHome from '../pages/SuperAdmin';

import './LayoutRunner.scss';

function LayoutRunner(props) {
    const { routes } = props;
    const { Content, Sider } = Layout;
    const { user, isLoading } = useAuth();
    const { person } = useUser();
    const { name, userType, avatar } = person;
    const [collapse, setCollapse] = useState(true);
    let rolUser = '';

    if (user) {
        rolUser =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
    }

    if (!user && !isLoading && userType === 0) {
        return (
            <>
                <Route path='/login' component={Login} />
                <Redirect to='/login' />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Client') {
        return (
            <>
                <Route path='/' component={ClientHome} />
                <Redirect to='/' />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Admin') {
        return (
            <>
                <Route path='/admin' component={AdminHome} />
                <Redirect to='/admin' />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Operator') {
        return (
            <>
                <Route path='/admin' component={AdminHome} />
                <Redirect to='/admin' />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Super') {
        return (
            <>
                <Route path='/administrative' component={SuperAdminHome} />
                <Redirect to='/administrative' />
            </>
        );
    }

    if (user && !isLoading && rolUser === 'Runner') {
        return (
            <Layout className='layout-admin'>
                <Sider
                    collapsed={collapse}
                    collapsible
                    breakpoint='xxl'
                    collapsedWidth='0'
                    onBreakpoint={(broken) => {}}
                    onCollapse={(collapsed, type) => {
                        setCollapse(!collapse);
                    }}
                >
                    <div className='logo' />
                    <MenuLeftSider
                        name={name ? name : ''}
                        companyImage={avatar}
                        setCollapse={setCollapse}
                        user={user}
                    />
                </Sider>
                <Layout>
                    <Content className='layout-admin__content'>
                        <LoadRoutes routes={routes} />
                    </Content>
                </Layout>
            </Layout>
        );
    }

    return null;
}

function LoadRoutes({ routes }) {
    return (
        <Switch>
            {routes.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                />
            ))}
        </Switch>
    );
}

export default withRouter(LayoutRunner);
