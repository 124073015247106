import React, { useEffect, useState } from 'react';
import { Result, Skeleton, notification } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getExtraImagesApi } from '../../../../api/menu';
import { messageError } from '../../../../utils/general';

import './ImageCarousel.scss';

export default function ImageCarousel({ itemId, itemImage }) {
    const [images, setImages] = useState([]);
    const [haveImages, setHaveImages] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const token = getAccessTokenApi();
        getExtraImagesApi(token, itemId).then((response) => {
            setLoading(true);
            if (response === undefined) {
                notification['error']({
                    message: messageError(),
                });
            } else {
                if (response.statusCode === 401) {
                    notification['error']({
                        message: 'Restaurante desautorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1200);

                    return false;
                }

                if (response.statusCode === 200) {
                    //LENAR ARREGLO
                    let arrayImages = [];
                    if (response.result) {
                        if (response.result.length > 0) {
                            //Have Images
                            response.result.forEach((img) => {
                                const urlImage = `data:image/${img.extension};base64, ${img.imageData}`;

                                const dataImageApi = {
                                    uid: img.imageId,
                                    name: `${img.fileName}.${img.extension}`,
                                    url: urlImage,
                                };

                                arrayImages.push(dataImageApi);
                            });
                            setHaveImages(true);
                        }
                    }
                    if (itemImage) {
                        const urlMainImage = `data:image/${itemImage.extension};base64, ${itemImage.imageData}`;
                        const mainImage = {
                            uid: itemImage.imageId,
                            name: `${itemImage.fileName}.${itemImage.extension}`,
                            url: urlMainImage,
                        };
                        arrayImages.unshift(mainImage);
                    }
                    setImages(arrayImages);
                } else {
                    let msg =
                        'Lo sentimos, ocurrió un error vuelva a intertarlo.';

                    if (response.statusCode === 404) {
                        if (itemImage) {
                            const urlMainImage = `data:image/${itemImage.extension};base64, ${itemImage.imageData}`;
                            const mainImage = {
                                uid: itemImage.imageId,
                                name: `${itemImage.fileName}.${itemImage.extension}`,
                                url: urlMainImage,
                            };
                            let arrayImages = [];
                            arrayImages.unshift(mainImage);
                            setImages(arrayImages);
                            setHaveImages(true);
                        }
                    } else {
                        if (
                            response.statusCode === 400 ||
                            response.statusCode === 409
                        ) {
                            msg = response.description;
                        }

                        notification['error']({
                            message: msg,
                        });
                    }
                }
            }
            setLoading(false);
        });
    }, [itemId, itemImage]);

    return (
        <div className='image-carousel'>
            {haveImages && !loading && (
                <Carousel howThumbs={false} showStatus={false}>
                    {images.map((image) => {
                        return (
                            <div key={image.uid}>
                                <img
                                    className='image-item'
                                    src={image.url}
                                    alt={image.name}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            )}
            {!haveImages && !loading && (
                <div className='image-carousel__not-found'>
                    <Result
                        icon={<FileImageOutlined />}
                        subTitle='No se han encontrado imágenes.'
                    />
                </div>
            )}

            {loading && <Skeleton active paragraph />}
        </div>
    );
}
