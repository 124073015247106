import React from 'react';
import { Layout } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import ActiveOrder from '../../../components/Runner/ActiveOrder/ActiveOrder';

import './OrderActive.scss';

export default function OrderActive() {
    const { Content } = Layout;

    return (
        <Layout className="order-active">
            <Content>
                <HeaderTop
                    title={'Detalle de la orden'}
                    backIconHandle={true}
                    goToUrl={'/runner/active/orders'}
                />
                <div className="active-orders-list__container">
                    <ActiveOrder />
                </div>
            </Content>
        </Layout>
    );
}
