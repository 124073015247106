import React, { useState } from 'react';
import {
    Spin,
    Form,
    Input,
    Select,
    Row,
    Col,
    Divider,
    Button,
    notification,
} from 'antd';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import { addCategoryMenu } from '../../../../api/menu';

import './CategoryForm.scss';

const layout = {
    labelCol: {
        span: 10,
    },
    wrapperCol: {
        span: 14,
    },
};

const tailLayout = {
    wrapperCol: {
        offset: 12,
        span: 12,
    },
};

const CategoryForm = (props) => {
    const { setIsModalVisible, element, menuAdmin, setMenuAdmin } = props;

    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    const { Option } = Select;

    const [loadingForm, setLoadingForm] = useState(false);

    const [form] = Form.useForm();

    const onFinish = async (values) => {
        setLoadingForm(true);

        let formData = values;

        formData['CompanyId'] = companyId;
        formData['GroupId'] = element.groupId;

        const result = await addCategoryMenu(token, formData);

        if (result === undefined) {
            setLoadingForm(false);

            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });

            return false;
        }

        if (result.statusCode === 401) {
            notification['error']({
                message: 'Usuario no autorizado.',
            });

            setTimeout(() => {
                logout();
                window.location.reload();
            }, 1200);

            return false;
        }

        if (result.statusCode === 200) {
            notification['success']({
                message: 'Categoría creada exitosamente',
            });

            // let start = result.description.indexOf("categoria")+10;
            // let end = result.description.indexOf(" para");

            // const idCategory = result.description.slice(start, end)
            const idCategory = result.result.categoryId;
            const found = menuAdmin.findIndex(
                (obj) => obj.groupId === element.groupId
            );

            const categoryJSON = {
                categoryId: parseInt(idCategory),
                categoryImage: null,
                categoryImageId: null,
                company: null,
                companyId: companyId,
                groupId: menuAdmin[found].groupId,
                groupName: menuAdmin[found].name,
                items: [],
                name: values.Name,
                priority: values.Priority,
            };
            menuAdmin[found].categories.push(categoryJSON);

            function compare(a, b) {
                if (a.priority < b.priority) {
                    return -1;
                }
                if (a.priority > b.priority) {
                    return 1;
                }
                return 0;
            }

            menuAdmin[found].categories.sort(compare);

            setMenuAdmin(menuAdmin);

            // menuAdmin[found].categoryId = result.result.name;

            setLoadingForm(false);
            form.resetFields();
            setIsModalVisible(false);
        } else {
            let messageError =
                'Ocurrio un problema al crear una nueva categoría, Intentelo otra vez';

            if (result.statusCode === 404) {
                messageError = result.description;
            }
            notification['error']({
                message: messageError,
            });

            setLoadingForm(false);
        }
    };

    const Cancel = () => {
        form.resetFields();
        setIsModalVisible(false);
    };

    return (
        <Spin tip="Guardando..." spinning={loadingForm} size="large">
            <Form
                {...layout}
                className="category"
                form={form}
                onFinish={onFinish}
            >
                <Form.Item
                    label="Nombre de la categoría"
                    name="Name"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                        },
                    ]}
                >
                    <Input placeholder="Nombre de la categoría" />
                </Form.Item>

                <Form.Item
                    name="Priority"
                    label="Prioridad"
                    rules={[
                        {
                            required: true,
                            message: 'Por favor seleccione una prioridad',
                        },
                    ]}
                >
                    <Select placeholder="Seleccione una prioridad" allowClear>
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                    </Select>
                </Form.Item>

                <Divider></Divider>

                <Row>
                    <Col span={12}>
                        <Button type="ghost" onClick={Cancel}>
                            Cancelar
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Spin>
    );
};

export default CategoryForm;
