import React, { useEffect } from 'react';
import { Tabs, Layout } from 'antd';
import { getAccessTokenApi, getEmailUserApi } from '../../../api/auth';
import { getUserApi } from '../../../api/user';
import MenuTop from '../../../components/Admin/MenuTop';
import Records from '../../../components/Runner/Records';
import EstablishmentAccount from '../../../components/Runner/EstablishmentAccount/EstablishmentAccount';

const OrderHistory = () => {
    const { TabPane } = Tabs;
    const token = getAccessTokenApi();

    useEffect(() => {
        const email = getEmailUserApi(token);
        getUserApi(email).then((response) => {
            const data = JSON.parse(response);
            if (!data.isPasswordChanged) {
                window.location.href = '/runner/changePassword';
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Layout>
            <MenuTop title={'Historial'} />
            <Tabs size={'large'} centered className="orders">
                <TabPane tab="Historial de pedidos" key={0}>
                    <Records />
                </TabPane>

                <TabPane tab="Cuentas con establecimientos" key={1}>
                    <EstablishmentAccount />
                </TabPane>
            </Tabs>
        </Layout>
    );
};

export default OrderHistory;
