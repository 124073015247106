import { useState, } from 'react';

export const useCouter = (initialState = 0) => {

    const [state, setState] = useState(initialState);

    
    const increment = () =>{
        setState( state + 1 );
    }

    const decrement = () =>{
        if(state <= 1){
            return false;
        }
        setState( state - 1 );
    }

    return {
        state,
        setState,
        increment,
        decrement
    }
}
