import React, { useEffect, useState } from 'react';
import { Tabs, Layout, Button, notification } from 'antd';
import MenuTop from '../../../components/Admin/MenuTop';
import OrderList from '../../../components/Admin/OrdersList';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import { getOrders, getListOrders } from '../../../api/order';
import { askForNotificationPermission } from '../../../utils/subscriptions';
import useAuth from '../../../hooks/useAuth';
import { userLogOut } from '../../../utils/general';

import './Orders.scss';

export default function Orders() {
    const [pendingOrders, setPendingOrders] = useState([]);
    const [processOrders, setProcessOrders] = useState([]);
    const [allOrders, setAllOrders] = useState([]);
    const [loadingOrders, setLoadingOrders] = useState(true);
    const [reloadOrders, setReloadOrders] = useState(false);
    const [reloadPendingOrders, setReloadPendingOrders] = useState(false);
    const [reloadHistorialOrders, setReloadHistorialOrders] = useState(false);
    const [firstTime, setFirstTime] = useState(true);
    const [activeKey, setActiveKey] = useState('pending');
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const { user } = useAuth();

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        //-- PENDIENTES --
        const clientId =
            user[
                'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
            ];
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        if (companyId === null) {
            return false;
        }
        askForNotificationPermission(clientId, roleId, companyId, token);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        //-- PENDIENTES --
        if (companyId === null) {
            return false;
        }
        getOrders(token, companyId, 2).then((res) => {
            if (res?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                    description: res?.message
                });
                console.log(res?.message);

                if(res?.message === 'El usuario se enuentra inactivo.'){
                    window.location.href ='/admin/subscription';
                }else {
                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 500);
                }
                return;
            } else {
                if (res !== undefined) {
                    setPendingOrders(res);
                } else {
                    setPendingOrders([]);
                }
                setActiveKey('pending');
                setLoadingOrders(false);
                setReloadOrders(false);
            }
        });

        if (firstTime) setFirstTime(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadOrders, companyId]);

    //-- PROCESO --
    useEffect(() => {
        if (!firstTime) {
            const data = {
                CompanyId: companyId,
                Viewed: 1,
                // StatusList: [2,3,4,5],
                Archived: false,
            };
            getListOrders(token, data).then((res) => {
                if (res?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1000);
                } else {
                    setActiveKey('process');
                    setProcessOrders(res);
                    setLoadingOrders(false);
                    setReloadPendingOrders(false);
                }
            });
            if (firstTime) setFirstTime(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadPendingOrders]);

    //-- HISTORIAL --
    useEffect(() => {
        if (!firstTime) {
            const data = {
                CompanyId: companyId,
                Viewed: 0,
                Archived: false,
            };
            getListOrders(token, data).then((res) => {
                setActiveKey('records');
                setAllOrders(res);
                setLoadingOrders(false);
                setReloadHistorialOrders(false);
            });
            if (firstTime) setFirstTime(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadHistorialOrders]);

    const { TabPane } = Tabs;

    function callback(key) {
        setLoadingOrders(true);
        if (key === 'pending') {
            getOrders(token, companyId, 2).then((res) => {
                if (res !== undefined) {
                    if (res.statusCode) {
                        if (res.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado.',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }
                    }
                    setPendingOrders(res);
                } else {
                    setPendingOrders([]);
                }
                setActiveKey('pending');
                setLoadingOrders(false);
            });
        } else if (key === 'process') {
            const data = {
                CompanyId: companyId,
                // StatusList: [2,3,4,5],
                Viewed: 1,
                Archived: false,
            };
            getListOrders(token, data).then((res) => {
                setActiveKey('process');
                if (res !== undefined) {
                    if (res.statusCode) {
                        if (res.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado.',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1000);
                            return;
                        }
                    }
                    setProcessOrders(res);
                }
                setLoadingOrders(false);
            });
        } else if (key === 'records') {
            const data = {
                CompanyId: companyId,
                Viewed: 0,
                // StatusList: [6,7],
                Archived: false,
            };
            getListOrders(token, data).then((res) => {
                setActiveKey('records');
                if (res !== undefined) {
                    if (res.statusCode) {
                        if (res.statusCode === 401) {
                            notification['error']({
                                message: 'Usuario no autorizado.',
                            });

                            setTimeout(() => {
                                logout();
                                window.location.reload();
                            }, 1500);
                            return;
                        }
                    }
                    setAllOrders(res);
                }
                setLoadingOrders(false);
            });
        }
    }

    return (
        <Layout>
            <MenuTop title={'Órdenes'} />
            <Tabs
                activeKey={activeKey}
                defaultActiveKey='pending'
                onChange={callback}
                size={'large'}
                centered
                className='orders'
            >
                <TabPane
                    tab={
                        <span>
                            <Button className='label-bell'>
                                {pendingOrders.length}
                            </Button>
                            Pendientes
                        </span>
                    }
                    key='pending'
                >
                    <OrderList
                        orders={pendingOrders}
                        loadingOrders={loadingOrders}
                        setReloadOrders={setReloadOrders}
                    />
                </TabPane>

                <TabPane tab='En Proceso' key='process'>
                    <OrderList
                        orders={processOrders}
                        setProcessOrders={setProcessOrders}
                        loadingOrders={loadingOrders}
                        setReloadPendingOrders={setReloadPendingOrders}
                        setReloadHistorialOrders={setReloadHistorialOrders}
                    />
                </TabPane>

                <TabPane tab='Historial' key='records'>
                    <OrderList
                        orders={allOrders}
                        loadingOrders={loadingOrders}
                        clearButton
                        setReloadHistorialOrders={setReloadHistorialOrders}
                    />
                </TabPane>
            </Tabs>
        </Layout>
    );
}
